import {
  BoxSection,
  EViewBoxColumnType,
  Heading3,
  PdfIcon,
  TextM,
  TextS,
  ViewBox,
} from 'app/components';
import {
  CessionDetailsDocument,
  CessionDetailsDocuments,
  CessionDetailsTableArea,
  CessionDetailsWrapper,
  CessionsDetailsPageWrapper,
  HeadingArea,
  TitleArea,
} from './styles';
import { useParams } from 'react-router-dom';
import { useBreadcrumbsContext } from 'app/context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TBodyContentCell, TTableHeadCell, Table } from 'app/components/Table';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { saveAs } from 'file-saver';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency } from 'app/utils/normalizer';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import {
  useApplicationsService,
  useFundingsService,
} from 'modules/products/services/hooks';
import { useCessionService } from 'modules/cession/services/useCessionService';
import {
  TCessionIssuerInfo,
  TCessionResponse,
} from 'modules/cession/interfaces/cession';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { EmptyArea } from 'modules/management/components';
import { format } from 'date-fns';
import { Cnab444Generate } from 'modules/cession/pages/Cnab444/components/Cnab444Generate';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { BRAZILIAN_STATES_OBJECT } from 'app/helpers/addressHelper';
import CessionIcon from 'app/components/Icons/CessionIcon';

const CessionsDetailsPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [issuerInfo, updateIssuerInfo] = useState<TCessionIssuerInfo | null>(
    null,
  );
  const [cessionDetails, updateCessionDetails] =
    useState<TCessionResponse | null>(null);
  const [applications, updateApplications] = useState<TApplicationResponse[]>(
    [],
  );
  const [funding, updateFunding] = useState<IFunding | null>(null);
  const { getFunding, getFundingLoading } = useFundingsService();
  const [isCnabModalOpen, setIsCnabModalOpen] = useState(false);
  const { getApplications, applicationLoading } = useApplicationsService();
  const {
    getCessionRequest,
    getCessionFile,
    getCessionIssuerInfo,
    getCessionFileLoading,
    getCessionLoading,
    getCessionIssuerInfoLoading,
  } = useCessionService();
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const { id: fundingId, cessionId } = useParams();

  useEffect(() => {
    setItems(
      [
        { to: `/cession/${fundingId}/cessions`, label: 'Cessão' },
        {
          to: `/cession/${fundingId}/cessions/${cessionId}`,
          label: 'Visualizar Cessão ',
        },
      ],
      <CessionIcon color="rgb(66, 66, 66)" />,
    );

    return () => resetBreadcrumbs();
  }, []);

  const handleGetCessionApplications = async (
    page: number,
    itemsPerPage: number,
  ) => {
    const ids = cessionDetails?.applications.map(
      (application) => application.applicationId,
    );
    if (ids) {
      getApplications(
        0,
        itemsPerPage,
        undefined,
        undefined,
        ids.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage),
      ).then((apps) => {
        if (apps) {
          updateApplications(apps.content);
        }
      });
    }
  };

  useEffect(() => {
    if (cessionDetails) {
      handleGetCessionApplications(currentPage, linesPerPage);
    }
  }, [cessionDetails, linesPerPage, currentPage]);

  useEffect(() => {
    if (fundingId && cessionId) {
      getCessionRequest(fundingId, cessionId).then((res) => {
        if (res) {
          updateCessionDetails(res);
          setTotalItemsQty(res.applications.length);
          getFunding(res.i_funding).then((fundingRes) => {
            if (fundingRes) {
              updateFunding(fundingRes);
            }
          });
          getCessionIssuerInfo(res.i_issuer).then((issuerInfoRes) => {
            if (issuerInfoRes) {
              updateIssuerInfo(issuerInfoRes);
            }
          });
        }
      });
    }
  }, [fundingId, cessionId]);

  const downloadCessionFile = useCallback(() => {
    if (!fundingId || !cessionDetails) {
      return;
    }
    getCessionFile(fundingId, cessionDetails.id).then((res) => {
      if (res) {
        const ccbName = `Cessao_${format(
          new Date(cessionDetails.created_at),
          'dd_mm_yyyy',
        )}.pdf`;
        saveAs(
          new Blob([res], {
            type: 'application/pdf',
          }),
          ccbName,
        );
      }
    });
  }, [fundingId, cessionDetails]);

  const tableHeadContent: TTableHeadCell[] = useMemo(() => {
    return [
      {
        id: '1',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Nº da CCB</TextS>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '2',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Data de emissão</TextS>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '3',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Data de aquisição</TextS>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '4',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Quantidade de parcelas</TextS>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '5',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Taxa de cessão</TextS>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '6',
        content: (
          <TableHeadCellContainer>
            <TextS weight="Bold">Valor nominal presente</TextS>
          </TableHeadCellContainer>
        ),
      },
    ] as TTableHeadCell[];
  }, []);

  const isLoading =
    getCessionLoading || getFundingLoading || getCessionIssuerInfoLoading;
  return (
    <CessionsDetailsPageWrapper>
      <BoxSection boxVariant="rounded">
        <HeadingArea>
          <Heading3 fontWeight="Sb">{`Cessão ${
            cessionId?.toUpperCase() ?? ''
          }`}</Heading3>
        </HeadingArea>
        {isLoading ? (
          <EmptyArea isLoading={isLoading} message="Carregando...">
            {null}
          </EmptyArea>
        ) : (
          <CessionDetailsWrapper>
            {issuerInfo && (
              <>
                <TitleArea>
                  <Heading3>Cedente</Heading3>
                </TitleArea>
                <ViewBox
                  textAlign="right"
                  values={
                    issuerInfo as unknown as {
                      [key: string]: string | number;
                    }
                  }
                  columns={[
                    {
                      path: 'legal_name',
                      label: 'Razão Social',
                      size: 2,
                    },
                    {
                      path: 'taxpayer_id',
                      label: 'CNPJ',
                      size: 2,
                      type: EViewBoxColumnType.CNPJ,
                    },
                  ]}
                />
              </>
            )}

            {funding && (
              <>
                <TitleArea>
                  <Heading3>Cessionário</Heading3>
                </TitleArea>
                <ViewBox
                  textAlign="right"
                  values={
                    funding as unknown as {
                      [key: string]: string | number;
                    }
                  }
                  columns={[
                    {
                      path: 'legal_name',
                      label: 'Razão Social',
                      size: 2,
                    },
                    {
                      path: 'taxpayer_id',
                      label: 'CNPJ',
                      size: 2,
                      type: EViewBoxColumnType.CNPJ,
                    },
                    {
                      path: 'address.postal_code',
                      label: 'CEP',
                      type: EViewBoxColumnType.CEP,
                    },
                    {
                      path: 'address.state_code',
                      label: 'Estado',
                      type: EViewBoxColumnType.ENUM,
                      enum: BRAZILIAN_STATES_OBJECT,
                    },
                    {
                      path: 'address.city',
                      label: 'Cidade',
                    },
                    {
                      path: 'address.district',
                      label: 'Bairro',
                    },
                    {
                      path: 'address.street_name',
                      label: 'Rua/avenida',
                    },
                    {
                      path: 'address.street_number',
                      label: 'Número',
                    },
                    {
                      path: 'address.extra_info',
                      label: 'Complemento',
                    },
                  ]}
                />
              </>
            )}
            <TitleArea>
              <Heading3>CCBs cedidas</Heading3>
            </TitleArea>
            <CessionDetailsTableArea>
              {applicationLoading ? (
                <EmptyArea
                  isLoading={!!applicationLoading}
                  message="Carregando..."
                >
                  {null}
                </EmptyArea>
              ) : (
                <Table
                  showPagination={totalItemsQty > linesPerPage}
                  handlePageChange={(selectedPage) => {
                    setCurrentPage(selectedPage - 1);
                  }}
                  handleLinesPerPage={(selectedLinesPerPage) => {
                    setCurrentPage(0);
                    setLinesPerPage(
                      selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                    );
                    handleGetCessionApplications(0, selectedLinesPerPage);
                  }}
                  totalElementsQty={totalItemsQty}
                  currentPage={currentPage + 1}
                  linesPerPage={linesPerPage}
                  tableHeadCell={tableHeadContent}
                  tableBodyContent={applications.map((application) => {
                    return {
                      id: '1',
                      cells: [
                        {
                          id: '1',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>{application.sequential_id || '-'}</TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '2',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>
                                {application?.issue_date
                                  ? `${formatDateFromBackend(
                                      application?.issue_date,
                                    ).toLocaleDateString('pt-BR')}`
                                  : '-'}
                              </TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '3',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>
                                {cessionDetails?.created_at
                                  ? `${formatDateFromBackend(
                                      cessionDetails?.created_at,
                                    ).toLocaleDateString('pt-BR')}`
                                  : '-'}
                              </TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '4',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>{application.num_payments}</TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '5',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>
                                {formatCurrency(
                                  (application.issue_value ?? 0) -
                                    (application?.loan_details
                                      .financed_amount ?? 0),
                                )}
                              </TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '6',
                          content: (
                            <TableBodyCellContainer>
                              <TextS>
                                {formatCurrency(
                                  application?.loan_details.financed_amount,
                                )}
                              </TextS>
                            </TableBodyCellContainer>
                          ),
                        },
                      ] as TBodyContentCell[],
                    };
                  })}
                />
              )}
            </CessionDetailsTableArea>

            <TitleArea>
              <Heading3>Documentos</Heading3>
            </TitleArea>
            <CessionDetailsDocuments>
              <CessionDetailsDocument
                onClick={downloadCessionFile}
                isDisabled={getCessionFileLoading}
              >
                <PdfIcon />
                <TextM>Termo de cessão assinado</TextM>
              </CessionDetailsDocument>
              <CessionDetailsDocument
                onClick={() => setIsCnabModalOpen(true)}
                isDisabled={isCnabModalOpen}
              >
                <PdfIcon />
                <TextM>CNAB</TextM>
              </CessionDetailsDocument>
            </CessionDetailsDocuments>
          </CessionDetailsWrapper>
        )}
      </BoxSection>
      {fundingId && cessionDetails && (
        <Cnab444Generate
          fundingId={fundingId}
          handleClose={() => setIsCnabModalOpen(false)}
          isOpen={isCnabModalOpen}
          cnab={{
            application_ids: cessionDetails.applications.map(
              (app) => app.applicationId,
            ),
            cession_date: cessionDetails.created_at,
          }}
        />
      )}
    </CessionsDetailsPageWrapper>
  );
};

export default CessionsDetailsPage;
