import { useContext } from 'react';
import { StepContent } from 'modules/customer/components';
import {
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { CalendarInput, CustomInput, CustomSelect } from 'app/components';
import { usePersonContext } from 'modules/customer/context';
import { useFormik } from 'formik';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { EMaritalPropertySystem } from 'modules/customer/context/PersonProvider/person.interfaces';
import { usePerson } from 'modules/customer/hooks';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { formatCPF } from 'app/helpers';
import { ButtonDefault, ButtonPrimary } from 'app/components';
import { ArrowBack } from '@mui/icons-material';
import { spouseStepSchema } from 'modules/customer/helpers/personValidation';

const SpouseStep = () => {
  const {
    state: { registerData },
  } = usePersonContext();
  const { setPersonData } = usePerson();
  const { onForward, onBack } = useContext(NaturalPersonHandlerStepperContext);

  const formik = useFormik({
    initialValues: {
      spouse_full_name: registerData.spouse_full_name || '',
      spouse_taxpayer_id: registerData.spouse_taxpayer_id || '',
      spouse_date_of_birth: registerData.spouse_date_of_birth
        ? formatDateFromBackend(registerData.spouse_date_of_birth).toISOString()
        : '',
      marital_status: registerData.marital_status || {
        label: '',
        value: undefined,
      },
      marital_property_system: registerData.marital_property_system || {
        label: '',
        value: undefined,
      },
    },
    validationSchema: spouseStepSchema,
    onSubmit: (values) => {
      const parsedValues = {
        ...values,
        spouse_date_of_birth: values.spouse_date_of_birth
          ? new Date(values.spouse_date_of_birth).toISOString()
          : '',
      };
      setPersonData(parsedValues);
      onForward();
    },
  });

  return (
    <>
      <StepContent
        title="Cônjuge"
        description="Informe os dados referentes ao cônjuge"
      >
        <StepContentInputArea>
          <StepContentInput>
            <CustomInput
              name="spouse_full_name"
              value={formik.values.spouse_full_name}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Maria da Silva"
              labelValue="Nome cônjuge"
              validationError={
                formik.touched.spouse_full_name
                  ? formik.errors.spouse_full_name
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="spouse_taxpayer_id"
              value={formatCPF(formik.values.spouse_taxpayer_id)}
              handleInputChange={formik.handleChange}
              placeholder="000.000.000-00"
              labelValue="CPF cônjuge"
              validationError={
                formik.touched.spouse_taxpayer_id
                  ? formik.errors.spouse_taxpayer_id
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CalendarInput
              name="spouse_date_of_birth"
              labelValue="Data de nascimento cônjuge"
              placeholder="dd/mm/aaaa"
              value={formik.values.spouse_date_of_birth}
              onChange={(e) =>
                formik.handleChange({
                  currentTarget: {
                    value: e,
                    name: 'spouse_date_of_birth',
                  },
                })
              }
              validationError={
                formik.touched.spouse_date_of_birth
                  ? formik.errors.spouse_date_of_birth
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Regime de bens"
              name="marital_property_system"
              placeholder="Selecione"
              value={formik.values.marital_property_system}
              handleInputChange={(v) => {
                formik.handleChange(v);
              }}
              options={[
                {
                  value: EMaritalPropertySystem.FINAL_COMMUNION,
                  label: 'Participação final nos aquestros',
                },
                {
                  value: EMaritalPropertySystem.FULL_COMMUNION,
                  label: 'Comunhão universal de bens',
                },
                {
                  value: EMaritalPropertySystem.NO_COMMUNION,
                  label: 'Separação total de bens',
                },
                {
                  value: EMaritalPropertySystem.PARTIAL_COMMUNION,
                  label: 'Comunhão parcial de bens',
                },
              ]}
              validationError={
                formik.touched.marital_property_system?.value
                  ? formik.errors.marital_property_system?.value
                  : ''
              }
            />
          </StepContentInput>
        </StepContentInputArea>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
        </StepContentButtonsArea>
      </StepContent>
    </>
  );
};

export default SpouseStep;
