import { CustomInput, CalendarInput, CustomSelect } from 'app/components';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { formatDateFromBackend } from '../../../../../../app/helpers/dateHelpers';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TRegisterPersonDataRequest } from '../../../../context';
import { documentStepSchema } from '../../../../helpers/personValidation';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import { DocumentTypeObject } from 'modules/customer/context/PersonProvider/person.constants';
import { EDocumentType } from 'modules/customer/context/PersonProvider/person.interfaces';

export interface INaturalPersonEditDocumentSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const NaturalPersonEditDocumentSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditDocumentSection) => {
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const formik = useFormik({
    initialValues: {
      document_number: personData.id_document?.number || '',
      document_issuer: personData.id_document?.issuer || '',
      document_type: personData.id_document?.type
        ? {
            label: DocumentTypeObject[personData.id_document.type],
            value: personData.id_document.type,
          }
        : {
            label: '',
            value: undefined,
          },
      document_issue_date: personData.id_document?.issue_date
        ? formatDateFromBackend(
            personData.id_document?.issue_date,
          ).toISOString()
        : '',
    },
    validationSchema: documentStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newPersonData: TRegisterPersonDataRequest = {
        ...personData,
        id_document: {
          number: values.document_number,
          issuer: values.document_issuer,
          type: values.document_type.value,
          issue_date: values.document_issue_date
            ? new Date(values.document_issue_date).toISOString()
            : '',
        },
      };
      updatePerson(newPersonData).then(() => {
        onClose();
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      title="Documento de identificação"
    >
      <StepContentInputArea>
        <StepContentInput>
          <CustomSelect
            labelValue="Tipo de documento"
            name="document_type"
            placeholder="Selecione"
            value={formik.values.document_type}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              { value: EDocumentType.RG, label: 'RG' },
              { value: EDocumentType.CNH, label: 'CNH' },
              { value: EDocumentType.PASSPORT, label: 'PASSAPORTE' },
              { value: EDocumentType.RNE, label: 'RNE' },
              { value: EDocumentType.CLASS_ID, label: 'CARTEIRA FUNCIONAL' },
              {
                value: EDocumentType.MILITARY_ID,
                label: 'IDENTIDADE MILITAR',
              },
            ]}
            validationError={
              formik.touched.document_type?.value
                ? formik.errors.document_type?.value
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="document_number"
            value={formik.values.document_number}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: 000.000.000-00"
            labelValue="Número do documento"
            validationError={
              formik.touched.document_number
                ? formik.errors.document_number
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="document_issuer"
            value={formik.values.document_issuer}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: SSP/SP"
            labelValue="Orgão emissor"
            validationError={
              formik.touched.document_issuer
                ? formik.errors.document_issuer
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            name="document_issue_date"
            labelValue="Data de emissão"
            placeholder="dd/mm/aaaa"
            value={formik.values.document_issue_date}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'document_issue_date',
                },
              })
            }
            validationError={
              formik.touched.document_issue_date
                ? formik.errors.document_issue_date
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditDocumentSection;
