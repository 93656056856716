import { BaseModal, Heading3, TextL } from 'app/components';
import { CloseIcon } from 'app/components/Icons';
import {
  ColumnSettingsContent,
  ColumnSettingsDraggableListContent,
  ColumnSettingsEmpty,
  ColumnSettingsSearchContent,
} from './styles';
import { List, ListItemButton, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DraggableList } from 'app/components/DraggableItem';
import {
  BlockOutlined,
  CheckOutlined,
  CompareArrowsRounded,
} from '@mui/icons-material';
import { SearchFilterInput } from 'app/components/SearchFilter';
import { ButtonPrimary } from 'app/components';

export type Column = { name: string; id: string; key: string };

interface IColumnSettingsProps {
  isOpen: boolean;
  columns: Array<Column>;
  allColumns: Array<Column>;
  onApply: (columns: Array<Column>) => void;
  onClose: () => void;
}

const ColumnSettings = ({
  isOpen,
  columns,
  allColumns,
  onApply,
  onClose,
}: IColumnSettingsProps) => {
  const theme = useTheme();
  const [searchText, updateSearchText] = useState('');
  const [orderingColumns, updateOrderingColumns] = useState<Array<Column>>([]);

  const orderingColumnsIds = useMemo(() => {
    return orderingColumns.map(({ id }) => id);
  }, [orderingColumns]);

  useEffect(() => {
    updateOrderingColumns(columns);
  }, [columns]);

  const onAddColumn = useCallback(
    (columnToAdd: Column) => {
      updateOrderingColumns(
        ([] as Array<Column>).concat(orderingColumns, [columnToAdd]),
      );
    },
    [orderingColumns],
  );

  const availableColumns = useMemo(() => {
    return allColumns
      .filter(({ id }) => !orderingColumnsIds.includes(id))
      .filter(
        ({ name }) =>
          !searchText || name.toLowerCase().includes(searchText.toLowerCase()),
      );
  }, [allColumns, orderingColumnsIds, searchText]);

  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal open={isOpen}>
      <ColumnSettingsContent>
        <div className="header-area">
          <Heading3>Configuração de Colunas</Heading3>
          <div className="close-btn" onClick={onClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body-content">
          <div className="content-left">
            <TextL weight="semibold">Colunas disponiveis:</TextL>
            <ColumnSettingsSearchContent>
              <SearchFilterInput
                handleClearInput={() => updateSearchText('')}
                handleInputChange={(evt) =>
                  updateSearchText(evt.currentTarget.value)
                }
                searchItemName="colunas"
                value={searchText}
              />
            </ColumnSettingsSearchContent>
            {availableColumns.length > 0 ? (
              <List
                sx={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                {availableColumns.map((column) => {
                  return (
                    <ListItemButton
                      key={column.name}
                      onClick={() => onAddColumn(column)}
                    >
                      {column.name}
                    </ListItemButton>
                  );
                })}
              </List>
            ) : (
              <ColumnSettingsEmpty style={{ paddingLeft: '20px' }}>
                <CheckOutlined />
              </ColumnSettingsEmpty>
            )}
          </div>
          <div className="arrows-icon">
            <CompareArrowsRounded />
          </div>
          <div className="content-right">
            <TextL weight="semibold">Colunas selecionadas:</TextL>
            <ColumnSettingsDraggableListContent>
              {orderingColumns.length > 0 ? (
                <DraggableList
                  items={orderingColumns}
                  onRemoveItem={(item) =>
                    updateOrderingColumns(
                      orderingColumns.filter((column) => column.id !== item.id),
                    )
                  }
                  updateItems={(items) => updateOrderingColumns(items)}
                />
              ) : (
                <ColumnSettingsEmpty
                  style={{ paddingTop: '70px', paddingRight: '20px' }}
                >
                  <BlockOutlined />
                </ColumnSettingsEmpty>
              )}
            </ColumnSettingsDraggableListContent>
          </div>
        </div>
        <div className="footer-content">
          <ButtonPrimary
            disabled={orderingColumns.length === 0}
            onClick={() => onApply(orderingColumns)}
            style={{
              width: '100px',
            }}
          >
            Salvar
          </ButtonPrimary>
        </div>
      </ColumnSettingsContent>
    </BaseModal>
  );
};

export default ColumnSettings;
