import { ButtonPrimary, Heading3, Spinner } from 'app/components';
import { EmptyContainer } from './styles';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { AddIcon } from 'app/components/Icons';
import { TextM } from 'app/components';

interface IEmptyAreaProps {
  children: ReactNode;
  isLoading: boolean;
  message: string;
  description?: string;
  createButtonText?: string;
  onCreateClick?: () => void;
  customButton?: ReactNode;
}

const EmptyArea = ({
  children,
  isLoading,
  message,
  description,
  createButtonText,
  customButton,
  onCreateClick,
}: IEmptyAreaProps) => {
  const theme = useTheme();
  return (
    <EmptyContainer>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {children}
          <Heading3
            style={{
              margin: '30px 0px',
              fontWeight: 'lighter',
              fontSize: '32px',
              color: theme.palette.brand.secondary.base,
            }}
          >
            {message}
          </Heading3>
          {description && (
            <TextM
              style={{
                color: theme.palette.brand.black[100] as string,
                maxWidth: '420px',
                textAlign: 'center',
                lineHeight: '24px',
                marginBottom: '32px',
                marginTop: '-8px',
              }}
            >
              {description}
            </TextM>
          )}
          {customButton ?? null}
          {typeof onCreateClick === 'function' && (
            <ButtonPrimary
              style={{ maxWidth: '202px' }}
              typeVariant="fill"
              iconPosition="left"
              btnIcon={<AddIcon color={theme.palette.common.white as string} />}
              onClick={onCreateClick}
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.common.white,
                }}
              >
                {createButtonText}
              </TextM>
            </ButtonPrimary>
          )}
        </>
      )}
    </EmptyContainer>
  );
};

export default EmptyArea;
