import React from 'react';
import { CustomRadioInputContainer, CustomRadioInputContent } from './styles';

export interface ICustomRadioInputProps {
  isChecked: boolean;
  handleClick?: (value?: string) => void;
  value?: string;
}

const CustomRadioInput = ({
  isChecked,
  handleClick,
  value,
}: ICustomRadioInputProps) => {
  return (
    <CustomRadioInputContainer
      onClick={() => handleClick && handleClick(value)}
    >
      {isChecked && <CustomRadioInputContent />}
    </CustomRadioInputContainer>
  );
};

export default CustomRadioInput;
