import { useState, useEffect, useCallback } from 'react';
import {
  WithdrawModal,
  WithdrawMessageModal,
  WithdrawLoadingModal,
} from './components';
import { useTheme } from '@mui/material';
import { CopyIcon, ErrorCircularIcon } from 'app/components/Icons';
import { AxiosError } from 'axios';
import {
  FundingAccount,
  WithdrawRequest,
  WithdrawStatusResponse,
} from 'modules/disbursement/interfaces/disbursement';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import { ButtonPrimary, ConfirmModal } from 'app/components';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { TextL, TextM } from 'app/components';
import { formatCurrency } from 'app/utils/normalizer';
import { WithdrawReceiptModal } from './components/WithdrawReceiptModal';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { WithdrawConfirmItem } from './styles';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';

enum EWithdrawError {
  CONNECTION,
  UNKNOWN,
  BAD_REQUEST,
  NO_PIX_KEY,
}

export interface IWithdraw {
  isOpen: boolean;
  fundingId: string;
  fundingAccount?: FundingAccount;
  fetchBalance: () => void;
  handleClose: (isOpen: boolean) => void;
}

const Withdraw = ({
  isOpen,
  fundingId,
  fundingAccount,
  fetchBalance,
  handleClose,
}: IWithdraw) => {
  const theme = useTheme();
  const { showSnackbarCopy } = useSnackbar();
  const { withdraw, getWithdrawStatus } = useDisbursementService();
  const [modalWithdrawLoadingOpen, setModalWithdrawLoadingOpen] =
    useState(false);
  const [widthdrawResponse, setWidthdrawResponse] =
    useState<WithdrawStatusResponse | null>(null);
  const [withdrawIdentifier, setWithdrawIdentifier] = useState<string | false>(
    false,
  );
  const [withdrawRequest, setWithdrawRequest] =
    useState<WithdrawRequest | null>(null);
  const [withdrawIntervalId, setWithdrawIntervalId] =
    useState<NodeJS.Timer | null>(null);
  const [modalErrorOpen, setModalErrorOpen] = useState<EWithdrawError | false>(
    false,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setWidthdrawResponse(null);
      setWithdrawIdentifier(false);
      setModalErrorOpen(false);
      setErrorMessage(null);
      setWithdrawRequest(null);
    }
  }, [isOpen]);

  const handleWithdraw = (params: WithdrawRequest) => {
    handleClose(false);
    setModalWithdrawLoadingOpen(true);
    withdraw(fundingId, params)
      .then((res) => {
        if (res) {
          setWithdrawIdentifier(params.identifier);
          return;
        }
        setModalWithdrawLoadingOpen(false);
        setModalErrorOpen(EWithdrawError.UNKNOWN);
      })
      .catch((e) => {
        if (typeof (e as { message: string })?.message === 'string') {
          setErrorMessage((e as { message: string }).message);
          setModalErrorOpen(EWithdrawError.BAD_REQUEST);
        } else if ((e as AxiosError).code === 'ERR_NETWORK') {
          setModalErrorOpen(EWithdrawError.CONNECTION);
        } else {
          setModalErrorOpen(EWithdrawError.UNKNOWN);
        }
        setModalWithdrawLoadingOpen(false);
      });
  };

  useEffect(() => {
    if (!modalWithdrawLoadingOpen && withdrawIntervalId) {
      clearTimeout(withdrawIntervalId);
      setWithdrawIntervalId(null);
    }
  }, [modalWithdrawLoadingOpen]);

  useEffect(() => {
    if (withdrawIdentifier) {
      const fetchWithdrawStatus = async () => {
        try {
          const withdrawStatus = await getWithdrawStatus(
            fundingId,
            withdrawIdentifier,
          );
          if (withdrawStatus?.status === 'PROCESSING') {
            const timeout = setTimeout(() => fetchWithdrawStatus(), 2000);
            setWithdrawIntervalId(timeout);
            return;
          } else {
            setWithdrawIntervalId(null);
            setModalWithdrawLoadingOpen(false);
            if (!withdrawStatus || withdrawStatus?.status === 'ERROR') {
              setModalErrorOpen(EWithdrawError.UNKNOWN);
            } else {
              setWidthdrawResponse(withdrawStatus);
            }
          }
        } catch (e: unknown) {
          if (typeof (e as { message: string })?.message === 'string') {
            setErrorMessage((e as { message: string }).message);
            setModalErrorOpen(EWithdrawError.BAD_REQUEST);
          } else if ((e as AxiosError).code === 'ERR_NETWORK') {
            setModalErrorOpen(EWithdrawError.CONNECTION);
          } else {
            setModalErrorOpen(EWithdrawError.UNKNOWN);
          }
          setWithdrawIntervalId(null);
        }
      };

      fetchWithdrawStatus();
    } else {
      if (withdrawIntervalId) {
        clearTimeout(withdrawIntervalId);
        setWithdrawIntervalId(null);
      }
    }
    return () => {
      if (withdrawIntervalId) {
        clearTimeout(withdrawIntervalId);
        setWithdrawIntervalId(null);
      }
    };
  }, [withdrawIdentifier]);

  const onCopyIdentifier = useCallback(() => {
    if (withdrawRequest) {
      navigator.clipboard.writeText(withdrawRequest.identifier as string);
      showSnackbarCopy();
    }
  }, [withdrawRequest]);

  const date = new Date();

  return (
    <>
      <WithdrawModal
        handleClose={() => handleClose(false)}
        isOpen={isOpen}
        fundingId={fundingId}
        handleWithdraw={(params) => {
          if (fundingAccount?.cashout?.pix?.key) {
            setWithdrawRequest(params);
          } else {
            setModalErrorOpen(EWithdrawError.NO_PIX_KEY);
          }
          handleClose(false);
        }}
      />
      <WithdrawLoadingModal
        isOpen={modalWithdrawLoadingOpen}
        handleClose={() => setModalWithdrawLoadingOpen(false)}
      />
      <WithdrawMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EWithdrawError.NO_PIX_KEY}
        title="Ocorreu um problema com a sua solicitação."
        icon={<ErrorCircularIcon />}
      >
        Sua conta não possui dados de Saque cadastrados. Entre em contato com o
        Atendimento Celcoin.
      </WithdrawMessageModal>
      <WithdrawMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EWithdrawError.CONNECTION}
        title="Não foi possível conectar à internet."
        icon={<ErrorCircularIcon />}
      >
        Verifique sua conexão de rede e tente novamente.
      </WithdrawMessageModal>
      <WithdrawMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EWithdrawError.UNKNOWN}
        title="Ocorreu um problema no nosso sistema."
        icon={<ErrorCircularIcon />}
      >
        Por gentileza, tente novamente ou contate o suporte.
      </WithdrawMessageModal>
      <WithdrawMessageModal
        handleClose={() => {
          setModalErrorOpen(false);
          setErrorMessage(null);
        }}
        isOpen={modalErrorOpen === EWithdrawError.BAD_REQUEST}
        title="Ocorreu um problema com a sua solicitação."
        icon={<ErrorCircularIcon />}
      >
        {errorMessage}
      </WithdrawMessageModal>
      <WithdrawReceiptModal
        isOpen={widthdrawResponse !== null}
        identifier={withdrawIdentifier}
        handleClose={() => {
          fetchBalance();
          setWidthdrawResponse(null);
        }}
        withdrawResponse={widthdrawResponse}
      />
      <ConfirmModal
        isOpen={withdrawRequest !== null}
        icon={<AttentionCircularIcon />}
        title="Confirmação de saque"
        btnConfirmText="Confirmar"
        width="600px"
        handleConfirm={() => {
          setWithdrawRequest(null);
          handleWithdraw(withdrawRequest!);
        }}
        handleClose={() => setWithdrawRequest(null)}
        handleCancel={() => setWithdrawRequest(null)}
      >
        {withdrawRequest && (
          <>
            <WithdrawConfirmItem>
              <TextL weight="bold">Identificador:</TextL>
              <div style={{ display: 'flex', gap: '5px' }}>
                <TextM
                  style={{
                    width: '100%',
                    lineBreak: 'anywhere',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '580px',
                    overflow: 'hidden',
                  }}
                >
                  {withdrawRequest.identifier}
                </TextM>
                <ButtonPrimary
                  typeVariant="ghost"
                  style={{
                    padding: '0px',
                    marginLeft: '5px',
                    width: 'fit-content',
                  }}
                  onClick={onCopyIdentifier}
                >
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                </ButtonPrimary>
              </div>
            </WithdrawConfirmItem>
            <WithdrawConfirmItem>
              <TextL weight="bold">Funding:</TextL>
              <TextM style={{ width: '100%' }}>
                {`${fundingAccount?.business_name} - ${fundingAccount?.account}`}
              </TextM>
            </WithdrawConfirmItem>
            <WithdrawConfirmItem>
              <TextL weight="bold">Chave Pix de Saque:</TextL>
              <TextM style={{ width: '100%' }}>
                {fundingAccount?.cashout?.pix?.key ?? '-'}
              </TextM>
            </WithdrawConfirmItem>
            <WithdrawConfirmItem>
              <TextL weight="bold">Valor:</TextL>
              <TextM>{formatCurrency(withdrawRequest.amount)}</TextM>
            </WithdrawConfirmItem>
            <WithdrawConfirmItem>
              <TextL weight="bold">Data:</TextL>
              <TextM>{`${formatDateFromBackend(
                date.toISOString(),
              ).toLocaleDateString('pt-BR')} às ${formatStringHourFromBackend(
                date.toISOString(),
              )}`}</TextM>
            </WithdrawConfirmItem>
            <WithdrawConfirmItem>
              <TextL weight="bold">Descrição:</TextL>
              <TextM
                style={{ whiteSpace: 'break-spaces', lineBreak: 'anywhere' }}
              >
                {withdrawRequest.description}
              </TextM>
            </WithdrawConfirmItem>
          </>
        )}
      </ConfirmModal>
    </>
  );
};

export default Withdraw;
