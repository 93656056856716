import { useCallback } from 'react';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, Heading3 } from '../../../../../../../app/components';
import {
  ArrowIcon,
  HyperlinkIcon,
} from '../../../../../../../app/components/Icons';
import { formatCNPJ, formatPhone } from '../../../../../../../app/helpers';
import { formatDateFromBackend } from '../../../../../../../app/helpers/dateHelpers';
import { TRegisterBusinessDataRequest } from '../../../../../../customer/context';
import {
  HeadingArea,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../styles';
import { EPermission } from 'modules/auth/context/permify/permify.interfaces';
import { TextM } from 'app/components';
import { usePermify } from 'modules/auth/context';

export interface IBusinessDetailsSideSheetAbout {
  business: TRegisterBusinessDataRequest;
}

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
};

const BusinessDetailsSideSheetAbout = ({
  business,
}: IBusinessDetailsSideSheetAbout) => {
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();
  const theme = useTheme();
  const { isAuthorized } = usePermify();

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/records/legal/${business?.id}/details`,
    );
    showSnackbarCopyLink();
  }, []);

  const handleNavigate = () => {
    navigate(`/records/legal/${business?.id}/details`);
  };

  return (
    <>
      <HeadingArea>
        <Heading3>Cadastro</Heading3>
        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          {isAuthorized(EPermission.PRODUCTS_APPLICATIONS_VIEW) && (
            <ButtonPrimary
              typeVariant="outline"
              style={buttonStyle}
              onClick={handleNavigate}
            >
              <TextM
                weight="Semibold"
                style={{
                  color: theme.palette.brand.primary.base,
                  marginRight: '8px',
                }}
              >
                Ver página
              </TextM>
              <ArrowIcon color={theme.palette.brand.primary.base as string} />
            </ButtonPrimary>
          )}
        </div>
      </HeadingArea>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Razão Social</strong>
            </span>
            <span>{business?.legal_name}</span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>CNPJ</strong>
            </span>
            <span>{formatCNPJ(business?.taxpayer_id as string)}</span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Data Constituição</strong>
            </span>
            <span>
              {formatDateFromBackend(
                business?.foundation_date as string,
              ).toLocaleDateString()}
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>E-mail</strong>
            </span>
            <span>{business?.email_address}</span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Celular</strong>
            </span>
            <span>
              {formatPhone(
                `${business?.phone?.area_code}${business?.phone?.number}`,
              )}
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Cadastrado em</strong>
            </span>
            <span>
              {formatDateFromBackend(
                business?.created_at as string,
              ).toLocaleDateString()}
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default BusinessDetailsSideSheetAbout;
