import styled from '@emotion/styled';

export const CustomRadioInputContainer = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border: 2px solid #E6E5EA;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomRadioInputContent = styled.div`
  width: 12.76px;
  height: 12.76px;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  border-radius: 50%;
`;
