export const ScrRequestIcon = ({ color, ...props }: { color: string }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13391_206217)">
        <g clip-path="url(#clip1_13391_206217)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.5006 24.5012C21.4005 24.5012 23.7512 22.1505 23.7512 19.2506C23.7512 16.3508 21.4005 14 18.5006 14C15.6008 14 13.25 16.3508 13.25 19.2506C13.25 22.1505 15.6008 24.5012 18.5006 24.5012ZM14.8846 18.3947C14.7051 18.4546 14.7064 18.5486 14.881 18.6068L17.5709 19.5046L20.2428 17.4981L18.2389 20.1734L19.4394 22.878C19.514 23.046 19.6157 23.0399 19.6662 22.8632L21.4875 16.4804C21.5369 16.3071 21.4357 16.2082 21.2592 16.2671L14.8846 18.3947Z"
            fill={color}
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.6617 13.163V8.26907L13.9834 2.59375H3.58372C3.06016 2.59375 2.63574 3.02442 2.63574 3.53188V20.5734C2.63574 21.0915 3.05661 21.5115 3.57539 21.5115H12.5659C12.32 20.9218 12.1633 20.2856 12.1126 19.6197H4.52752V4.48552H13.0405V9.21496H17.7699V13.0165C17.9261 13.0046 18.0838 12.9985 18.243 12.9985C18.7313 12.9985 19.2063 13.0555 19.6617 13.163ZM13.8064 14.8903H7.36518V16.7821H12.5659C12.8608 16.0749 13.284 15.4346 13.8064 14.8903ZM10.2028 7.32319H7.36518V9.21496H10.2028V7.32319ZM14.9323 11.1067H7.36518V12.9985H14.9323V11.1067Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13391_206217">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0.5 0.5)"
          />
        </clipPath>
        <clipPath id="clip1_13391_206217">
          <rect
            width="10.5"
            height="10.5"
            fill={color}
            transform="translate(13.25 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
