import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import { Divider, List } from '@mui/material';
import { TextM } from '../typography';
import {
  ListItemButtonContent,
  ListItemButtonWrapper,
  SideMenuDrawerItemContainer,
  SideMenuDrawerItemContainerCollapsed,
} from './styles';

export interface ISideMenuDrawerItemProps {
  icon?: React.ReactNode;
  hoverIcon?: React.ReactNode;
  title: string;
  isOpen?: boolean;
  children?: React.ReactNode;
  multiple?: boolean;
  selected?: boolean;
  isCollapsed?: boolean;
  handleClick?: () => void;
}

const SideMenuDrawerItem = ({
  icon,
  title,
  hoverIcon,
  children,
  isOpen = false,
  multiple = false,
  selected = false,
  isCollapsed = false,
  handleClick,
}: ISideMenuDrawerItemProps) => {
  const onButtonClick = () => {
    handleClick?.();
  };

  return (
    <SideMenuDrawerItemContainer isSelected={selected}>
      {isCollapsed ? (
        <SideMenuDrawerItemContainerCollapsed
          isSelected={selected}
          onClick={handleClick}
        >
          <ListItemButton>{selected ? hoverIcon : icon}</ListItemButton>
        </SideMenuDrawerItemContainerCollapsed>
      ) : (
        <ListItemButton>
          <ListItemButtonWrapper>
            <ListItemButtonContent isOpen={isOpen} onClick={onButtonClick}>
              <div className="title-area">
                {selected ? hoverIcon : icon}
                <TextM weight="Bold">{title}</TextM>
              </div>
              {multiple && (
                <div className="arrowico">
                  <KeyboardArrowDownOutlinedIcon />
                </div>
              )}
            </ListItemButtonContent>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                {children}
              </List>
            </Collapse>
          </ListItemButtonWrapper>
        </ListItemButton>
      )}
    </SideMenuDrawerItemContainer>
  );
};

export default SideMenuDrawerItem;
