import styled from '@emotion/styled';

export const CustomSwitchContainer = styled.div`
  width: 100%;
  margin-top: -15px;
`;

export const CustomSwitchContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;
