import React from 'react';
import { routingPath } from '../../../../../app/routes';
import {
  ButtonPrimary,
  Heading3,
  RegularText,
  Spinner,
} from '../../../../../app/components';
import { AuthFormContainer, BackToLoginBtn } from '../../../components';
import {
  BackLoginButtonContent,
  ButtonArea,
  HeadTextArea,
  InputArea,
  InputItem,
  SubtitleArea,
} from './styles';
import { useFormik } from 'formik';
import { passwordRecoverySchema } from '../../../helpers';
import { useAuthContext } from 'modules/auth/context';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';
import { CustomInput } from 'app/components/CustomInput';

const PasswordRecoveryForm = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { requestResetPassword, isAuthLoading } = useAuthContext();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: passwordRecoverySchema,
    onSubmit: (value) => {
      requestResetPassword(value.email).then((res) => {
        if (res) {
          navigate(`/${routingPath.auth.passwordRecoveryConfirmation}`);
        } else {
          showSnackbar(
            'Ocorreu um problema ao solicitar a redefinição de senha, tente novamente',
            'error',
          );
        }
      });
    },
  });

  return (
    <AuthFormContainer>
      <HeadTextArea>
        <Heading3 fontWeight="B">Esqueceu a senha?</Heading3>
      </HeadTextArea>
      <SubtitleArea>
        <RegularText>Crie uma nova agora mesmo. É super fácil!</RegularText>
      </SubtitleArea>
      <InputArea>
        <InputItem>
          <CustomInput
            value={formik.values.email}
            handleInputChange={formik.handleChange}
            name="email"
            labelValue="EMAIL"
            placeholder="Digite seu email"
            validationError={formik.touched.email ? formik.errors.email : ''}
          />
        </InputItem>
        <ButtonArea>
          {isAuthLoading ? (
            <div className="spinner">
              <Spinner />
            </div>
          ) : (
            <ButtonPrimary onClick={formik.submitForm}>
              CRIAR SENHA
            </ButtonPrimary>
          )}
        </ButtonArea>
        <ButtonArea
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BackLoginButtonContent
            onClick={() => navigate(routingPath.auth.login)}
          >
            <BackToLoginBtn onBtnClick={() => navigate(routingPath.auth.login)}>
              Voltar para o login
            </BackToLoginBtn>
          </BackLoginButtonContent>
        </ButtonArea>
      </InputArea>
    </AuthFormContainer>
  );
};

export default PasswordRecoveryForm;
