import ArrowForward from '@mui/icons-material/ArrowForward';
import { Card } from 'app/components';
import { Heading4 } from '../../../../../../app/components';
import {
  ProductCardHeader,
  ProductCardContent,
  ProductCardStyle,
} from './styles';
import { TextS } from '../../../../../../app/components/typography/body';
import { TProducts } from '../../../../context';

export interface IProductCardProps {
  product: TProducts;
  handleClick?: (id: string) => void;
}

const ProductCard = ({ product, handleClick }: IProductCardProps) => {
  return (
    <Card sx={ProductCardStyle} onClick={() => handleClick?.(product?.id)}>
      <ProductCardHeader>
        <Heading4 fontWeight="B">{product.name}</Heading4>
        <ArrowForward />
      </ProductCardHeader>
      <ProductCardContent>
        <TextS>{product?.description}</TextS>
      </ProductCardContent>
    </Card>
  );
};

export default ProductCard;
