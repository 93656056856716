import { useTheme } from '@mui/material';
import { CloseIcon, Spinner, Heading4, TextL } from 'app/components';
import { BaseModal } from 'app/components';
import {
  ModalBody,
  ModalHeader,
  UploadCsvLoadingModalContainer,
} from './styles';

export interface IUploadCsvLoadingModal {
  isOpen: boolean;
  handleClose: () => void;
}

const UploadCsvLoadingModal = ({
  isOpen,
  handleClose,
}: IUploadCsvLoadingModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <UploadCsvLoadingModalContainer>
        <ModalHeader>
          <TextL>Importação de pessoas</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          <Spinner />
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              marginTop: '24px',
            }}
            fontWeight="B"
          >
            Carregando
          </Heading4>
          <TextL
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            Arquivos grandes podem demorou um pouco. Em caso de problemas, você
            pode tentar carregar novamente.
          </TextL>
        </ModalBody>
      </UploadCsvLoadingModalContainer>
    </BaseModal>
  );
};

export default UploadCsvLoadingModal;
