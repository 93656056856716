import React, { ReactNode } from 'react';
import { Heading4, TextM } from '../typography';
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  ConfirmModalContainer,
  ConfirmModalContent,
  ConfirmModalFooter,
  ConfirmModalHeader,
  SkipButton,
} from './styles';
import { CloseIcon } from '../Icons';
import { CustomCircularIndicator } from '../CustomCircularIndicator';
import { useTheme } from '@mui/material';
import { BaseModal } from '../BaseModal';

export type IConfirmModalProps = {
  children: ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  color?: 'primary' | 'danger' | 'attention';
  handleConfirm?: () => void;
  handleClose: () => void;
  handleSkip?: () => void;
  handleCancel?: () => void;
  btnCancelText?: string;
  btnCancelCustom?: ReactNode;
  btnSkipText?: string;
  isColumnButtons?: boolean;
  btnConfirmText?: string;
  btnConfirmCustom?: ReactNode;
  title: string;
  width?: string;
  style?: React.CSSProperties;
};

const ConfirmModal = ({
  children,
  isOpen,
  title,
  color,
  btnCancelText,
  btnSkipText,
  btnConfirmText,
  isLoading,
  icon,
  isColumnButtons,
  width,
  btnCancelCustom,
  btnConfirmCustom,
  style,
  handleConfirm,
  handleClose,
  handleCancel,
  handleSkip,
}: IConfirmModalProps) => {
  const theme = useTheme();
  return (
    <BaseModal
      open={isOpen}
      boxStyle={style}
      onClose={handleClose}
      style={{ zIndex: 1800 }}
    >
      <ConfirmModalContainer width={width}>
        <ConfirmModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseButton>
        </ConfirmModalHeader>
        <ConfirmModalContent>
          {icon || null}
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              paddingBottom: '16px',
              paddingTop: typeof icon !== 'undefined' ? '20px' : '0px',
              paddingLeft: '10px',
              paddingRight: '10px',
              overflowWrap: 'break-word',
            }}
            fontWeight="B"
          >
            {title}
          </Heading4>
          <TextM>{children}</TextM>
        </ConfirmModalContent>
        <ConfirmModalFooter
          isColumnButtons={isColumnButtons}
          isLoading={!!isLoading}
        >
          {!isLoading ? (
            <>
              {typeof handleCancel === 'function' && (
                <CancelButton
                  color={color ?? 'primary'}
                  onClick={handleCancel ?? handleClose}
                >
                  {btnCancelText ?? 'Cancelar'}
                </CancelButton>
              )}
              {typeof handleSkip === 'function' && (
                <SkipButton
                  onClick={handleSkip}
                  color={color ?? 'primary'}
                  style={isColumnButtons ? { marginBottom: '10px' } : {}}
                >
                  {btnSkipText ?? 'Continuar'}
                </SkipButton>
              )}
              {typeof handleConfirm === 'function' && (
                <ConfirmButton
                  onClick={handleConfirm}
                  color={color ?? 'primary'}
                  style={isColumnButtons ? { marginBottom: '10px' } : {}}
                >
                  {btnConfirmText ?? 'Confirmar'}
                </ConfirmButton>
              )}
              {btnCancelCustom ?? null}
              {btnConfirmCustom ?? null}
            </>
          ) : (
            <CustomCircularIndicator
              sx={{
                color: theme.palette.brand.primary.base,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          )}
        </ConfirmModalFooter>
      </ConfirmModalContainer>
    </BaseModal>
  );
};

export default ConfirmModal;
