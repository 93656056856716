import { SideSheet, ViewBox } from 'app/components';
import { formatPhone } from 'app/helpers';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { Heading4, TextM } from 'app/components';
import {
  FundingAccount,
  TBalance,
} from 'modules/disbursement/interfaces/disbursement';
import { useMemo } from 'react';

const BaaSPixType = {
  EVP: 'Chave aleatória',
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  EMAIL: 'E-mail',
  PHONE: 'Telefone',
};

export interface IBalanceStatementDepositSideSheet {
  handleClose: () => void;
  balanceData?: TBalance;
  fundingAccount?: FundingAccount;
  isOpen: boolean;
}

const BankStatementDepositSideSheet = ({
  handleClose,
  balanceData,
  fundingAccount,
  isOpen,
}: IBalanceStatementDepositSideSheet) => {
  const onClose = () => {
    handleClose();
  };

  const values = useMemo(() => {
    if (fundingAccount) {
      const { bank_account, document_number, bank_branch, pix_keys } =
        fundingAccount;

      const getPixNormalized = () => {
        if (!pix_keys || pix_keys.length === 0) return '-';
        if (!pix_keys[0]?.key || !pix_keys[0]?.key_type) return '-';

        const pixKey = pix_keys[0].key;
        const pixKeyType = pix_keys[0].key_type;
        if (pixKeyType === 'EVP') return pixKey;
        if (['CPF', 'CNPJ'].includes(pixKeyType))
          return normalizeCpfCnpj(pixKey);
        if (pixKeyType === 'PHONE') return formatPhone(pixKey.slice(3));
        return pixKey;
      };
      return {
        bank_code: 'Celcoin',
        agency: bank_branch,
        account: `${bank_account.slice(
          0,
          bank_account.length - 1,
        )}-${bank_account.slice(-1)}`,
        taxpayer_id: normalizeCpfCnpj(document_number),
        pix_key: getPixNormalized(),
        pix_type:
          pix_keys && pix_keys[0] && pix_keys[0].key_type
            ? BaaSPixType[pix_keys[0].key_type as keyof typeof BaaSPixType]
            : '-',
      };
    }
  }, [fundingAccount]);

  return (
    <SideSheet open={isOpen} handleClose={onClose} title="Depositar">
      <Heading4 fontWeight="B">
        Use os dados abaixo para depositar via Pix
      </Heading4>
      <TextM style={{ marginTop: '5px' }}>
        {balanceData
          ? `Seu saldo disponível é: R$ ${formatCurrency(
              balanceData?.balance.amount,
              false,
            )}`
          : ''}
      </TextM>
      <ViewBox
        columns={[
          {
            label: 'Banco',
            path: 'bank_code',
            full: true,
          },
          {
            label: 'Agência',
            path: 'agency',
            full: true,
          },
          {
            label: 'Conta',
            path: 'account',
            full: true,
          },
          {
            label: 'CNPJ',
            path: 'taxpayer_id',
            full: true,
          },
          {
            label: 'Pix',
            path: 'pix_key',
            full: true,
          },
          {
            label: 'Tipo Pix',
            path: 'pix_type',
            full: true,
          },
        ]}
        values={
          values as {
            [key: string]: string | number;
          }
        }
        size={1}
        textAlign="right"
      />
    </SideSheet>
  );
};

export default BankStatementDepositSideSheet;
