import { useState, useEffect, useCallback, useContext } from 'react';
import { useCustomVariables } from 'app/hooks';
import {
  EVariableType,
  TVariable,
  VariableTypeNames,
} from 'app/services/variablesHttpService';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  TextL,
  TextS,
} from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../context';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Popover, useTheme } from '@mui/material';
import {
  LegalPersonViewCustomVariablesTabPopOver,
  TableWrapper,
} from './styles';
import { CustomVariableEditSideSheet } from 'modules/customer/components';
import { LegalPersonViewPageContext } from '../../LegalPersonViewPage';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { Heading3, Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { DetailsIcon } from 'app/components/Icons';
import { EmptyArea } from 'modules/management/components';

type ILegalPersonViewCustomVariablesTabProps = {
  business: TRegisterBusinessDataRequest;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Variáveis adicionadas</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Detalhes</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ minWidth: '100px' }}>
          Valor
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

const LegalPersonViewCustomVariablesTab = ({
  business,
}: ILegalPersonViewCustomVariablesTabProps) => {
  const theme = useTheme();
  const [variables, updateVariables] = useState<TVariable[]>();
  const { updateBusiness, isLoading } = useContext(LegalPersonViewPageContext);
  const [editingCustomVariable, toggleEditingCustomVariable] =
    useState<TVariable | null>(null);
  const { getCustomVariablesForBusiness, isCustomVariablesLoading } =
    useCustomVariables();
  const { isProfileAuthorized } = usePermify();

  useEffect(() => {
    getCustomVariablesForBusiness().then(
      (vars) => vars && updateVariables(() => vars),
    );
  }, []);

  const handleOpenCustomVariablesSideSheet = useCallback(
    (variable: TVariable) => {
      toggleEditingCustomVariable(variable);
    },
    [],
  );

  const handleSaveCustomVariable = useCallback(
    (value: string | undefined) => {
      const businessData: TRegisterBusinessDataRequest = {
        ...business,
        custom_variables: {
          ...business.custom_variables,
          [editingCustomVariable?.name as string]: value,
        },
      };
      updateBusiness(businessData).then(() => {
        toggleEditingCustomVariable(null);
      });
    },
    [editingCustomVariable],
  );

  const getFormatedValue = useCallback(
    (variable: TVariable): string | null => {
      if (!business.custom_variables) return null;
      if (
        variable.type === EVariableType.DATE &&
        typeof business.custom_variables[variable.name] === 'string'
      )
        return formatDateFromBackend(
          business.custom_variables[variable.name] as string,
        ).toLocaleDateString('pt-BR');
      if (variable.type === EVariableType.BOOLEAN) {
        if (business.custom_variables[variable.name] === 'true') return 'Sim';
        if (business.custom_variables[variable.name] === 'false') return 'Não';
        return '';
      }
      return business.custom_variables[variable.name] as string;
    },
    [business],
  );
  return (
    <BoxSection>
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Campos adicionais
      </Heading3>
      {variables && variables.length > 0 ? (
        <>
          <TableWrapper>
            <Table
              tableHeadCell={tableHeader}
              tableBodyContent={variables.map((variable, index) => {
                return {
                  id: variable?.id || String(index),
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextS weight="Bold">{variable.display_name}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <PopupState
                            variant="popover"
                            popupId={`${variable.display_name}-popup`}
                          >
                            {(popupState) => (
                              <>
                                <ButtonDefault
                                  {...bindTrigger(popupState)}
                                  style={{
                                    border: 'none',
                                    fontWeight: 'normal',
                                    background: 'transparent',
                                    textDecoration: 'underline',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                  }}
                                >
                                  Ver descrição
                                </ButtonDefault>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <LegalPersonViewCustomVariablesTabPopOver>
                                    <TextS>{variable.description}</TextS>
                                  </LegalPersonViewCustomVariablesTabPopOver>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>{VariableTypeNames[variable.type]}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <TextS style={{ minWidth: '100px' }}>
                            {business.custom_variables &&
                            business.custom_variables[variable.name] ? (
                              getFormatedValue(variable)
                            ) : isProfileAuthorized(
                                EAccountPermissions.UPDATE_PERSON_BUSINESS,
                              ) ? (
                              <ButtonDefault
                                onClick={() =>
                                  handleOpenCustomVariablesSideSheet(variable)
                                }
                                style={{
                                  border: 'none',
                                  fontWeight: 'normal',
                                  background: 'transparent',
                                  textDecoration: 'underline',
                                  minWidth: '100px',
                                  justifyContent: 'flex-start',
                                  padding: '0px',
                                }}
                              >
                                Definir valor
                              </ButtonDefault>
                            ) : (
                              <>Vazio</>
                            )}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: isProfileAuthorized(
                        EAccountPermissions.UPDATE_PERSON_BUSINESS,
                      ) && (
                        <TableBodyCellContainer
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0px',
                          }}
                        >
                          <ButtonPrimary
                            typeVariant="outline"
                            style={{ width: 'fit-content' }}
                            onClick={() =>
                              handleOpenCustomVariablesSideSheet(variable)
                            }
                          >
                            <DetailsIcon
                              color={theme.palette.brand.primary.base as string}
                            />
                          </ButtonPrimary>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })}
            />
          </TableWrapper>
        </>
      ) : (
        <EmptyArea
          message="Nenhum campo adicionado"
          isLoading={isCustomVariablesLoading}
        >
          {null}
        </EmptyArea>
      )}
      <CustomVariableEditSideSheet
        isOpen={editingCustomVariable !== null}
        handleClose={() => toggleEditingCustomVariable(null)}
        handleSave={handleSaveCustomVariable}
        isLoading={isLoading}
        value={
          editingCustomVariable && business.custom_variables
            ? (business.custom_variables[editingCustomVariable.name] as string)
            : ''
        }
        variable={editingCustomVariable as TVariable}
      />
    </BoxSection>
  );
};

export default LegalPersonViewCustomVariablesTab;
