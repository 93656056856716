import { useState, useEffect, useCallback } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ConfirmModal,
  TextL,
  TextS,
} from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Popover, useTheme } from '@mui/material';
import {
  ButtonArea,
  NaturalPersonViewKycTabPopOver,
  TableWrapper,
} from './styles';
import { Heading3, Heading4, Table, TextM } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { EmptyArea } from 'modules/management/components';
import { usePersonService } from 'modules/customer/services';
import { formatCPF } from 'app/helpers';
import { KycStatus, TKyc, TPersonKyc } from 'modules/customer/interfaces/kyc';
import { capitalize } from 'app/utils/normalizer';
import { InfoOutlined, RefreshOutlined } from '@mui/icons-material';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useNavigate } from 'react-router-dom';

type INaturalPersonViewKycTabProps = {
  personData: TRegisterPersonDataRequest;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Criado em</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Status</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Situação do CPF</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Indicação de Óbito</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoa Politicamente Exposta</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoa Sancionada</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const NaturalPersonViewKycTab = ({
  personData,
}: INaturalPersonViewKycTabProps) => {
  const theme = useTheme();
  const { isProfileAuthorized } = usePermify();
  const [isConfirmRequestKycOpen, toggleConfirmRequestModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const [kycList, updateKycList] = useState<TKyc<TPersonKyc>[]>();
  const {
    getPersonKyc,
    requestPersonKyc,
    getPersonKycLoading,
    requestPersonKycLoading,
  } = usePersonService();

  const reloadData = useCallback(() => {
    return getPersonKyc(personData.taxpayer_id).then((kyc) => {
      if (kyc)
        updateKycList(
          kyc.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          ),
        );
    });
  }, [personData]);

  useEffect(() => {
    if (isProfileAuthorized(EAccountPermissions.READ_KYC)) {
      reloadData();
    } else {
      navigate('/');
    }
  }, [personData]);

  const onRequestKyc = useCallback(() => {
    requestPersonKyc(personData.full_name, personData.taxpayer_id).then(() => {
      reloadData().then(() => {
        toggleConfirmRequestModalOpen(false);
      });
    });
  }, [personData]);

  return (
    <BoxSection>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading3
          style={{ color: theme.palette.brand.secondary.base, width: '100%' }}
        >
          Background Check
          {kycList && kycList.length > 0 && (
            <Heading4
              style={{
                fontSize: '14px',
                paddingTop: '5px',
              }}
            >
              <strong>{`Última consulta: ${new Date(
                kycList[0].created_at,
              ).toLocaleDateString('pt-br')} às ${new Date(
                kycList[0].created_at,
              ).toLocaleTimeString('pt-br')}`}</strong>
            </Heading4>
          )}
        </Heading3>
        {kycList &&
          kycList.length > 0 &&
          isProfileAuthorized(EAccountPermissions.CREATE_KYC) && (
            <ButtonArea>
              <ButtonDefault
                style={{ maxWidth: '250px' }}
                onClick={() => toggleConfirmRequestModalOpen(true)}
              >
                <RefreshOutlined
                  style={{
                    color: theme.palette.brand.primary.base as string,
                    marginRight: '5px',
                    fontSize: '22px',
                  }}
                />
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.primary.base }}
                >
                  Atualizar consulta
                </TextM>
              </ButtonDefault>
            </ButtonArea>
          )}
      </div>
      {kycList && kycList.length > 0 ? (
        <TableWrapper>
          <Table
            tableHeadCell={tableHeader}
            tableBodyContent={kycList.map((kycItem, index) => {
              const { result } = kycItem;
              return {
                id: `${result?.pf_name}-${String(index)}`,
                cells: [
                  {
                    id: '1',
                    content: (
                      <TableBodyCellContainer>
                        <PopupState
                          variant="popover"
                          popupId={`${result?.pf_name}-details-${index}-popup`}
                        >
                          {(popupState) => (
                            <>
                              <ButtonDefault
                                {...bindTrigger(popupState)}
                                style={{
                                  border: 'none',
                                  fontWeight: 'normal',
                                  background: 'transparent',
                                  textDecoration: 'underline',
                                  justifyContent: 'flex-start',
                                  padding: '0px',
                                }}
                              >
                                <InfoOutlined />
                              </ButtonDefault>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <NaturalPersonViewKycTabPopOver>
                                  <TextS>
                                    {result?.pf_ofac_message ??
                                      KycStatus[kycItem.status]}
                                  </TextS>
                                </NaturalPersonViewKycTabPopOver>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '2',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>
                          {`${new Date(kycItem.created_at).toLocaleDateString(
                            'pt-br',
                          )} às ${new Date(
                            kycItem.created_at,
                          ).toLocaleTimeString('pt-br')}`}
                        </TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '3',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>{KycStatus[kycItem.status]}</TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '4',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>
                          {result?.pf_tax_id_status
                            ? capitalize(result.pf_tax_id_status)
                            : '-'}
                        </TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '5',
                    content: (
                      <TableBodyCellContainer>
                        {result?.pf_has_obit_indication ? (
                          <PopupState
                            variant="popover"
                            popupId={`${result.pf_name}-obit-${index}-popup`}
                          >
                            {(popupState) => (
                              <>
                                <ButtonDefault
                                  {...bindTrigger(popupState)}
                                  style={{
                                    border: 'none',
                                    fontWeight: 'normal',
                                    background: 'transparent',
                                    textDecoration: 'underline',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                  }}
                                >
                                  Sim
                                </ButtonDefault>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <NaturalPersonViewKycTabPopOver>
                                    <TextS>
                                      <strong>Ano: </strong>
                                      {result?.pf_obit_indication_year}
                                    </TextS>
                                    <TextS>
                                      <strong>Origem: </strong>
                                      {result?.pf_obit_indication_origin}
                                    </TextS>
                                  </NaturalPersonViewKycTabPopOver>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        ) : (
                          <TextS>
                            {result?.pf_has_obit_indication === false
                              ? 'Não'
                              : '-'}
                          </TextS>
                        )}
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '6',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>
                          {result?.pf_is_currently_pep
                            ? 'Sim'
                            : result?.pf_is_currently_pep === false
                            ? 'Não'
                            : '-'}
                        </TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '7',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>
                          {result?.pf_is_currently_sanctioned
                            ? 'Sim'
                            : result?.pf_is_currently_sanctioned === false
                            ? 'Não'
                            : '-'}
                        </TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                ],
              };
            })}
          />
        </TableWrapper>
      ) : (
        <EmptyArea
          message="Nenhuma consulta de Background Check realizada"
          createButtonText="Realizar consulta"
          onCreateClick={
            isProfileAuthorized(EAccountPermissions.CREATE_KYC)
              ? () => toggleConfirmRequestModalOpen(true)
              : undefined
          }
          isLoading={getPersonKycLoading}
        >
          {null}
        </EmptyArea>
      )}
      <ConfirmModal
        isOpen={isConfirmRequestKycOpen}
        title="Essa consulta é cobrada ao Originador. Deseja prosseguir?"
        color="primary"
        btnConfirmText="Confirmar"
        isLoading={requestPersonKycLoading}
        handleConfirm={onRequestKyc}
        handleClose={() => toggleConfirmRequestModalOpen(false)}
        handleCancel={() => toggleConfirmRequestModalOpen(false)}
      >
        Consultas de Background Check precisam do consentimento Livre, Informado
        e Inambíguo da Pessoa. Você confirma que obteve consentimento do titular
        do CPF: <strong>{formatCPF(personData.taxpayer_id)}</strong>?
      </ConfirmModal>
    </BoxSection>
  );
};

export default NaturalPersonViewKycTab;
