import React, { RefObject, useState, ReactNode } from 'react';
import {
  Paper,
  Popper as NativePopper,
  PopperProps as NativePopperProps,
} from '@mui/material';
import { PopperArrow, PopperContainer, PopperContent } from './styles';

export interface PopperProps extends NativePopperProps {
  children: ReactNode;
  anchorRef?: RefObject<HTMLDivElement>;
}

const Popper = ({ children, anchorRef, open }: PopperProps) => {
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  return (
    <NativePopper
      open={open}
      anchorEl={anchorRef?.current}
      placement="right"
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <PopperContainer>
        <PopperArrow ref={(ref) => setArrowRef(ref)} />
        <Paper
          style={{
            boxShadow: 'unset',
            borderRadius: '10px',
          }}
        >
          <PopperContent>{children}</PopperContent>
        </Paper>
      </PopperContainer>
    </NativePopper>
  );
};

export default Popper;
