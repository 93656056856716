import { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  ChevronIcon,
  DeleteButton,
  DownloadButton,
  StepContent,
  Upload,
} from 'app/components';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import {
  StepContentButtonsArea,
  TableBodyFile,
  TableFilesWrapper,
  TableHeadFile,
  UploadArea,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { Table, TextM } from 'app/components';
import {
  useUploadBusinessFiles,
  useUploadPersonFiles,
} from 'modules/customer/hooks';
import { differenceInDays, format } from 'date-fns';
import { iconByFileType } from 'modules/customer/components/DocumentsModal/DocumentsModal';
import { IFile } from 'modules/customer/hooks/useUploadBusinessFiles';
import {
  DOCUMENT_TYPE,
  BusinessDocumentTypeLabel,
  PersonDocumentTypeLabel,
} from 'app/helpers/documentTypes';
import {
  RequiredArea,
  RequiredAreaIcon,
  RequiredDocumentsList,
  RequiredDocumentsListItem,
} from 'modules/customer/components';
import { AttentionRequiredDocuments } from 'app/components/Icons';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

const AllDocumentsLabels = {
  ...BusinessDocumentTypeLabel,
  ...PersonDocumentTypeLabel,
};

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadFile>
        <TextM weight="Bold">Arquivo</TextM>
        <ChevronIcon />
      </TableHeadFile>
    ),
  },
  {
    id: '2',
    content: <TextM weight="Bold">Tipo</TextM>,
  },
  {
    id: '3',
    content: <TextM weight="Bold">Criado em</TextM>,
  },
  {
    id: '4',
    content: <TextM weight="Bold">Baixar</TextM>,
  },
  {
    id: '5',
    content: <TextM weight="Bold">Excluir</TextM>,
  },
];

const DocumentsStep = () => {
  const {
    onForward,
    onBack,
    onStartCreateQualififcation,
    borrowerData,
    productData,
    borrowerType,
  } = useContext(QualificationRequestStepperContext);
  const { isProfileAuthorized } = usePermify();

  const [requiredDocuments, updateRequiredDocuments] = useState<
    DOCUMENT_TYPE[]
  >([]);

  const {
    fetchPersonFile,
    downloadPersonFile,
    handleDeletePersonFile,
    uploadedFiles: uploadedPersonFiles,
    handleUploadFiles: handlePersonUploadFiles,
    uploadedFilesLoading: uploadedPersonFilesLoading,
  } = useUploadPersonFiles();

  const {
    fetchBusinessFile,
    downloadBusinessFile,
    handleDeleteBusinessFile,
    uploadedFiles: uploadedBusinessFiles,
    handleUploadFiles: handleBusinessUploadFiles,
    uploadedFilesLoading: uploadedBusinessFilesLoading,
  } = useUploadBusinessFiles();

  const uploadedFiles = useMemo(() => {
    if (
      productData?.borrower_type === 'PERSON' &&
      borrowerType === EFunctions.BORROWER
    )
      return uploadedPersonFiles;
    return uploadedBusinessFiles;
  }, [productData, borrowerType, uploadedPersonFiles, uploadedBusinessFiles]);

  const uploadedFilesLoading = useMemo(() => {
    if (
      productData?.borrower_type === 'PERSON' &&
      borrowerType === EFunctions.BORROWER
    )
      return uploadedPersonFilesLoading;
    return uploadedBusinessFilesLoading;
  }, [
    productData,
    borrowerType,
    uploadedPersonFilesLoading,
    uploadedBusinessFilesLoading,
  ]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (
        productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.BORROWER
      ) {
        handlePersonUploadFiles(acceptedFiles, borrowerData?.id || '');
      } else {
        handleBusinessUploadFiles(acceptedFiles, borrowerData?.id || '');
      }
    },
    [productData, borrowerType],
  );

  const handleDownloadFile = useCallback(
    (fileId: string, file: IFile) => {
      if (
        productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.BORROWER
      ) {
        downloadPersonFile(borrowerData?.id as string, fileId, file);
      } else {
        downloadBusinessFile(borrowerData?.id as string, fileId, file);
      }
    },
    [borrowerData, borrowerType, productData],
  );

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      if (
        productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.BORROWER
      ) {
        handleDeletePersonFile(fileId, borrowerData?.id as string);
      } else {
        handleDeleteBusinessFile(fileId, borrowerData?.id as string);
      }
    },
    [borrowerData, borrowerType, productData],
  );

  const renderCreationDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const diffInDays = differenceInDays(new Date(), date);
    return (
      <TextM weight="Regular">
        {diffInDays > 2
          ? format(date, 'dd/MM/yyyy')
          : diffInDays === 0
          ? 'Hoje'
          : `${diffInDays} dia(s) atrás`}
      </TextM>
    );
  };

  useEffect(() => {
    if (
      productData?.borrower_type === 'PERSON' &&
      borrowerType === EFunctions.BORROWER
    ) {
      fetchPersonFile(borrowerData?.id as string);
    } else {
      fetchBusinessFile(borrowerData?.id as string);
    }
  }, [borrowerData, borrowerType, productData]);

  useEffect(() => {
    if (productData) {
      const documentsKey =
        productData?.borrower_type === 'PERSON'
          ? 'person_document'
          : 'business_document';
      const borrowerDocuments =
        borrowerType === EFunctions.BORROWER
          ? productData?.variables_configuration?.borrower[documentsKey]
          : productData?.variables_configuration?.employer_document;

      if (borrowerDocuments)
        updateRequiredDocuments(
          borrowerDocuments
            ?.filter((document) => {
              if (!document.required) return false;
              const hasUploaded = uploadedFiles.find(
                (uploadedFile) => uploadedFile.type === document.type,
              );
              return !hasUploaded;
            })
            .map((document) => document.type),
        );
    }
  }, [productData, uploadedFiles]);

  const isAllowToForward = useMemo(
    () =>
      isProfileAuthorized(
        EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
      ) && borrowerType === EFunctions.BORROWER,
    [borrowerType],
  );

  const onForwardRequest = useCallback(() => {
    if (isAllowToForward) {
      onForward();
      return;
    }
    onStartCreateQualififcation(true);
  }, [borrowerType, isAllowToForward]);

  return (
    <StepContent
      title="Documentos"
      description="Envie os documentos necessários para a linha de crédito"
    >
      <TableFilesWrapper>
        <Table
          tableHeadCell={tableHeadContent}
          tableBodyContent={uploadedFiles.map((item, index) => {
            return {
              id: String(index),
              cells: [
                {
                  id: '1',
                  content: (
                    <TableBodyFile
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        {
                          iconByFileType[
                            item?.file_extension?.toLocaleLowerCase() || 'pdf'
                          ]
                        }
                      </div>
                      <TextM weight="Semibold">{item?.file_name}</TextM>
                    </TableBodyFile>
                  ),
                },
                {
                  id: '2',
                  content: (
                    <TextM weight="Regular">
                      {AllDocumentsLabels[item?.type]}
                    </TextM>
                  ),
                },
                {
                  id: '3',
                  content: renderCreationDate(
                    item?.created_at || new Date().toISOString(),
                  ),
                },
                {
                  id: '4',
                  content: (
                    <DownloadButton
                      handleClick={() =>
                        handleDownloadFile(item.id || '', item)
                      }
                    />
                  ),
                },
                {
                  id: '5',
                  content: (
                    <DeleteButton
                      handleClick={() => {
                        handleDeleteFile(item.id || '');
                      }}
                    />
                  ),
                },
              ],
            };
          })}
        />
      </TableFilesWrapper>
      <UploadArea>
        <Upload handleDrop={handleDrop} loading={uploadedFilesLoading} />
      </UploadArea>
      {requiredDocuments.length > 0 && (
        <RequiredArea>
          <RequiredAreaIcon>
            <AttentionRequiredDocuments />
          </RequiredAreaIcon>
          <TextM weight="Bold">{`${requiredDocuments.length} documentos obrigatórios não foram enviados.`}</TextM>
          <RequiredDocumentsList>
            {requiredDocuments.map((document) => {
              return (
                <RequiredDocumentsListItem key={document as string}>
                  {AllDocumentsLabels[document]}
                </RequiredDocumentsListItem>
              );
            })}
          </RequiredDocumentsList>
        </RequiredArea>
      )}
      <StepContentButtonsArea style={{ marginTop: '15px' }}>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary
          disabled={requiredDocuments.length > 0}
          onClick={onForwardRequest}
        >
          {isAllowToForward ? 'Avançar' : 'Concluir'}
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default DocumentsStep;
