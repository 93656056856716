import {
  CustomSelect,
  CalendarInput,
  CustomInput,
  CustomCurrencyInput,
} from 'app/components';
import { useFormik } from 'formik';
import React, { useCallback, useContext } from 'react';
import { formatCNPJ, formatPhone } from '../../../../../../app/helpers';
import { formatDateFromBackend } from '../../../../../../app/helpers/dateHelpers';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import {
  ETaxRegime,
  TRegisterBusinessDataRequest,
} from '../../../../context/CustomerProvider/customer.interfaces';
import { aboutStepSchema } from '../../../../helpers/businessValidation';
import { LegalPersonViewPageContext } from '../../LegalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import {
  IncorporationTypeObject,
  TaxRegimeObject,
} from 'modules/customer/context/CustomerProvider/customer.constants';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { TReactChangeInput } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { INCORPORATION_TYPES } from 'modules/customer/pages/LegalPersonHandler/steps/AboutStep';

export interface ILegalPersonEditAboutSection {
  business: TRegisterBusinessDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const LegalPersonEditAboutSection = ({
  business,
  handleClose,
  isOpen,
}: ILegalPersonEditAboutSection) => {
  const { updateBusiness, isLoading } = useContext(LegalPersonViewPageContext);
  const formik = useFormik({
    initialValues: {
      tax_regime: {
        label: TaxRegimeObject[business.tax_regime as ETaxRegime],
        value: business.tax_regime,
      },
      taxpayer_id: business.taxpayer_id || '',
      legal_name: business.legal_name || '',
      name: business.name || '',
      phone: formatPhone(
        business.phone.area_code + business.phone.number || '',
      ),
      email_address: business.email_address || '',
      foundation_date: business.foundation_date
        ? formatDateFromBackend(business.foundation_date).toISOString()
        : '',
      monthly_revenue:
        formatCurrency(business.monthly_revenue, false) || '0,00',
      share_capital: formatCurrency(business.share_capital, false) || '0,00',
      industry_classification: business.industry_classification || '',
      incorporation_type: business.incorporation_type
        ? {
            label: IncorporationTypeObject[business.incorporation_type],
            value: business.incorporation_type,
          }
        : {
            label: '',
            value: undefined,
          },
    },
    validationSchema: aboutStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const businessData: TRegisterBusinessDataRequest = {
        ...business,
        tax_regime: values.tax_regime.value,
        taxpayer_id: values.taxpayer_id.replace(/\D+/g, ''),
        legal_name: values.legal_name.trim(),
        phone: {
          ...business.phone,
          area_code: values.phone?.replace(/\D+/g, '').slice(0, 2),
          number: values?.phone?.replace(/\D+/g, '').slice(2),
        },
        email_address: values.email_address.trim(),
        foundation_date: new Date(values.foundation_date).toISOString(),
        monthly_revenue: Number(formatToCurrencyFloat(values.monthly_revenue)),
        share_capital: Number(formatToCurrencyFloat(values.share_capital)),
        incorporation_type: values.incorporation_type?.value,
        industry_classification:
          values.industry_classification.length > 0
            ? values.industry_classification.trim()
            : undefined,
        name: values.name.length > 0 ? values.name.trim() : undefined,
      };
      updateBusiness(businessData).then(() => {
        onClose();
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_revenue',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleShareCapitalChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'share_capital',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      title="Sobre"
    >
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomInput
            name="legal_name"
            value={formik.values.legal_name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Empresa S.A."
            labelValue="Razão social"
            validationError={
              formik.touched.legal_name ? formik.errors.legal_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="name"
            value={formik.values.name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Empresa S.A."
            labelValue="Nome fantasia"
            validationError={formik.touched.name ? formik.errors.name : ''}
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="email_address"
            value={formik.values.email_address}
            handleInputChange={formik.handleChange}
            placeholder="exemplo@email.com"
            labelValue="E-mail"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="taxpayer_id"
            disabled
            value={formatCNPJ(formik.values.taxpayer_id)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="00.000.000/0000-00"
            labelValue="CNPJ"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values.phone)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            name="foundation_date"
            labelValue="Data de Constituição"
            placeholder="dd/mm/aaaa"
            value={formik.values.foundation_date}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'foundation_date',
                },
              })
            }
            validationError={
              formik.touched.foundation_date
                ? formik.errors.foundation_date
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Faturamento mensal"
            value={formik.values.monthly_revenue}
            handleInputChange={handleMonthlyIncomeChange}
            name="monthly_revenue"
            validationError={
              formik.touched.monthly_revenue
                ? formik.errors.monthly_revenue
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Capital Social"
            value={formik.values.share_capital}
            handleInputChange={handleShareCapitalChange}
            name="share_capital"
            validationError={
              formik.touched.share_capital ? formik.errors.share_capital : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Regime Fiscal"
            name="tax_regime"
            placeholder="Selecione o regime fiscal"
            value={formik.values.tax_regime}
            handleInputChange={formik.handleChange}
            options={[
              { value: ETaxRegime.SIMPLE, label: 'Simples Nacional' },
              { value: ETaxRegime.REAL_PROFIT, label: 'Lucro real' },
              { value: ETaxRegime.PRESUMED_PROFIT, label: 'Lucro presumido' },
            ]}
            validationError={
              formik.touched.tax_regime?.value
                ? formik.errors.tax_regime?.value
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Formato Jurídico"
            name="incorporation_type"
            placeholder="Selecione o regime fiscal"
            value={formik.values.incorporation_type}
            handleInputChange={formik.handleChange}
            options={INCORPORATION_TYPES}
            validationError={
              formik.touched.tax_regime ? formik.errors.tax_regime?.value : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="industry_classification"
            value={formik.values.industry_classification}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Comércio de roupas e acessórios"
            labelValue="Classificação da indústria"
            validationError={
              formik.touched.industry_classification
                ? formik.errors.industry_classification
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default LegalPersonEditAboutSection;
