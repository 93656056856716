import { CustomSelect, CustomInput } from 'app/components';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { BRAZILIAN_STATES, formatCep } from '../../../../../../app/helpers';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TRegisterPersonDataRequest } from '../../../../context';
import { addressStepSchema } from '../../../../helpers/businessValidation';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import { useAddress } from 'app/hooks';
import { BRAZILIAN_STATES_OBJECT } from 'app/helpers/addressHelper';

export interface INaturalPersonEditAdressSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const NaturalPersonEditAdressSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditAdressSection) => {
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const [citiesList, setCitiesList] = useState<{ value: string }[]>([]);
  const { fetchCities, fetchAddressByPostalCode, isFetchingPostalCode } =
    useAddress();
  const [cityManually, setCityManually] = useState(true);
  const [stateCode, setStateCode] = useState('');

  const formik = useFormik({
    initialValues: {
      country_code: {
        label: personData.address?.country_code || 'BRA',
        value: personData.address?.country_code || 'BRA',
      },
      postal_code: personData.address?.postal_code || '',
      district: personData.address?.district || '',
      street_name: personData.address?.street_name || '',
      street_number: personData.address?.street_number || '',
      extra_info: personData.address?.extra_info || '',
      cityManually: personData.address?.city || '',
      state_code: {
        label:
          personData.address?.state_code &&
          BRAZILIAN_STATES_OBJECT[personData.address?.state_code],
        value: personData.address?.state_code,
      },
      city: {
        label: personData.address?.city,
        value: personData.address?.city,
      },
    },
    validationSchema: addressStepSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const personDataValue: TRegisterPersonDataRequest = {
        ...personData,
        address: {
          country_code: values.country_code.value,
          postal_code: values.postal_code.replace(/\D+/g, ''),
          district: values.district.trim(),
          street_name: values.street_name.trim(),
          street_number: String(values.street_number).trim(),
          extra_info: values.extra_info.trim(),
          state_code: values.state_code.value || '',
          city: values.city.value || '',
          ...(citiesList?.length <= 0 && {
            city: values.cityManually.trim(),
          }),
        },
      };

      updatePerson(personDataValue).then(() => {
        onClose();
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleZipode = async (value: string) => {
    if (value.length < 9) return;
    const res = await fetchAddressByPostalCode(value);
    if (res && res.data.status === '200') {
      const values = res.data.body.address;
      const stateCodeFound = BRAZILIAN_STATES.find(
        (state) =>
          state.value.toLocaleLowerCase() === values.state.toLocaleLowerCase(),
      )?.value;
      formik.setFieldValue('district', values.neighborhood);
      formik.setFieldValue('street_name', values.street);
      formik.setFieldValue(
        'state_code',
        BRAZILIAN_STATES.find(
          (state) =>
            state.value.toLocaleLowerCase() ===
            values.state.toLocaleLowerCase(),
        ),
      );

      const resCities = await handleCities(stateCodeFound || '');
      const foundCity = resCities.find(
        (item: { value: string; label: string }) =>
          item.value.toLowerCase() === values.city.toLowerCase(),
      );
      formik.setFieldValue('city', foundCity);
      return;
    }
    setCityManually(true);
  };

  const handleCities = async (stateCodeFound?: string) => {
    setCityManually(false);
    try {
      const result = await fetchCities(stateCodeFound ?? stateCode);
      if (!result || result.data.status !== '200') {
        setCityManually(true);
        return [];
      }

      const parsedResult = result.data?.body?.city?.map?.(
        (item: { name: string; id: string }) => {
          return {
            value: item.name,
            label: item.name,
          };
        },
      );
      setCitiesList(parsedResult || []);
      return parsedResult;
    } catch (error) {
      setCityManually(true);
    }
  };

  const handleNumber = (numberValue: string) => {
    formik.setFieldValue(
      'street_number',
      numberValue.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
    );
  };

  useEffect(() => {
    if (stateCode) {
      handleCities();
    }
  }, [stateCode]);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      title="Endereço"
    >
      <StepContentInputArea>
        <StepContentInput>
          <CustomInput
            name="postal_code"
            value={formatCep(formik.values.postal_code)}
            handleInputChange={formik.handleChange}
            isLoading={isFetchingPostalCode}
            onBlur={(evt) => {
              formik.handleBlur(evt);
              if (formik.values.postal_code)
                handleZipode(formik.values.postal_code);
            }}
            labelValue="CEP"
            validationError={
              formik.touched.postal_code ? formik.errors.postal_code : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="street_name"
            value={formik.values.street_name}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Av Presidente Vargas"
            labelValue="Rua/Avenida"
            validationError={
              formik.touched.street_name ? formik.errors.street_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="street_number"
            value={formik.values.street_number}
            handleInputChange={(e) => handleNumber(e.target.value)}
            placeholder="Ex.: 300"
            labelValue="Número"
            validationError={
              formik.touched.street_number ? formik.errors.street_number : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="extra_info"
            value={formik.values.extra_info}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Próximo a escola..."
            labelValue="Complemento (opcional)"
            validationError={
              formik.touched.extra_info ? formik.errors.extra_info : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="district"
            value={formik.values.district}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Planalto do Sol"
            labelValue="Bairro"
            validationError={
              formik.touched.district ? formik.errors.district : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Estado"
            name="state_code"
            placeholder="Selecione o estado"
            value={formik.values.state_code}
            handleInputChange={(v) => {
              formik.handleChange(v);
              setStateCode(v.currentTarget.value.value);
            }}
            options={BRAZILIAN_STATES}
            validationError={
              formik.touched.state_code?.value
                ? formik.errors.state_code?.value
                : ''
            }
          />
        </StepContentInput>

        <StepContentInput>
          {cityManually ? (
            <CustomInput
              name="cityManually"
              value={formik.values.cityManually}
              handleInputChange={(evt) => {
                formik.setFieldValue('cityManually', evt.target.value);
                formik.setFieldValue('city', {
                  label: evt.target.value,
                  value: evt.target.value,
                });
              }}
              placeholder="Cidade"
              labelValue="Cidade"
              validationError={
                formik.touched.city?.value ? formik.errors.city?.value : ''
              }
            />
          ) : (
            <CustomSelect
              labelValue="Cidade"
              name="city"
              placeholder="Selecione a cidade"
              value={formik.values.city}
              handleInputChange={formik.handleChange}
              options={citiesList}
              validationError={
                formik.touched.city?.value ? formik.errors.city?.value : ''
              }
            />
          )}
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditAdressSection;
