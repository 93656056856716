import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { CalendarInput, Heading4, Popover } from 'app/components';
import {
  CloseBtn,
  FilterMovementsButtons,
  FilterMovementsDropdownBody,
  FilterMovementsDropdownContainer,
  FilterMovementsDropdownHeader,
  FilterMovementsInput,
  FilterMovementsInputInvalid,
  FilterMovementsInputs,
} from './styles';
import { ButtonPrimary } from 'app/components';
import { Close } from '@mui/icons-material';
import { TFilterType } from 'modules/disbursement/interfaces/disbursement';
import {
  formatDateForBackendString,
  formatDateFromBackend,
} from 'app/helpers/dateHelpers';
import { Divider, useTheme } from '@mui/material';

const buttonStyles = {
  width: 'fit-content',
  padding: '5px 10px',
  fontSize: '14px',
  marginRight: '5px',
};

export type TStatusItem = {
  title: string;
  value: string;
  selected: boolean;
};

export interface IFilterMovementsDropdownProps {
  open: boolean;
  children?: React.ReactElement;
  dateFrom: string;
  dateTo: string;
  filterType: TFilterType;
  refElement: RefObject<HTMLDivElement>;
  handleClose?: () => void;
  handleFilter: (
    dateFrom: string,
    dateTo: string,
    filterType: TFilterType,
  ) => void;
}
const FilterMovementsDropdown = ({
  open,
  refElement,
  filterType,
  dateFrom,
  dateTo,
  handleClose,
  handleFilter,
}: IFilterMovementsDropdownProps) => {
  const theme = useTheme();
  const [dateFromInner, updateDateFromInner] = useState<string | null>(null);
  const [dateToInner, updateDateToInner] = useState<string | null>(null);
  const [filterTypeInner, updateFilterInner] =
    useState<TFilterType>(filterType);

  useEffect(() => {
    if (open) {
      updateFilterInner(filterType);
      updateDateFromInner(formatDateFromBackend(dateFrom).toISOString());
      updateDateToInner(formatDateFromBackend(dateTo).toISOString());
    }
  }, [open]);

  const isInvalid = useMemo(() => {
    if (filterTypeInner !== TFilterType.CUSTOM) return false;
    if (!dateToInner || !dateFromInner) return 'Preencha todas as datas';

    if (
      new Date(dateToInner) > new Date() ||
      new Date(dateFromInner) > new Date() ||
      new Date(dateFromInner) > new Date(dateToInner)
    )
      return 'Datas selecionadas inválidas';

    return false;
  }, [dateFromInner, dateToInner, filterTypeInner]);

  return (
    <Popover
      open={open}
      anchorEl={refElement.current}
      onClose={handleClose}
      sx={{
        '& .MuiPopover-paper	': {
          marginTop: '60px',
          borderRadius: '20px',
        },
      }}
    >
      <FilterMovementsDropdownContainer>
        <FilterMovementsDropdownHeader>
          <Heading4>Data</Heading4>
          <CloseBtn onClick={handleClose}>
            <Close sx={{ color: theme.palette.brand.secondary.base }} />
          </CloseBtn>
        </FilterMovementsDropdownHeader>
        <FilterMovementsDropdownBody>
          <FilterMovementsButtons>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.TODAY ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.TODAY)}
              style={buttonStyles}
            >
              Hoje
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.YESTERDAY ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.YESTERDAY)}
              style={buttonStyles}
            >
              Ontem
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.LAST_7_DAYS ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.LAST_7_DAYS)}
              style={buttonStyles}
            >
              Últimos 7 dias
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.CUSTOM ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.CUSTOM)}
              style={buttonStyles}
            >
              Escolher período
            </ButtonPrimary>
          </FilterMovementsButtons>
          {filterTypeInner === TFilterType.CUSTOM && (
            <>
              <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
              <FilterMovementsInputs>
                <FilterMovementsInput>
                  <CalendarInput
                    name="dateFrom"
                    labelValue=""
                    placeholder="dd/mm/aaaa"
                    value={dateFromInner}
                    onChange={(e) => {
                      try {
                        updateDateFromInner(e?.toISOString() ?? null);
                      } catch {
                        updateDateFromInner(null);
                      }
                    }}
                    validationError={''}
                  />
                </FilterMovementsInput>
                <FilterMovementsInput>
                  <CalendarInput
                    name="dateTo"
                    labelValue=""
                    placeholder="dd/mm/aaaa"
                    value={dateToInner}
                    onChange={(e) => {
                      try {
                        updateDateToInner(e?.toISOString() ?? null);
                      } catch {
                        updateDateToInner(null);
                      }
                    }}
                    validationError={''}
                  />
                </FilterMovementsInput>
              </FilterMovementsInputs>
              {isInvalid !== false && (
                <FilterMovementsInputInvalid>
                  {isInvalid}
                </FilterMovementsInputInvalid>
              )}
            </>
          )}
        </FilterMovementsDropdownBody>
        <ButtonPrimary
          style={{ marginTop: '25px' }}
          disabled={
            (filterType === filterTypeInner &&
              filterTypeInner !== TFilterType.CUSTOM) ||
            isInvalid !== false
          }
          onClick={() =>
            handleFilter(
              formatDateForBackendString(
                new Date(dateFromInner as string),
              ) as string,
              formatDateForBackendString(
                new Date(dateToInner as string),
              ) as string,
              filterTypeInner,
            )
          }
        >
          Aplicar filtro
        </ButtonPrimary>
      </FilterMovementsDropdownContainer>
    </Popover>
  );
};

export default FilterMovementsDropdown;
