import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Divider, Tooltip, useTheme } from '@mui/material';
import { ButtonPrimary } from '../../ButtonPrimary';
import { BroomIcon, CopyIcon } from '../../Icons';
import { Tabs } from '../../Tabs';
import { Heading3, Heading4, TextButton, TextM } from '../../typography';
import {
  CreditSimulatorContentBox,
  CreditSimulatorContentWrapper,
  DisbursementDateInput,
  DisbursementDatesSelector,
  InputArea,
  InputButtonArea,
  InputContent,
  InputsGrid,
  InputsWrapper,
  MultipleDisbursementDateContainer,
  MultipleDisbursementDatesSelector,
  NumPaymentContent,
  PreviewArea,
  PreviewButtonsArea,
  PreviewItem,
  PreviewItems,
  TabArea,
} from './styles';
import {
  formatBRLCurrencyMaskInput,
  getDigitsFromValue,
} from '../../../utils/currency';
import { useFormik } from 'formik';
import {
  creditSimulatorValidation,
  formatDecimalField,
} from '../../../helpers';
import {
  formatCurrency,
  formatToFixed,
  normalizeAddDigits,
} from '../../../utils/normalizer';
import { formatDateFromBackend } from '../../../helpers/dateHelpers';
import useCreditSimulator from '../../../hooks/useCreditSimulator';
import { Spinner } from '../../Spinner';
import { TProducts } from 'modules/products/context';
import {
  simulatorActionTypes,
  useSimulatorContext,
} from 'modules/simulation/context';
import {
  AMORTIZATION_TYPES,
  FINANCED_IOF,
  INTEREST_COMPOUNDING_TYPES,
  IOFTYPES,
  IOFTYPES_TRANSLATED_EN_PT,
  SCHEDULE_TYPES,
  SCHEDULE_TYPES_TRANSLATED_EN_PT,
  TABS_CONTENT,
} from 'app/helpers/simulatiorHelper';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Theme } from '@emotion/react';
import { getValidDisbursementDate } from '../helpers';
import { TacValueType } from 'modules/products/pages/ApplicationRequest/ApplicationRequest';
import { CustomSelect } from 'app/components/CustomSelect';
import { useAppContext } from 'app/context/AppContextProvider';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { Accordion } from 'app/components/Accordion';
import { InfoOutlined } from '@mui/icons-material';
import { CustomCurrencyInput } from 'app/components/CustomCurrencyInput';
import { CalendarInput } from 'app/components/CalendarInput';
import { CustomInput } from 'app/components/CustomInput';
import { Table } from 'app/components/Table';

const tableHeadContent = [
  { id: '1', content: null },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Valor no vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Amortização</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Juros</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">IOF</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
];

export interface ICreditSimulatorContent {
  mode: 'COMPONENT' | 'MODAL';
  product?: TProducts;
  simulationData?: TSimulatorParametersData | null;
  simulationPreviewData?: TPreviewSimulationData | null;
  onDefineSimulation?: (
    previewSimulationData: TPreviewSimulationData,
    simulationRequest: TSimulatorParametersData,
  ) => void;
  onRenderPreviewButtonsArea?: (
    previewSimulationData: TPreviewSimulationData,
  ) => JSX.Element;
}

const PreviewDivider = ({ theme }: { theme: Theme }) => (
  <Divider
    sx={{
      backgroundColor: theme.palette?.brand.primary.base,
      marginTop: '12px',
      opacity: 0.16,
    }}
  />
);

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export type TPreviewSimulationData = {
  numPeriods: number | null;
  paymentAmount: number | null;
  disbursementAmount?: number | null;
  interestRate: number | null;
  monthlyEffectiveInterestRate: number | null;
  tacAmount: number | null;
  iofAmount: number | null;
  firstPaymentDate: string | null;
  lastPaymentDate: string | null;
  totalAmountOwed: number | null;
  financed_amount: number | null;
  discount_amount?: number | null;
  iofBaseRate: number | null;
  requested_amount: number | null;
  disbursement_date: string | null;
  finance_fee: number | null;
  schedule?: Array<{
    payment: number;
    iof: number;
    balance: number;
    interest: number;
    principal: number;
    period: number;
    running_day: number;
    payment_date: string;
  }>;
};

export type TSimulatorParametersData = {
  amount: string;
  numPayments: string;
  tacType: { value: string; label: string };
  tacAmount: string;
  tacRate?: string;
  interestRate: string;
  financeFee: string;
  discountRate?: string;
  iofType: { value: string; label: string };
  scheduleType: { value: string; label: string };
  amortizationType: { value: string; label: string };
  interestCompoundingType: { value: string; label: string };
  financedIof: { value: string; label: string };
  firstPaymentDate: string;
  disbursementDate: string;
};

const CreditSimulatorContent = ({
  mode,
  product,
  simulationPreviewData,
  onDefineSimulation,
  onRenderPreviewButtonsArea,
}: ICreditSimulatorContent) => {
  const theme = useTheme();
  const { showSnackbarCopy } = useSnackbar();
  const { simulate, simulationLoading } = useCreditSimulator();
  const [isAccordionOpen, setIsAccordionOpen] = useState('conditions');
  const {
    state: { simulatorParametersData },
    dispatch,
  } = useSimulatorContext();
  const [previewSimulationData, setPreviewSimulationData] =
    useState<TPreviewSimulationData>(
      simulationPreviewData ?? {
        numPeriods: null,
        paymentAmount: null,
        disbursementAmount: null,
        interestRate: null,
        monthlyEffectiveInterestRate: null,
        tacAmount: null,
        iofAmount: null,
        firstPaymentDate: null,
        iofBaseRate: null,
        lastPaymentDate: null,
        totalAmountOwed: null,
        disbursement_date: null,
        financed_amount: null,
        requested_amount: null,
        finance_fee: null,
      },
    );
  const [tabIndex, setTabIndex] = useState(
    simulatorParametersData?.amountKey || '0',
  );
  const { feature_flags } = useAppContext();

  const isDiscountRateVisible = useMemo(() => {
    return feature_flags?.DISCOUNT_RATE_ENABLED
      ? feature_flags?.DISCOUNT_RATE_ENABLED?.isActive
      : false;
  }, [feature_flags]);

  const baseIofPerInstallment = useMemo(() => {
    const sum_running_iof =
      previewSimulationData?.schedule?.reduce((sum, j) => sum + j.iof, 0) || 0;

    if (
      !previewSimulationData?.iofAmount ||
      !previewSimulationData.numPeriods
    ) {
      return 0;
    }

    return (
      (previewSimulationData?.iofAmount - sum_running_iof) /
      previewSimulationData.numPeriods
    );
  }, [previewSimulationData]);

  const handleCopySchedule = useCallback(() => {
    const header =
      'Parcela\tValor no vencimento\tAmortização\tJuros\tIOF\tVencimento\r\n';
    const records = previewSimulationData.schedule
      ?.map((schedule, inx) => {
        const { payment, principal, interest, payment_date, iof } = schedule;
        const finalIof = baseIofPerInstallment + iof;
        return `${
          inx + 1
        }\t${payment.toLocaleString()}\t${principal.toLocaleString()}\t${interest.toLocaleString()}\t${finalIof}\t${payment_date}`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${records}`);
    showSnackbarCopy();
  }, [previewSimulationData, baseIofPerInstallment]);

  const handleAmountChange = useCallback(
    (evt: TReactChangeInput) => {
      formik.setFieldValue(
        'amount',
        formatBRLCurrencyMaskInput(evt.target.value || ''),
      );
    },
    [product],
  );

  const handleTacAmountChange = (evt: TReactChangeInput) => {
    if (formik.values.tacType.value === TacValueType.PERCENTAGE) {
      formik.setFieldValue('tacRate', formatDecimalField(evt.target.value));
      return;
    }
    formik.setFieldValue(
      'tacAmount',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  };

  const handleNumPayments = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue('numPayments', getDigitsFromValue(evt.target.value));
  }, []);

  const handleFinanceFee = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'financeFee',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleInterestRate = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  const handleTacValueType = () => {
    return [
      //VALOR ABSOLUTO must be the first element
      {
        label: 'VALOR ABSOLUTO',
        value: TacValueType.MONETARY,
      },
      {
        label: 'VALOR PERCENTUAL',
        value: TacValueType.PERCENTAGE,
      },
    ];
  };

  const formik = useFormik({
    initialValues: {
      amount: simulatorParametersData
        ? formatCurrency(simulatorParametersData?.amount, false) || '0,00'
        : '0,00',
      numPayments: simulatorParametersData
        ? String(simulatorParametersData.numPayments)
        : '1',
      tacType:
        simulatorParametersData && simulatorParametersData.tacType
          ? simulatorParametersData.tacType
          : handleTacValueType()[0],
      tacAmount: simulatorParametersData
        ? formatCurrency(simulatorParametersData.tacAmount, false) || '0,00'
        : '0,00',
      tacRate:
        simulatorParametersData && simulatorParametersData.tacRate
          ? String(
              formatToFixed(simulatorParametersData.tacRate * 100, 2),
            ).replace('.', ',') || '0'
          : '0',
      interestRate: simulatorParametersData
        ? String(
            formatToFixed(simulatorParametersData.interestRate * 100, 2),
          ).replace('.', ',')
        : '1',
      discountRate:
        simulatorParametersData && simulatorParametersData.discountRate
          ? String(
              formatToFixed(simulatorParametersData.discountRate * 100, 2),
            ).replace('.', ',') || '0'
          : '0',
      financeFee: simulatorParametersData
        ? formatCurrency(simulatorParametersData?.financeFee, false) || '0,00'
        : '0,00',
      iofType:
        simulatorParametersData && simulatorParametersData.iofType
          ? simulatorParametersData.iofType
          : IOFTYPES[0],
      scheduleType:
        typeof product === 'object'
          ? {
              label: product.schedule_type as string,
              value: product.schedule_type as string,
            }
          : SCHEDULE_TYPES[0],
      amortizationType:
        typeof product === 'object'
          ? {
              label: product.amortization_type as string,
              value: product.amortization_type as string,
            }
          : AMORTIZATION_TYPES[0],
      interestCompoundingType:
        typeof product === 'object'
          ? {
              label: product.interest_compounding_type as string,
              value: product.interest_compounding_type as string,
            }
          : INTEREST_COMPOUNDING_TYPES[0],
      financedIof:
        typeof product === 'object'
          ? {
              label: String(product.financed_iof),
              value: String(product.financed_iof),
            }
          : FINANCED_IOF[0],
      firstPaymentDate: simulatorParametersData
        ? formatDateFromBackend(
            simulatorParametersData.firstPaymentDate as string,
          ).toISOString()
        : new Date(
            new Date().setMonth(new Date().getMonth() + 1),
          ).toISOString(),
      disbursementDate:
        simulatorParametersData && simulatorParametersData.disbursementDate
          ? formatDateFromBackend(
              simulatorParametersData.disbursementDate as string,
            ).toISOString()
          : getValidDisbursementDate(product?.multiple_disbursements_dates),
    },
    validationSchema: creditSimulatorValidation(
      product?.multiple_disbursements_dates,
    ),
    enableReinitialize: false,
    onSubmit: async (values) => {
      const res = await simulate(values, String(tabIndex), product?.id);
      const data = res?.data;

      const simulationDataRequest: TPreviewSimulationData = {
        numPeriods: data?.num_periods,
        paymentAmount: data?.payment_amount,
        requested_amount: data?.requested_amount,
        interestRate: data?.interest_rate,
        monthlyEffectiveInterestRate: data?.monthly_effective_interest_rate,
        tacAmount: data?.tac_amount,
        iofAmount: data?.iof_amount,
        iofBaseRate: data?.iof_base_rate,
        firstPaymentDate: data?.first_payment_date,
        lastPaymentDate: data?.last_payment_date,
        totalAmountOwed: data?.total_amount_owed,
        financed_amount: data?.financed_amount,
        disbursement_date: data?.disbursement_date,
        schedule: data?.schedule,
        finance_fee: data?.finance_fee,
      };
      setPreviewSimulationData(simulationDataRequest);
      if (typeof onDefineSimulation === 'function') {
        onDefineSimulation(simulationDataRequest, values);
      }
    },
  });

  const simulateWithDifferentDisbursementDate = useCallback(
    async (disbursementDate: string) => {
      const values = formik.values;
      const res = await simulate(
        { ...values, disbursementDate },
        String(tabIndex),
        product?.id,
      );
      const data = res?.data;

      const simulationDataRequest: TPreviewSimulationData = {
        numPeriods: data?.num_periods,
        paymentAmount: data?.payment_amount,
        requested_amount: data?.requested_amount,
        interestRate: data?.interest_rate,
        monthlyEffectiveInterestRate: data?.monthly_effective_interest_rate,
        tacAmount: data?.tac_amount,
        iofAmount: data?.iof_amount,
        iofBaseRate: data?.iof_base_rate,
        firstPaymentDate: data?.first_payment_date,
        lastPaymentDate: data?.last_payment_date,
        totalAmountOwed: data?.total_amount_owed,
        financed_amount: data?.financed_amount,
        disbursement_date: data?.disbursement_date,
        schedule: data?.schedule,
        finance_fee: data?.finance_fee,
      };
      setPreviewSimulationData(simulationDataRequest);
    },
    [tabIndex, product, formik.values],
  );

  const handleDiscountRate = (evt: TReactChangeInput) => {
    formik.setFieldValue('discountRate', formatDecimalField(evt.target.value));
  };

  const handleSimulator = useCallback(() => {
    setPreviewSimulationData({
      numPeriods: null,
      paymentAmount: null,
      disbursementAmount: null,
      interestRate: null,
      monthlyEffectiveInterestRate: null,
      tacAmount: null,
      iofAmount: null,
      iofBaseRate: null,
      firstPaymentDate: null,
      lastPaymentDate: null,
      totalAmountOwed: null,
      financed_amount: null,
      requested_amount: null,
      finance_fee: null,
      disbursement_date: null,
    });
    formik.submitForm();
  }, []);

  const resetPreview = useCallback(() => {
    setPreviewSimulationData({
      numPeriods: null,
      paymentAmount: null,
      disbursementAmount: null,
      interestRate: null,
      monthlyEffectiveInterestRate: null,
      tacAmount: null,
      iofAmount: null,
      iofBaseRate: null,
      firstPaymentDate: null,
      lastPaymentDate: null,
      totalAmountOwed: null,
      financed_amount: null,
      requested_amount: null,
      disbursement_date: null,
      finance_fee: null,
    });
  }, []);

  const handleResetForm = useCallback(() => {
    formik.resetForm();
    resetPreview();
    dispatch({
      type: simulatorActionTypes.RESET_SIMULATOR_DATA,
    });
    if (typeof product === 'object') {
      formik.setFieldValue('scheduleType', {
        label: product.schedule_type as string,
        value: product.schedule_type as string,
      });
      formik.setFieldValue('iofType', {
        label: product.iof_zero
          ? IOFTYPES_TRANSLATED_EN_PT['ZERO']
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? IOFTYPES_TRANSLATED_EN_PT['PERSON']
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? IOFTYPES_TRANSLATED_EN_PT['BUSINESS']
          : IOFTYPES_TRANSLATED_EN_PT['BUSINESS_SIMPLE'],
        value: product.iof_zero
          ? 'ZERO'
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? 'PERSON'
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? 'BUSINESS'
          : 'BUSINESS_SIMPLE',
      });
    }
  }, [product]);

  const handleChangeTabIndex = useCallback(
    (value: number) => {
      if (formik.getFieldMeta('amount').value !== '0,00') {
        formik.setFieldValue('amount', '0,00');
        resetPreview();
      }
      setTabIndex(String(value));
      formik.setFieldValue('tacType', handleTacValueType()[0]);
      formik.setFieldValue('discountRate', '0');
    },
    [formik],
  );

  const handleIOFTypes = () => {
    return product?.iof_zero
      ? IOFTYPES.filter((i) => i.value === 'ZERO')
      : !product?.iof_zero && product?.borrower_type === 'PERSON'
      ? IOFTYPES.filter((i) => i.value === 'PERSON')
      : !product?.iof_zero && product?.borrower_type === 'BUSINESS'
      ? IOFTYPES.filter((i) => i.value.includes('BUSINESS'))
      : IOFTYPES.filter((i) => i.value.includes('BUSINESS'));
  };

  useEffect(() => {
    if (product) {
      formik.setFieldValue('scheduleType', {
        label: product.schedule_type as string,
        value: product.schedule_type as string,
      });
      formik.setFieldValue('interestCompoundingType', {
        label: product.interest_compounding_type as string,
        value: product.interest_compounding_type as string,
      });
      formik.setFieldValue('amortizationType', {
        label: product.amortization_type as string,
        value: product.amortization_type as string,
      });
      formik.setFieldValue('financedIof', {
        label: String(product.financed_iof),
        value: String(product.financed_iof),
      });
      formik.setFieldValue('iofType', {
        label: product.iof_zero
          ? IOFTYPES_TRANSLATED_EN_PT['ZERO']
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? IOFTYPES_TRANSLATED_EN_PT['PERSON']
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? IOFTYPES_TRANSLATED_EN_PT['BUSINESS']
          : IOFTYPES_TRANSLATED_EN_PT['BUSINESS_SIMPLE'],
        value: product.iof_zero
          ? 'ZERO'
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? 'PERSON'
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? 'BUSINESS'
          : 'BUSINESS_SIMPLE',
      });
    }
  }, [product]);

  return (
    <CreditSimulatorContentWrapper>
      <CreditSimulatorContentBox>
        <InputArea mode={mode}>
          <Accordion
            isOpen={isAccordionOpen === 'conditions'}
            title={<Heading4>Condições</Heading4>}
            onClick={() =>
              setIsAccordionOpen(
                isAccordionOpen === 'conditions' ? 'numPayments' : 'conditions',
              )
            }
          >
            <>
              <InputContent mode={mode}>
                <TabArea mode={mode}>
                  <TextM
                    weight="Bold"
                    style={{
                      color: theme.palette.brand.secondary.base,
                      minWidth: '150px',
                    }}
                  >
                    Definir por valor:
                  </TextM>
                  <Tabs
                    content={TABS_CONTENT}
                    selectedIndex={Number(tabIndex)}
                    handleClick={handleChangeTabIndex}
                  />
                </TabArea>
                <InputsWrapper mode={mode}>
                  <TextM
                    weight="Bold"
                    style={{
                      color: theme.palette.brand.secondary.base,
                      marginBottom: '24px',
                    }}
                  >
                    Definições:
                  </TextM>
                  <InputsGrid mode={mode}>
                    <CustomCurrencyInput
                      labelValue={`Valor ${TABS_CONTENT[Number(tabIndex)]}`}
                      value={formik.values.amount}
                      handleInputChange={handleAmountChange}
                      name="amount"
                      validationError={
                        formik.touched.amount ? formik.errors.amount : ''
                      }
                    />
                    <CustomInput
                      labelValue="Prazo (parcelas)"
                      value={formik.values.numPayments}
                      handleInputChange={handleNumPayments}
                      name="numPayments"
                      validationError={
                        formik.touched.numPayments
                          ? formik.errors.numPayments
                          : ''
                      }
                    />
                    {isDiscountRateVisible && (
                      <CustomInput
                        labelValue="Taxa de desconto (%)"
                        value={formik.values.discountRate}
                        handleInputChange={handleDiscountRate}
                        validationError={
                          formik.touched.discountRate
                            ? formik.errors.discountRate
                            : ''
                        }
                      />
                    )}
                    <CustomInput
                      labelValue="Taxa de juros (% a.m.)"
                      value={formik.values.interestRate}
                      handleInputChange={handleInterestRate}
                      validationError={
                        formik.touched.interestRate
                          ? formik.errors.interestRate
                          : ''
                      }
                    />
                    {tabIndex === '0' && (
                      <CustomSelect
                        labelValue="Tipo da taxa de cadastro"
                        value={formik.values.tacType}
                        options={handleTacValueType()}
                        handleInputChange={formik.handleChange}
                        name="tacType"
                        placeholder="Selecionar"
                        isClearable={false}
                        tooltip={`Valor Absoluto: Valor monetário cobrado na taxa de cadastro; 
                          \r\nValor Percentual: Valor cobrado na taxa de cadastro como porcentagem do valor solicitado`}
                      />
                    )}

                    {formik.values.tacType.value === TacValueType.MONETARY ? (
                      <CustomCurrencyInput
                        labelValue="Taxa de cadastro (absoluto)"
                        value={formik.values.tacAmount}
                        handleInputChange={handleTacAmountChange}
                        name="tacAmount"
                        validationError={
                          formik.touched.tacAmount
                            ? formik.errors.tacAmount
                            : ''
                        }
                      />
                    ) : (
                      <CustomInput
                        labelValue="Taxa de cadastro (%)"
                        value={formik.values.tacRate}
                        handleInputChange={handleTacAmountChange}
                        name="tacRate"
                        validationError={
                          formik.touched.tacRate ? formik.errors.tacRate : ''
                        }
                      />
                    )}
                    <CustomCurrencyInput
                      labelValue="Outros custos (absoluto)"
                      value={formik.values.financeFee}
                      handleInputChange={handleFinanceFee}
                      validationError={
                        formik.touched.financeFee
                          ? formik.errors.financeFee
                          : ''
                      }
                    />
                    {mode === 'MODAL' && (
                      <CustomSelect
                        labelValue="Tipo de IOF"
                        value={formik.values.iofType}
                        options={handleIOFTypes()}
                        handleInputChange={formik.handleChange}
                        name="iofType"
                        placeholder="Selecionar"
                        isClearable={false}
                        validationError={
                          formik.touched.iofType?.value
                            ? formik.errors.iofType?.value
                            : ''
                        }
                      />
                    )}
                    {mode === 'MODAL' && (
                      <CustomInput
                        labelValue="Periodicidade dos venc."
                        value={
                          SCHEDULE_TYPES_TRANSLATED_EN_PT[
                            formik.values.scheduleType.value
                          ]
                        }
                        handleInputChange={formik.handleChange}
                        name="scheduleType"
                        placeholder="Selecionar"
                        readOnly
                        validationError={
                          formik.touched.iofType?.value
                            ? formik.errors.iofType?.value
                            : ''
                        }
                      />
                    )}
                    <CalendarInput
                      name="foundation_date"
                      labelValue="Data do 1º pagamento"
                      placeholder="dd/mm/aaaa"
                      value={formik.values.firstPaymentDate}
                      onChange={(e) =>
                        formik.handleChange({
                          currentTarget: {
                            value: e,
                            name: 'firstPaymentDate',
                          },
                        })
                      }
                      validationError={
                        formik.touched.firstPaymentDate
                          ? formik.errors.firstPaymentDate
                          : ''
                      }
                    />
                    {product?.multiple_disbursements_dates ? (
                      <MultipleDisbursementDateContainer>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.8rem',
                            alignItems: 'center',
                          }}
                        >
                          <TextM
                            weight="Bold"
                            color={theme.palette.brand.black.base as string}
                          >
                            Operação com múltiplas datas de desembolso
                          </TextM>
                          <Tooltip
                            placement="bottom-start"
                            title={
                              <TextM
                                style={{ color: theme.palette.common.white }}
                              >
                                A operação será desembolsada de acordo com a
                                data de assinatura da operação, que poderá ser
                                entre a data de início e data fim do desembolso.
                              </TextM>
                            }
                          >
                            <InfoOutlined
                              style={{
                                fontSize: '1.2rem',
                                color: theme.palette.brand.gray[900],
                              }}
                            />
                          </Tooltip>
                        </div>
                        <MultipleDisbursementDatesSelector>
                          <DisbursementDateInput
                            disabled={true}
                            isMultiplesDisbursementDate={true}
                          >
                            <CalendarInput
                              name="disbursement_date_start"
                              labelValue="Data de início do desembolso"
                              placeholder="dd/mm/aaaa"
                              value={new Date().toISOString()}
                              //eslint-disable-next-line
                              onChange={() => {}}
                            />
                          </DisbursementDateInput>
                          <DisbursementDateInput
                            isMultiplesDisbursementDate={true}
                          >
                            <CalendarInput
                              name="foundation_date"
                              labelValue="Data fim do desembolso"
                              placeholder="dd/mm/aaaa"
                              value={formik.values.disbursementDate}
                              onChange={(e) =>
                                formik.handleChange({
                                  currentTarget: {
                                    value: e,
                                    name: 'disbursementDate',
                                  },
                                })
                              }
                              validationError={
                                formik.touched.disbursementDate
                                  ? formik.errors.disbursementDate
                                  : ''
                              }
                            />
                          </DisbursementDateInput>
                        </MultipleDisbursementDatesSelector>
                      </MultipleDisbursementDateContainer>
                    ) : (
                      <DisbursementDateInput>
                        <CalendarInput
                          name="foundation_date"
                          labelValue="Data de liberação do recurso"
                          placeholder="dd/mm/aaaa"
                          value={formik.values.disbursementDate}
                          onChange={(e) =>
                            formik.handleChange({
                              currentTarget: {
                                value: e,
                                name: 'disbursementDate',
                              },
                            })
                          }
                          validationError={
                            formik.touched.disbursementDate
                              ? formik.errors.disbursementDate
                              : ''
                          }
                        />
                      </DisbursementDateInput>
                    )}
                  </InputsGrid>
                </InputsWrapper>
              </InputContent>
              <Divider sx={{ marginTop: '22px' }} />
              <InputButtonArea>
                <ButtonPrimary
                  typeVariant="ghost"
                  btnIcon={
                    <BroomIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  iconPosition="left"
                  style={{ width: '45%' }}
                  onClick={handleResetForm}
                >
                  <TextButton style={{ marginLeft: '10px' }}>
                    {mode === 'MODAL' ? 'Limpar simulador' : 'Limpar'}
                  </TextButton>
                </ButtonPrimary>
                {simulationLoading ? (
                  <Spinner size={48} />
                ) : (
                  <ButtonPrimary
                    style={{ border: mode === 'MODAL' ? '' : '1px solid #ccc' }}
                    typeVariant={mode === 'MODAL' ? 'fill' : 'ghost'}
                    onClick={handleSimulator}
                  >
                    <TextButton>
                      {mode === 'MODAL' ? 'Simular' : 'Definir'}
                    </TextButton>
                  </ButtonPrimary>
                )}
              </InputButtonArea>
            </>
          </Accordion>
          <Accordion
            isOpen={isAccordionOpen === 'numPayments'}
            title={<Heading4>Visualizar parcelas</Heading4>}
            onClick={() =>
              setIsAccordionOpen(
                isAccordionOpen === 'numPayments'
                  ? 'conditions'
                  : 'numPayments',
              )
            }
          >
            <NumPaymentContent>
              {Array.isArray(previewSimulationData.schedule) &&
              previewSimulationData.schedule.length > 0 ? (
                <>
                  {product?.multiple_disbursements_dates && (
                    <DisbursementDatesSelector>
                      <CustomSelect
                        labelValue="Por data de desembolso"
                        options={new Array(
                          differenceInDays(
                            startOfDay(
                              new Date(formik.values.disbursementDate),
                            ),
                            startOfDay(new Date()),
                          ) + 1,
                        )
                          .fill(0)
                          .map((_, index) => {
                            const date = new Date();
                            date.setDate(date.getDate() + index);
                            return {
                              label: format(date, 'dd/MM/yyyy'),
                              value: startOfDay(date).toISOString(),
                            };
                          })}
                        handleInputChange={(evt) => {
                          const { value } = evt.currentTarget.value;
                          simulateWithDifferentDisbursementDate(value);
                        }}
                        isClearable={false}
                        value={{
                          label: format(
                            formatDateFromBackend(
                              previewSimulationData.disbursement_date!,
                            ),
                            'dd/MM/yyyy',
                          ),
                          value: startOfDay(
                            formatDateFromBackend(
                              previewSimulationData.disbursement_date!,
                            ),
                          ).toISOString(),
                        }}
                        name="disbursementScheduleDate"
                        placeholder="Selecionar"
                      />
                    </DisbursementDatesSelector>
                  )}
                  <Table
                    tableHeadCell={tableHeadContent}
                    maxBodyHeight="45vh"
                    tableHeadSticky
                    tableBodyContent={previewSimulationData.schedule.map(
                      (simulationData, inx) => {
                        const iof = baseIofPerInstallment + simulationData.iof;
                        return {
                          id: String(inx),
                          cells: [
                            {
                              id: '1',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM
                                    style={{ margin: '0 auto' }}
                                    weight="Bold"
                                  >
                                    {inx + 1}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '2',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.payment)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '3',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.principal)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '4',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.interest)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '5',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>{formatCurrency(iof)}</TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '6',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatDateFromBackend(
                                      simulationData.payment_date,
                                    ).toLocaleDateString('pt-BR')}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                          ],
                        };
                      },
                    )}
                    tableFooterCell={[
                      {
                        id: '1',
                        content: null,
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    previewSimulationData.schedule
                                      .map((schedule) => schedule.payment)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    previewSimulationData.schedule
                                      .map((schedule) => schedule.principal)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    previewSimulationData.schedule
                                      .map((schedule) => schedule.interest)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    previewSimulationData.schedule
                                      .map(
                                        (schedule) =>
                                          baseIofPerInstallment + schedule.iof,
                                      )
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: null,
                      },
                    ]}
                  />
                  <ButtonPrimary
                    style={{
                      backgroundColor: 'transparent',
                      width: 'fit-content',
                      margin: '15px auto 0px',
                    }}
                    typeVariant="outline"
                    btnIcon={
                      <CopyIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                    }
                    iconPosition="left"
                    onClick={handleCopySchedule}
                  >
                    <TextM
                      weight="Semibold"
                      style={{
                        marginLeft: '11px',
                        color: theme.palette.brand.primary.base,
                      }}
                    >
                      Copiar resultado
                    </TextM>
                  </ButtonPrimary>
                </>
              ) : (
                <TextM>
                  Nenhuma parcela disponível. Conclua primeiro a simulação no
                  bloco de condições.
                </TextM>
              )}
            </NumPaymentContent>
          </Accordion>
        </InputArea>
        <PreviewArea mode={mode}>
          {mode === 'COMPONENT' ? (
            <Heading4
              style={{
                color: theme.palette.brand.secondary.base,
                minWidth: '172px',
              }}
            >
              Resultado:
            </Heading4>
          ) : (
            <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
              Resultado
            </Heading3>
          )}
          <PreviewItems mode={mode}>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Quant. parcelas
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.numPeriods &&
                  previewSimulationData?.numPeriods > 0
                    ? previewSimulationData?.numPeriods
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Valor da parcela
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.paymentAmount
                    ? formatCurrency(previewSimulationData?.paymentAmount)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Valor liberado
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.requested_amount
                    ? formatCurrency(previewSimulationData?.requested_amount)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Valor financiado
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.financed_amount
                    ? formatCurrency(previewSimulationData?.financed_amount)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Juros
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.interestRate
                    ? `${normalizeAddDigits(
                        previewSimulationData?.interestRate * 100,
                        2,
                      )?.replace('.', ',')}% a.m.`
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  CET
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {typeof previewSimulationData?.monthlyEffectiveInterestRate ===
                  'number'
                    ? `${normalizeAddDigits(
                        previewSimulationData?.monthlyEffectiveInterestRate *
                          100,
                        2,
                      ).replace('.', ',')}% a.m.`
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  TC
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.tacAmount
                    ? formatCurrency(previewSimulationData?.tacAmount)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  IOF
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.iofAmount
                    ? formatCurrency(previewSimulationData?.iofAmount)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Outros custos
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.finance_fee
                    ? formatCurrency(previewSimulationData?.finance_fee)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Primeiro vencimento
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.firstPaymentDate
                    ? formatDateFromBackend(
                        previewSimulationData?.firstPaymentDate,
                      ).toLocaleDateString('pt-br')
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Último vencimento
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.lastPaymentDate
                    ? formatDateFromBackend(
                        previewSimulationData?.lastPaymentDate,
                      ).toLocaleDateString('pt-br')
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
            <>
              <PreviewItem>
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.secondary.base }}
                >
                  Saldo Total
                </TextM>
                <TextM style={{ color: theme.palette.brand.secondary.base }}>
                  {previewSimulationData?.totalAmountOwed
                    ? formatCurrency(previewSimulationData?.totalAmountOwed)
                    : '-'}
                </TextM>
              </PreviewItem>
              <PreviewDivider theme={theme} />
            </>
          </PreviewItems>
          {typeof onRenderPreviewButtonsArea === 'function' && (
            <PreviewButtonsArea mode={mode}>
              {onRenderPreviewButtonsArea(previewSimulationData)}
            </PreviewButtonsArea>
          )}
        </PreviewArea>
      </CreditSimulatorContentBox>
    </CreditSimulatorContentWrapper>
  );
};

export default CreditSimulatorContent;
