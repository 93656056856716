import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { ButtonPrimary, Heading4, Spinner } from 'app/components';
import { MoneyIcon } from 'app/components/Icons/svgs';
import { TProducts } from 'modules/products/context';
import {
  TBorrowerQualificationResponseParsed,
  TConditionItems,
} from 'modules/products/pages/interfaces';
import {
  BtnArea,
  ConditionsModalContent,
  IconArea,
  InputArea,
  LoadingArea,
} from './style';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { useQualificationsService } from 'modules/products/services/hooks';
import { useFormik } from 'formik';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { TReactChangeInput } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { formatCurrency, normalizeAddDigits } from 'app/utils/normalizer';
import { formatDecimalField } from 'app/helpers';
import { BaseModal, CustomCurrencyInput, CustomInput } from 'app/components';
import { getNumbersFromValue } from 'app/utils/numbers';
import { editConditionValidationRequired } from 'modules/customer/helpers/conditionStepValidation';

export interface IConditionsModalProps {
  productInfo?: TProducts;
  qualification?: TBorrowerQualificationResponseParsed | undefined;
  isOpen: boolean;
  handleModalClose: () => void;
  handleApprove: ({
    creditLimit,
    maxPaymentAmount,
    interestRate,
    qualification,
  }: TConditionItems) => void;
  updateQualificationConditionLoading: boolean;
}

const ConditionsModal = ({
  isOpen,
  productInfo,
  qualification,
  updateQualificationConditionLoading,
  handleModalClose,
  handleApprove,
}: IConditionsModalProps) => {
  const [borrowerQualificationData, setBorrowerQualificationData] = useState<
    TBorrowerQualificationRequestType[] | undefined
  >();
  const theme = useTheme();
  const { getBorrowerQualification } = useQualificationsService();
  const [isLoading, setIsLoading] = useState(false);

  const fetchBorrowerQualificationDetails = useCallback(async () => {
    setIsLoading(true);

    try {
      console.log('QUALIFIC', qualification);
      const borrowerQualificationRes = await handleGetBorrowerQualification(
        productInfo?.id || '',
        qualification?.borrower.id || '',
        qualification?.id || '',
      );

      if (!borrowerQualificationRes) {
        handleModalClose();
      }
      setBorrowerQualificationData(borrowerQualificationRes?.content);
    } finally {
      setIsLoading(false);
    }
  }, [productInfo, qualification]);

  const handleGetBorrowerQualification = useCallback(
    async (productId: string, borrowerId: string, qualificationId: string) => {
      try {
        const borrowerQualificationRes = await getBorrowerQualification(
          productId,
          borrowerId,
          qualificationId,
        );
        return borrowerQualificationRes;
      } catch (error) {
        handleModalClose();
      }
    },
    [],
  );

  const formik = useFormik({
    initialValues: {
      creditLimit: formatCurrency(
        borrowerQualificationData?.[0].condition?.line_of_credit
          ?.credit_limit || 0.0,
        false,
      ),
      maxPaymentAmount: formatCurrency(
        borrowerQualificationData?.[0].condition?.line_of_credit
          ?.max_payment_amount || 0.0,
        false,
      ),
      minRequestedAmount:
        formatCurrency(
          borrowerQualificationData?.[0]?.condition?.min_requested_amount || 0,
          false,
        ) || '',
      interestRate: borrowerQualificationData?.[0]?.condition
        ?.schedule_based_conditions?.[0]?.interest_rate
        ? normalizeAddDigits(
            borrowerQualificationData?.[0]?.condition
              ?.schedule_based_conditions?.[0]?.interest_rate * 100,
            2,
          )
        : '',
      dueDay: borrowerQualificationData?.[0]?.condition?.due_day || '',
      maxGracePeriod:
        borrowerQualificationData?.[0]?.condition?.max_grace_period || '',
      minGracePeriod:
        borrowerQualificationData?.[0]?.condition?.min_grace_period || '',
      maxPaymentNumber:
        borrowerQualificationData?.[0]?.condition?.max_payment_number || '',
      minPaymentNumber:
        borrowerQualificationData?.[0]?.condition?.min_payment_number || '',
      tac:
        formatCurrency(
          borrowerQualificationData?.[0]?.condition?.tac || 0,
          false,
        ) || '',
    },
    validationSchema: editConditionValidationRequired,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleApprove({
        ...values,
        qualification: borrowerQualificationData?.[0],
      });
    },
  });

  const handleAmountChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      evt.target.name,
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleMaxPaymentAmountChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'maxPaymentAmount',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleInterestRateChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  const handleIntChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      evt.target.name,
      getNumbersFromValue(evt.target.value || ''),
    );
  }, []);

  useEffect(() => {
    if (isOpen && productInfo) {
      fetchBorrowerQualificationDetails();
    }
  }, [isOpen, productInfo]);

  return (
    <BaseModal open={isOpen} onClose={handleModalClose}>
      <ConditionsModalContent>
        <IconArea>
          <img src={MoneyIcon} alt="moneyico" />
        </IconArea>
        <Heading4
          fontWeight="B"
          style={{
            color: theme.palette.brand.secondary.base,
            textAlign: 'center',
          }}
        >
          Complete os campos abaixo necessários para concluir a aprovação.
        </Heading4>

        {isLoading ? (
          <LoadingArea>
            <Spinner />
          </LoadingArea>
        ) : (
          <>
            <InputArea>
              <CustomCurrencyInput
                value={String(formik.values.creditLimit) || '0,00'}
                labelValue="Limite total"
                handleInputChange={handleAmountChange}
                name="creditLimit"
                validationError={
                  formik.touched.creditLimit ? formik.errors.creditLimit : ''
                }
              />
              <CustomCurrencyInput
                value={String(formik.values.maxPaymentAmount)}
                labelValue="Limite da parcela"
                handleInputChange={handleMaxPaymentAmountChange}
                name="maxPaymentAmount"
                validationError={
                  formik.touched.maxPaymentAmount
                    ? formik.errors.maxPaymentAmount
                    : ''
                }
              />
              <CustomCurrencyInput
                name="minRequestedAmount"
                value={formik.values.minRequestedAmount}
                handleInputChange={handleAmountChange}
                labelValue="Mínimo solicitação"
                validationError={
                  formik.touched.minRequestedAmount
                    ? formik.errors.minRequestedAmount
                    : ''
                }
              />
              {/* <CustomCurrencyInput
                name="tac"
                value={formik.values.tac}
                handleInputChange={handleAmountChange}
                labelValue="Tac"
                validationError={formik.touched.tac ? formik.errors.tac : ''}
              /> */}
              <CustomInput
                name="maxGracePeriod"
                value={String(formik.values.maxGracePeriod)}
                handleInputChange={handleIntChange}
                labelValue="Carência máxima"
                validationError={
                  formik.touched.maxGracePeriod
                    ? formik.errors.maxGracePeriod
                    : ''
                }
              />
              <CustomInput
                name="minGracePeriod"
                value={String(formik.values.minGracePeriod)}
                handleInputChange={handleIntChange}
                labelValue="Carência mínima"
                validationError={
                  formik.touched.minGracePeriod
                    ? formik.errors.minGracePeriod
                    : ''
                }
              />
              <CustomInput
                name="minPaymentNumber"
                value={String(formik.values.minPaymentNumber)}
                handleInputChange={handleIntChange}
                labelValue="Prazo mínimo"
                validationError={
                  formik.touched.minPaymentNumber
                    ? formik.errors.minPaymentNumber
                    : ''
                }
              />
              <CustomInput
                name="maxPaymentNumber"
                value={String(formik.values.maxPaymentNumber)}
                handleInputChange={handleIntChange}
                labelValue="Prazo máximo"
                validationError={
                  formik.touched.maxPaymentNumber
                    ? formik.errors.maxPaymentNumber
                    : ''
                }
              />
              {/*<CustomInput*/}
              {/*  name="dueDay"*/}
              {/*  value={String(formik.values.dueDay)}*/}
              {/*  handleInputChange={handleIntChange}*/}
              {/*  labelValue="Dia de vencimento"*/}
              {/*  validationError={*/}
              {/*    formik.touched.dueDay ? formik.errors.dueDay : ''*/}
              {/*  }*/}
              {/*/>*/}
              <CustomInput
                labelValue="Taxa de juros (% a.m.)"
                value={formik.values.interestRate || ''}
                handleInputChange={handleInterestRateChange}
                validationError={
                  formik.touched.interestRate ? formik.errors.interestRate : ''
                }
              />
            </InputArea>

            <BtnArea>
              {updateQualificationConditionLoading ? (
                <div className="spinner">
                  <Spinner />
                </div>
              ) : (
                <ButtonPrimary typeVariant="fill" onClick={formik.submitForm}>
                  Aprovar
                </ButtonPrimary>
              )}
            </BtnArea>
          </>
        )}
      </ConditionsModalContent>
    </BaseModal>
  );
};

export default ConditionsModal;
