import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  ButtonContainer,
  ButtonFilterContent,
  Content,
  MovementStatementIdCell,
  MovementStatementMovementCell,
  MovementsStatementButtonsArea,
  ResultCopyArea,
  TableArea,
  TableBodyCellContainer,
  TableHeadCellContainer,
} from './styles';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import {
  BankStatementFormat,
  BankStatementResponseData,
  TBalanceType,
  TFilterType,
  TMovement,
  TMovementType,
} from 'modules/disbursement/interfaces/disbursement';
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  formatStringHourFromBackend,
  subtractDays,
} from 'app/helpers/dateHelpers';
import { Spinner, Table, TextL, TextM, TextS } from 'app/components';
import { ButtonPrimary, TTableBodyContent } from 'app/components';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { FilterMovementsDropdown } from './components/FilterMovementsDropdown';
import { CalendarIcon } from 'app/components/Icons';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { EmptyContent } from 'modules/products/pages/ProductDetail/components/EmptyContent';
import { formatCurrency } from 'app/utils/normalizer';
import {
  ArrowCircleDown,
  ArrowCircleUp,
  FilterAlt,
  FilterAltOutlined,
  FileDownload,
  Balance,
} from '@mui/icons-material';

import { Filters, TFilterItem } from './components/Filters';
import { DownloadReportModal } from './components/DownloadReportModal';
import {
  EFilterItemType,
  TFilterResult,
  TFilterValues,
} from './components/Filters/types';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import BankStatementDetailsSideSheet from './BankStatementDetailsSideSheet';
import { getReportDescription } from 'modules/disbursement/utils/bankStatement';

const BalanceTypeConfig = {
  [TBalanceType.CREDIT]: {
    label: 'Entrada',
    icon: <ArrowCircleDown />,
    bgColor: '#B8F1C1',
    color: '#246B30',
  },
  [TBalanceType.DEBIT]: {
    label: 'Saída',
    icon: <ArrowCircleUp />,
    bgColor: '#F5F5F5',
    color: '#6D6980',
  },
  [TBalanceType.BALANCE]: {
    label: 'Saldo do dia',
    icon: <Balance />,
    bgColor: '#F5F5F5',
    color: '#246B30',
  },
};

export const MovementTypeLabel = {
  [TMovementType.ENTRYCREDIT]: 'Recebimento TEF',
  [TMovementType.PIXPAYMENTIN]: 'Recebimento Pix',
  [TMovementType.ENTRYDEBIT]: 'Pagamento TEF',
  [TMovementType.PIXPAYMENTOUT]: 'Pagamento Pix',
  [TMovementType.TEDTRANSFERIN]: ' Recebimento TED',
  [TMovementType.TEDTRANSFEROUT]: 'Pagamento TED',
  [TMovementType.PIXREVERSALIN]: 'Estorno Pix',
  [TMovementType.PIXREVERSALOUT]: 'Estorno Pix',
  [TMovementType.BALANCE]: 'Saldo do dia',
};

const MovementsFilters: Array<TFilterItem> = [
  {
    label: 'Movimento',
    placeholder: 'Movimento',
    name: 'balanceType',
    type: EFilterItemType.MULTI_SELECT,
    options: [
      {
        label: BalanceTypeConfig[TBalanceType.CREDIT].label,
        value: TBalanceType.CREDIT,
      },
      {
        label: BalanceTypeConfig[TBalanceType.DEBIT].label,
        value: TBalanceType.DEBIT,
      },
    ],
  },
  {
    label: 'ID Originador',
    placeholder: 'Digite o ID do originador',
    name: 'disbursementOriginatorId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Solicitação',
    placeholder: 'Digite o ID da solicitação',
    name: 'disbursementApplicationId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Produto',
    placeholder: 'Digite o ID do produto',
    name: 'disbursementProductId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Funding',
    placeholder: 'Digite o ID do funding',
    name: 'disbursementFundingId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Nome Tomador',
    placeholder: 'Digite o nome do tomador',
    name: 'disbursementBorrowerName',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'CPF/CNPJ Tomador',
    placeholder: 'Digite o CPF/CNPJ do tomador',
    name: 'disbursementBorrowerTaxpayerId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Tomador',
    placeholder: 'Digite o ID do tomador',
    name: 'disbursementBorrowerId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Nome Recebedor',
    placeholder: 'Digite o nome do recebedor',
    name: 'creditPartyName',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'CPF/CNPJ Recebedor',
    placeholder: 'Digite o CPF/CNPJ do recebedor',
    name: 'creditPartyTaxId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Pix Recebedor',
    placeholder: 'Digite a chave PIX do recebedor',
    name: 'creditPartyKey',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Razão',
    placeholder: 'Razão',
    isFull: true,
    name: 'movementType',
    type: EFilterItemType.MULTI_SELECT,
    options: Object.keys(MovementTypeLabel).map((movementType) => ({
      label: MovementTypeLabel[movementType as TMovementType],
      value: movementType,
    })),
  },
];

const FilterTypeLabel = {
  [TFilterType.TODAY]: 'Hoje',
  [TFilterType.YESTERDAY]: 'Ontem',
  [TFilterType.LAST_7_DAYS]: 'Últimos 7 dias',
};

export const getAccountType = (accountType: string): string => {
  switch (accountType) {
    case 'CACC':
      return 'Conta Corrente';
    case 'TRAN':
      return 'Conta de Pagamentos';
    case 'SLRY':
      return 'Conta Salário';
    case 'SVGS':
      return 'Conta Poupança';
  }
  return accountType;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Lançamento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Detalhes</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor</TextL>
      </TableHeadCellContainer>
    ),
  },
];

export interface MovementsStatementContentHandler {
  reloadMovement: () => void;
}

interface MovementsStatementContentProps {
  funding?: IFunding;
}

const MovementsStatementContent = forwardRef<
  MovementsStatementContentHandler,
  MovementsStatementContentProps
>(({ funding }: MovementsStatementContentProps, ref) => {
  const [filterType, updateFilterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, TFilterType.TODAY),
  );
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [dateFrom, setDateFrom] = useQueryParam(
    'dateFrom',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [dateTo, setDateTo] = useQueryParam(
    'dateTo',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 50),
  );
  const [isFiltersOpen, toggleFiltersOpen] = useState(false);
  const [isRequestReportModalOpen, toggleRequestReportModalOpen] =
    useState(false);

  const [filterValuesQuery, updateFilterValuesQuery] =
    useQueryParam<TFilterValues>(
      'filter',
      withDefault(JsonParam, {} as TFilterValues),
    );
  const [filterValues, updateFilterValues] =
    useState<TFilterValues>(filterValuesQuery);
  const [movementsData, setMovementsData] = useState<
    BankStatementResponseData[] | undefined
  >();
  const [selectedMovementForDetail, setSelectedMovementForDetail] = useState<
    TMovement | undefined
  >();
  const {
    getBankStatement,
    requestConciliationReport,
    requestConciliationReportLoading,
    getBankStatementLoading,
  } = useDisbursementService();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const filterBtnRef = useRef<HTMLDivElement>(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();

  const fetchMovements = async () => {
    if (funding) {
      const result = await getBankStatement({
        id: funding.id,
        page,
        dateFrom,
        dateTo,
        linesPerPage,
        filters: filterValues,
      });

      const filtered = result?.data?.filter(
        (movement) => movement.movements.length > 0,
      );

      setMovementsData(filtered);
      updateFilterValuesQuery(filterValues);
      setTotalItemsQty((result?.totalPages ?? 0) * linesPerPage);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      reloadMovement: fetchMovements,
    }),
    [funding, page, dateFrom, dateTo, linesPerPage, filterValues],
  );

  useEffect(() => {
    fetchMovements();
  }, [page, dateFrom, dateTo, filterValues, filterType, linesPerPage, funding]);

  const onApplyFilter = (filterResult: TFilterResult) => {
    const filterValuesObject = Object.keys(filterResult).reduce<TFilterValues>(
      (filterValuesObj, filterKey) => {
        if (
          !filterResult[filterKey]?.value ||
          (Array.isArray(filterResult[filterKey]?.value) &&
            (filterResult[filterKey]?.value as string[]).length === 0)
        )
          return filterValuesObj;
        return {
          ...filterValuesObj,
          [filterKey]: filterResult[filterKey]?.value,
        };
      },
      {} as TFilterValues,
    );
    updateFilterValues(() => filterValuesObject);
    toggleFiltersOpen(false);
  };

  const handleDownloadReport = async (
    email: string,
    format: BankStatementFormat,
  ) => {
    if (funding) {
      const result = await requestConciliationReport({
        fundingId: funding.id || '',
        filters: filterValues,
        columns: null,
        dateFrom,
        dateTo,
        email,
        format: format,
        type: 'formattedStatement',
      });
      toggleRequestReportModalOpen(false);
      if (result) {
        showSnackbar('Extrato solicitado com sucesso! Verifique seu e-mail.');
      }
    }
  };

  const renderMovements = () => {
    const rows: TTableBodyContent[] = [];
    movementsData?.map((item) => {
      //Fernando pediu para não mostrar o saldo
      // const row = {
      //   id: '1',
      //   cells: [
      //     {
      //       id: '1',
      //       content: (
      //         <TableBodyCellContainer>
      //           <MovementStatementMovementCell>
      //             <TextM weight="Semibold">
      //               {`Saldo no dia ${formatDateFromBackend(
      //                 item.date,
      //               ).toLocaleDateString('pt-BR')}: ${formatCurrency(
      //                 Number(item.balance),
      //               )?.toString()}`}
      //             </TextM>
      //           </MovementStatementMovementCell>
      //         </TableBodyCellContainer>
      //       ),
      //     },
      //     {
      //       id: '2',
      //       content: <TableBodyCellContainer></TableBodyCellContainer>,
      //     },
      //     {
      //       id: '3',
      //       content: <TableBodyCellContainer></TableBodyCellContainer>,
      //     },
      //   ],
      // };

      // rows.push(row);

      item.movements.forEach((movement) => {
        const row2 = {
          id: movement.id,
          cells: [
            {
              id: '1',
              content: (
                <TableBodyCellContainer>
                  <MovementStatementMovementCell
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    <div>
                      <div>
                        <TextS>
                          {`${formatDateFromBackend(
                            movement?.createDate || '',
                          ).toLocaleDateString('pt-BR')} às
                                ${formatStringHourFromBackend(
                                  movement?.createDate || '',
                                )}`}
                        </TextS>
                      </div>
                      <div>
                        <MovementStatementIdCell>
                          {MovementTypeLabel[movement.movementType]}
                        </MovementStatementIdCell>
                      </div>
                    </div>
                  </MovementStatementMovementCell>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '2',
              content: (
                <TableBodyCellContainer>
                  {getReportDescription(movement)}
                </TableBodyCellContainer>
              ),
            },
            {
              id: '3',
              content: (
                <TableBodyCellContainer>
                  <TextS
                    weight={
                      movement.balanceType === TBalanceType.CREDIT
                        ? 'Bold'
                        : 'Regular'
                    }
                    style={{
                      color:
                        movement.balanceType === TBalanceType.CREDIT
                          ? '#246B30'
                          : movement.balanceType === TBalanceType.BALANCE
                          ? (theme.palette.brand.secondary.base as string)
                          : (theme.palette.brand.error[500] as string),
                    }}
                  >
                    {movement?.amount
                      ? `${
                          movement.balanceType === TBalanceType.CREDIT
                            ? '+'
                            : movement.balanceType === TBalanceType.BALANCE
                            ? ' '
                            : '-'
                        } ${formatCurrency(parseFloat(movement?.amount))}`
                      : '-'}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
          ],
        };

        rows.push(row2);
      });
    });
    return rows;
  };

  return (
    <Content>
      <TextL style={{ fontSize: '24px' }}>Lançamentos</TextL>
      <ButtonContainer>
        <div ref={filterBtnRef}>
          <ButtonPrimary
            style={{ backgroundColor: 'transparent' }}
            typeVariant="outline"
            onClick={() => setFilterDropdownOpen((state) => !state)}
          >
            <ButtonFilterContent>
              <div className="filter-qty-container">
                <CalendarIcon
                  color={theme.palette.brand.primary.base as string}
                />
                <TextM weight="Medium">Data:</TextM>
                <TextM weight="Medium" className="filter-qty">
                  {filterType !== TFilterType.CUSTOM
                    ? FilterTypeLabel[
                        filterType as keyof typeof FilterTypeLabel
                      ]
                    : `${formatDateFromBackend(dateFrom).toLocaleDateString(
                        'pt-br',
                      )}-${formatDateFromBackend(dateTo).toLocaleDateString(
                        'pt-br',
                      )}`}
                </TextM>
              </div>
              <KeyboardArrowDownOutlinedIcon />
            </ButtonFilterContent>
          </ButtonPrimary>
        </div>

        <FilterMovementsDropdown
          open={filterDropdownOpen}
          refElement={filterBtnRef}
          filterType={filterType as TFilterType}
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleClose={() => setFilterDropdownOpen(false)}
          handleFilter={(from: string, to: string, type: TFilterType) => {
            if (type === TFilterType.TODAY) {
              setDateFrom(formatDateForBackendString(new Date()));
              setDateTo(formatDateForBackendString(new Date()));
            }
            if (type === TFilterType.YESTERDAY) {
              setDateFrom(
                formatDateForBackendString(subtractDays(new Date(), 1)),
              );
              setDateTo(
                formatDateForBackendString(subtractDays(new Date(), 1)),
              );
            }
            if (type === TFilterType.LAST_7_DAYS) {
              setDateFrom(
                formatDateForBackendString(subtractDays(new Date(), 6)),
              );
              setDateTo(formatDateForBackendString(new Date()));
            }
            if (type === TFilterType.CUSTOM) {
              setDateFrom(from);
              setDateTo(to);
            }
            updateFilterType(type);
            setFilterDropdownOpen(false);
          }}
        />

        <MovementsStatementButtonsArea>
          <ButtonPrimary
            onClick={() => toggleFiltersOpen(true)}
            style={{ marginTop: '8px' }}
            typeVariant="ghost"
          >
            {Object.keys(filterValues).length > 0 ? (
              <FilterAlt />
            ) : (
              <FilterAltOutlined />
            )}
          </ButtonPrimary>
          {!!movementsData?.length && (
            <ResultCopyArea>
              <ButtonPrimary
                onClick={() => toggleRequestReportModalOpen(true)}
                typeVariant="ghost"
              >
                <FileDownload />
              </ButtonPrimary>
            </ResultCopyArea>
          )}
        </MovementsStatementButtonsArea>
      </ButtonContainer>
      {getBankStatementLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <Spinner />
        </div>
      ) : !getBankStatementLoading &&
        (!movementsData || movementsData?.length <= 0) ? (
        <EmptyContent titleText="Nenhum lançamento registrado" />
      ) : (
        <TableArea>
          <Table
            showPagination
            handlePageChange={(selectedPage) => {
              setPage(selectedPage);
              fetchMovements();
            }}
            handleLinesPerPage={(selectedLinesPerPage) => {
              setPage(1);
              setLinesPerPage(
                selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
              );
            }}
            totalElementsQty={totalItemsQty}
            currentPage={page}
            linesPerPage={linesPerPage}
            tableHeadCell={tableHeader}
            tableBodyContent={
              movementsData && movementsData.length ? renderMovements() : []
            }
          />
        </TableArea>
      )}
      <Filters
        filters={MovementsFilters}
        isOpen={isFiltersOpen}
        filterValues={filterValues}
        handleFilter={onApplyFilter}
        handleClose={() => toggleFiltersOpen(false)}
      />
      <DownloadReportModal
        isOpen={isRequestReportModalOpen}
        handleClose={() => toggleRequestReportModalOpen(false)}
        handleSubmit={handleDownloadReport}
        loading={requestConciliationReportLoading}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
      <BankStatementDetailsSideSheet
        movementData={selectedMovementForDetail}
        isOpen={!!selectedMovementForDetail}
        handleClose={() => setSelectedMovementForDetail(undefined)}
      />
    </Content>
  );
});

MovementsStatementContent.displayName = 'MovementsStatementContent';

export default MovementsStatementContent;
