import { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';
import {
  CellBodyContent,
  CellHeadContent,
  CustomTableWrapper,
  LoadingContainer,
  PaginationWrapper,
} from './styles';
import { ButtonPrimary } from '../ButtonPrimary';
import { Spinner } from '../Spinner';
import { TextM, TextS } from '../typography';
import { CustomInput } from '../CustomInput';

export type TBodyContentCell = {
  id: string;
  width?: string;
  content: React.ReactNode;
};

export type TTableBodyContent = {
  id?: string;
  cells: TBodyContentCell[];
  disabled?: boolean;
  handleLineClick?: () => void;
};

export type TTableHeadCell = {
  id: string;
  width?: string;
  content: React.ReactNode;
};

export interface ICustomTable {
  tableBodyContent?: TTableBodyContent[] | null;
  tableHeadCell?: TTableHeadCell[] | null;
  tableFooterCell?: TBodyContentCell[];
  showPagination?: boolean;
  totalElementsQty?: number;
  linesPerPage?: number;
  currentPage?: number;
  isLoading?: boolean;
  maxBodyHeight?: string;
  handlePageChange?: (page: number) => void;
  handleLinesPerPage?: (quantity: number) => void;
  overflowVisible?: boolean;
  tableHeadSticky?: boolean;
}

const CustomTable = ({
  tableHeadCell = [],
  tableBodyContent = [],
  tableFooterCell = [],
  totalElementsQty = 1,
  showPagination,
  linesPerPage = 1,
  currentPage,
  isLoading,
  overflowVisible,
  maxBodyHeight,
  tableHeadSticky,
  handleLinesPerPage,
  handlePageChange,
}: ICustomTable) => {
  const [linesPerPageValue, setLinesPerPageValue] = useState(linesPerPage);

  const handlePaginationItems = () => {
    const res =
      totalElementsQty &&
      Math.round(totalElementsQty / (linesPerPage || 1)) <= 0
        ? 1
        : Math.ceil(totalElementsQty / (linesPerPage || 1)) <= 0
        ? 1
        : Math.ceil(totalElementsQty / (linesPerPage || 1));

    return res;
  };

  return (
    <CustomTableWrapper
      showPagination={showPagination}
      tableHeadSticky={tableHeadSticky}
    >
      <TableContainer
        component={Paper}
        style={{
          overflowX: overflowVisible ? 'visible' : 'auto',
          maxHeight: maxBodyHeight ?? 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadCell?.map((item, idx) => (
                <TableCell key={idx}>
                  <CellHeadContent>{item.content}</CellHeadContent>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyContent?.map((item, index) => (
              <TableRow
                style={{
                  opacity: item.disabled ? 0.3 : 1,
                  cursor:
                    typeof item.handleLineClick === 'function'
                      ? 'pointer'
                      : 'text',
                }}
                onClick={item.handleLineClick}
                key={index}
              >
                {item.cells.map((cell, idx) => (
                  <TableCell key={idx}>
                    <CellBodyContent>{cell.content}</CellBodyContent>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {tableFooterCell?.map((item, idx) => (
              <TableCell key={idx}>
                <CellHeadContent>{item.content}</CellHeadContent>
              </TableCell>
            ))}
          </TableFooter>
        </Table>
      </TableContainer>
      {showPagination && (
        <PaginationWrapper>
          <div className="pagination-lines">
            <TextS>Linhas por página:</TextS>
            <CustomInput
              className="pagination-input"
              value={String(linesPerPageValue) || ''}
              handleInputChange={(v) =>
                setLinesPerPageValue(
                  Number(
                    v.target.value?.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
                  ),
                )
              }
              onKeyDown={(evt) => {
                if (evt.key === 'Enter') {
                  evt.currentTarget.blur();
                }
              }}
              onBlur={() => handleLinesPerPage?.(linesPerPageValue)}
            />
          </div>
          {isLoading && (
            <div>
              <LoadingContainer>
                <Spinner />
                <TextM>Carregando mais itens</TextM>
              </LoadingContainer>
            </div>
          )}
          <div className="pagination-btns">
            {currentPage && currentPage > 1 && (
              <ButtonPrimary
                typeVariant="ghost"
                className="pagination-btns-prev"
                onClick={() => handlePageChange?.(currentPage - 1)}
              >
                <TextM>Anterior</TextM>
              </ButtonPrimary>
            )}
            <Pagination
              count={handlePaginationItems()}
              hideNextButton
              hidePrevButton
              onChange={(_, page) => handlePageChange?.(page)}
              page={currentPage}
            />
            {currentPage && currentPage < handlePaginationItems() && (
              <ButtonPrimary
                typeVariant="ghost"
                className="pagination-btns-next"
                onClick={() => handlePageChange?.(currentPage + 1)}
              >
                <TextM>Próxima</TextM>
              </ButtonPrimary>
            )}
          </div>
        </PaginationWrapper>
      )}
    </CustomTableWrapper>
  );
};

export default CustomTable;
