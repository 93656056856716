import React from 'react';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import {
  CustomCurrencyInputContainer,
  InputLabelContainer,
  LineItem,
  TextFieldArea,
} from './styles';
import { InputError, TextM } from '../typography';

export interface ICustomCurrencyInputProps extends OutlinedInputProps {
  labelValue?: string;
  value: string;
  validationError?: string;
  withSelect?: boolean;
  handleInputChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const CustomCurrencyInput = ({
  labelValue,
  handleInputChange,
  value,
  validationError,
  withSelect,
  ...props
}: ICustomCurrencyInputProps) => {
  return (
    <CustomCurrencyInputContainer withSelect={withSelect}>
      {labelValue && <InputLabelContainer>{labelValue}</InputLabelContainer>}
      <TextFieldArea>
        <LineItem />
        <TextM style={{ margin: '0px', zIndex: '99' }}>R$</TextM>
        <OutlinedInput
          value={value}
          type={'text'}
          onChange={(e) => handleInputChange(e)}
          {...props}
        />
      </TextFieldArea>
      {validationError && <InputError>{validationError}</InputError>}
    </CustomCurrencyInputContainer>
  );
};

export default CustomCurrencyInput;
