import { useCallback, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, useTheme } from '@mui/material';
import { ButtonPrimary } from '../../ButtonPrimary';
import {
  ChevronDownIcon,
  CloseIcon,
  CopyIcon,
  HandCoinIcon,
} from '../../Icons';
import { Heading4, TextL, TextM } from '../../typography';
import {
  Arrow,
  CloseButton,
  CreditSimulatorContainer,
  CreditSimulatorWrapper,
  ProductArea,
  ProductListContent,
  ProductListItem,
} from './styles';
import { formatCurrency, normalizeAddDigits } from '../../../utils/normalizer';
import { formatDateFromBackend } from '../../../helpers/dateHelpers';
import CreditSimulatorContent, {
  TPreviewSimulationData,
} from './CreditSimulatorContent';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useProductsService } from 'modules/products/services/hooks';
import { useProductsContext } from 'modules/products';
import { Spinner } from '../../Spinner';
import { TProducts } from 'modules/products/context';
import { Popover } from 'app/components/Popover';

export interface ICreditSimulator {
  isActive: boolean;
  handleClose: () => void;
}

const CreditSimulator = ({ isActive, handleClose }: ICreditSimulator) => {
  const [isProductVisible, setIsProductVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<TProducts>();
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarCopy } = useSnackbar();
  const { isProfileAuthorized } = usePermify();
  const productNameRef = useRef(null);
  const { getProducts, getProductsLoading } = useProductsService();
  const {
    state: { content: productList },
  } = useProductsContext();

  const handleGetProducts = async () => {
    if (isActive) {
      await getProducts();
    }
  };

  const handleCopyPreview = useCallback(
    (previewSimulationData: TPreviewSimulationData) => {
      navigator.clipboard.writeText(
        `Quant. parcelas: ${
          previewSimulationData?.numPeriods
            ? previewSimulationData?.numPeriods
            : '-'
        }\nValor da parcela: ${
          previewSimulationData?.paymentAmount
            ? formatCurrency(previewSimulationData?.paymentAmount)
            : '-'
        }\nValor liberado: ${
          previewSimulationData?.requested_amount
            ? formatCurrency(previewSimulationData?.requested_amount || 0.0)
            : '-'
        }\nValor financiado: ${
          previewSimulationData?.financed_amount
            ? formatCurrency(previewSimulationData?.financed_amount || 0.0)
            : '-'
        }\nJuros: ${
          previewSimulationData?.interestRate
            ? `${normalizeAddDigits(
                previewSimulationData?.interestRate * 100,
                2,
              ).replace('.', ',')}% a.m.`
            : '-'
        }\nCET: ${
          typeof previewSimulationData?.monthlyEffectiveInterestRate ===
          'number'
            ? `${normalizeAddDigits(
                previewSimulationData?.monthlyEffectiveInterestRate * 100,
                2,
              ).replace('.', ',')}% a.m.`
            : '-'
        }\nTC: ${
          previewSimulationData?.tacAmount
            ? formatCurrency(previewSimulationData?.tacAmount)
            : '-'
        }\nIOF: ${
          previewSimulationData?.iofAmount
            ? formatCurrency(previewSimulationData?.iofAmount)
            : '-'
        }\nOutros custos: ${
          previewSimulationData?.finance_fee
            ? formatCurrency(previewSimulationData?.finance_fee)
            : '-'
        }\nPrimeiro vencimento: ${
          previewSimulationData?.firstPaymentDate
            ? formatDateFromBackend(
                previewSimulationData?.firstPaymentDate,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nÚltimo vencimento: ${
          previewSimulationData?.lastPaymentDate
            ? formatDateFromBackend(
                previewSimulationData?.lastPaymentDate,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nSaldo Total: ${
          previewSimulationData?.totalAmountOwed
            ? formatCurrency(previewSimulationData?.totalAmountOwed)
            : '-'
        }`,
      );
      showSnackbarCopy();
    },
    [],
  );

  const handleApplication = useCallback(() => {
    handleClose();
    navigate('/products/application');
  }, []);

  const handleSelectProduct = (productData: TProducts) => {
    localStorage.setItem('selectedProductId', productData.id);
    setSelectedProduct(productData);
    setIsProductVisible(false);
  };

  useEffect(() => {
    handleGetProducts();
  }, [isActive]);

  useEffect(() => {
    if (isActive && productList && productList?.length > 0) {
      const selectedProductId = localStorage.getItem('selectedProductId');
      const productFound = productList.find(
        (product) => product.id === selectedProductId,
      );
      if (productFound) {
        setSelectedProduct(productFound);
      } else {
        setSelectedProduct(productList?.[0]);
        localStorage.setItem('selectedProductId', productList?.[0]?.id);
      }
    }
  }, [isActive, productList, selectedProduct]);

  if (isActive) {
    return (
      <CreditSimulatorWrapper>
        <CreditSimulatorContainer>
          <ProductArea>
            <div className="product-name-area">
              <Heading4 fontWeight="B">Produto: </Heading4>
              {getProductsLoading ? (
                <Spinner />
              ) : (
                <div
                  ref={productNameRef}
                  onClick={() => setIsProductVisible(true)}
                  className="product-name"
                >
                  <Heading4
                    fontWeight="B"
                    style={{
                      color: theme.palette.brand.primary.base,
                      cursor: 'pointer',
                    }}
                  >
                    {selectedProduct?.name || '-'}
                  </Heading4>
                  <ChevronDownIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                </div>
              )}
            </div>
            <Popover
              open={isProductVisible}
              anchorEl={productNameRef.current}
              onClose={() => setIsProductVisible(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPopover-paper	': {
                  marginTop: '8px',
                  marginLeft: '0px',
                  minWidth: '160px',
                  boxShadow: '0px 18px 25px rgba(0, 21, 33, 0.14)',
                  backgroundColor: '#FDFDFD',
                  border: '1px solid #EDEDED',
                  borderRadius: '20px',
                },
              }}
            >
              <ProductListContent>
                {productList && productList.length > 0 ? (
                  productList.map((product) => {
                    return (
                      <div key={product.id}>
                        <ProductListItem
                          isSelected={product.id === selectedProduct?.id}
                          onClick={() => handleSelectProduct(product)}
                        >
                          <TextL>{product.name}</TextL>
                        </ProductListItem>
                        <Divider />
                      </div>
                    );
                  })
                ) : (
                  <ProductListItem>
                    <TextL>Nenhum produto cadastrado</TextL>
                  </ProductListItem>
                )}
              </ProductListContent>
            </Popover>
            <CloseButton onClick={handleClose}>
              <CloseIcon color={theme.palette.brand.secondary.base as string} />
            </CloseButton>
            <Divider sx={{ marginTop: '22px' }} />
          </ProductArea>
          <CreditSimulatorContent
            mode="MODAL"
            product={selectedProduct}
            onRenderPreviewButtonsArea={(previewSimulationData) => {
              return (
                <>
                  <ButtonPrimary
                    style={{ backgroundColor: 'transparent' }}
                    typeVariant="outline"
                    btnIcon={
                      <CopyIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                    }
                    iconPosition="left"
                    onClick={() => handleCopyPreview(previewSimulationData)}
                  >
                    <TextM
                      weight="Semibold"
                      style={{
                        marginLeft: '11px',
                        color: theme.palette.brand.primary.base,
                      }}
                    >
                      Copiar resultado
                    </TextM>
                  </ButtonPrimary>
                  {isProfileAuthorized(
                    EAccountPermissions.CREATE_APPLICATION,
                  ) && (
                    <ButtonPrimary
                      style={{ backgroundColor: 'transparent' }}
                      typeVariant="outline"
                      btnIcon={
                        <HandCoinIcon
                          color={theme.palette.brand.primary.base as string}
                        />
                      }
                      iconPosition="left"
                      onClick={handleApplication}
                    >
                      <TextM
                        weight="Semibold"
                        style={{
                          marginLeft: '11px',
                          color: theme.palette.brand.primary.base,
                        }}
                      >
                        Criar solicitação
                      </TextM>
                    </ButtonPrimary>
                  )}
                </>
              );
            }}
          />
        </CreditSimulatorContainer>
        <Arrow />
      </CreditSimulatorWrapper>
    );
  }

  return null;
};

export default CreditSimulator;
