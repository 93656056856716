import { useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  InstallmentsCartContent,
  InstallmentsCartWrapper,
  InstallmentsDescription,
} from './styles';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';
import { InstallmentsCartIcon } from 'app/components/Icons';
import { Heading4, TextL, TextM } from 'app/components';
import { formatCurrency } from 'app/utils/normalizer';
import { ButtonDefault } from 'app/components';
import { ArrowForward } from '@mui/icons-material';
import { ChargesSimulator } from '../ChargesSimulator';

const InstallmentsCart = () => {
  const theme = useTheme();
  const [isChargesSimulatorModalOpen, toggleChargesSimulatorModalOpen] =
    useState(false);
  const { installments, onCleanCart } = useInstallmentsCart();

  const debtorName = useMemo(() => {
    if (installments.length > 0) return installments[0].debtor[0].name;
    return '-';
  }, [installments]);

  return (
    <>
      <InstallmentsCartWrapper
        className={installments.length > 0 ? 'active' : ''}
      >
        <InstallmentsCartContent>
          <InstallmentsCartIcon color={theme.palette.common.white as string} />
          <InstallmentsDescription>
            <Heading4
              style={{
                fontWeight: 'bold',
                color: theme.palette.common.white as string,
              }}
            >
              {debtorName}
            </Heading4>
            <TextL
              style={{
                display: 'inline-block',
                color: theme.palette.common.white as string,
              }}
            >{`${formatCurrency(
              installments.reduce(
                (total, installment) => total + installment.due_amount,
                0,
              ),
            )}`}</TextL>
            <TextM
              style={{
                paddingLeft: '5px',
                display: 'inline-block',
                color: theme.palette.common.white as string,
              }}
            >
              {`(${installments.length} ${
                installments.length === 1
                  ? ` parcela selecionada`
                  : `parcelas selecionadas`
              })`}
            </TextM>
          </InstallmentsDescription>
          <ButtonDefault
            style={{
              maxWidth: '100px',
              backgroundColor: theme.palette.brand.primary.base,
              border: 'none',
              textAlign: 'center',
            }}
            margin={true}
            onClick={onCleanCart}
          >
            <TextL
              style={{
                padding: '0px',
                color: theme.palette.common.white as string,
              }}
            >
              Limpar
            </TextL>
          </ButtonDefault>
          <ButtonDefault
            style={{
              maxWidth: '230px',
            }}
            onClick={() => toggleChargesSimulatorModalOpen(true)}
          >
            <TextL
              weight="bold"
              style={{ color: theme.palette.brand.primary.base }}
            >
              Gerar Cobrança
            </TextL>
            <ArrowForward
              style={{
                color: theme.palette.brand.primary.base,
                marginLeft: '10px',
              }}
            />
          </ButtonDefault>
        </InstallmentsCartContent>
      </InstallmentsCartWrapper>
      <ChargesSimulator
        isActive={isChargesSimulatorModalOpen}
        handleClose={() => toggleChargesSimulatorModalOpen(false)}
      />
    </>
  );
};

export default InstallmentsCart;
