import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { useTheme } from '@mui/material';
import {
  CessionListTableButtonArea,
  CessionListTableFilterArea,
  CessionListTableArea,
  CessionListTableWrapper,
  ButtonFilterContent,
  SearchInputContainer,
  CessionListActionButton,
  CessionListActionButtons,
} from './styles';
import {
  CalendarIcon,
  DownloadIcon,
  EmptyAccessImage,
} from 'app/components/Icons';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { EmptyArea } from 'modules/management/components';
import { TBodyContentCell, TTableHeadCell, Table } from 'app/components/Table';
import { saveAs } from 'file-saver';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  subtractDays,
} from 'app/helpers/dateHelpers';
import { formatCurrency } from 'app/utils/normalizer';
import { TFilterType } from '../../types';
import { CessionsFilter } from '../CessionsFilter';
import { ButtonPrimary, TextL, TextM, TextS } from 'app/components';
import { SearchFilterInput } from 'app/components/SearchFilter';
import SearchWhiteIcon from 'app/components/Icons/SearchWhiteIcon';
import EyeIcon from 'app/components/Icons/EyeIcon';
import { useNavigate } from 'react-router-dom';
import { useCessionService } from 'modules/cession/services/useCessionService';
import { TCession } from 'modules/cession/interfaces/cession';
import { differenceInDays, format } from 'date-fns';

export const initalFilters = {
  status: ['ISSUED'],
};

const FilterTypeLabel = {
  [TFilterType.TODAY]: 'Hoje',
  [TFilterType.YESTERDAY]: 'Ontem',
  [TFilterType.LAST_7_DAYS]: 'Últimos 7 dias',
  [TFilterType.ALL]: 'Desde o início',
};

interface ICessionListTableProps {
  fundingId: string;
}
const CessionListTable = ({ fundingId }: ICessionListTableProps) => {
  const theme = useTheme();
  const filterBtnRef = useRef<HTMLDivElement>(null);
  const [cessionList, setCessionList] = useState<TCession[]>([]);
  const [searchText, updateSearchText] = useState<string>('');
  const [filterType, updateFilterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, TFilterType.ALL),
  );
  const [dateFrom, setDateFrom] = useQueryParam(
    'dateFrom',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [dateTo, setDateTo] = useQueryParam(
    'dateTo',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [isFiltersOpen, toggleFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const {
    getCessionFile,
    getCessionRequestsList,
    getCessionFileLoading,
    getCessionRequestsListLoading,
  } = useCessionService();

  const handleGetCessionList = async () => {
    getCessionRequestsList(fundingId).then((res) => {
      if (res) {
        setCessionList(res);
        setTotalItemsQty(res.length);
      }
    });
  };

  useEffect(() => {
    handleGetCessionList();
  }, [
    dateFrom,
    dateTo,
    filterType,
    linesPerPage,
    linesPerPage,
    currentPage,
    fundingId,
  ]);

  useEffect(() => {
    if (currentPage === 0) {
      handleGetCessionList();
    } else {
      setCurrentPage(0);
    }
  }, []);

  const downloadCessionFile = useCallback(
    (cession: TCession) => {
      getCessionFile(fundingId, cession.cession.id).then((res) => {
        if (res) {
          const ccbName = `Cessao_${format(
            new Date(cession.cession.created_at),
            'dd_mm_yyyy',
          )}.pdf`;
          saveAs(
            new Blob([res], {
              type: 'application/pdf',
            }),
            ccbName,
          );
        }
      });
    },
    [fundingId],
  );

  const tableHeadContent: TTableHeadCell[] = useMemo(() => {
    return [
      {
        id: '1',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Código de cessão</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '2',
        width: '10%',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Data</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '3',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Valor solicitado</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '4',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Valor de aquisição</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '5',
        content: (
          <TableHeadCellContainer style={{ width: '100%' }}>
            <TextL weight="bold" style={{ textAlign: 'center' }}>
              Ação
            </TextL>
          </TableHeadCellContainer>
        ),
      },
    ] as TTableHeadCell[];
  }, [cessionList]);

  return (
    <>
      <CessionListTableWrapper>
        <CessionListTableFilterArea>
          <CessionListTableButtonArea>
            <SearchInputContainer>
              <SearchWhiteIcon />
              <SearchFilterInput
                handleClearInput={() => updateSearchText('')}
                handleInputChange={(evt) =>
                  updateSearchText(evt.currentTarget.value)
                }
                placeholder="Pesquisar por cessionário ou código de cessão"
                searchItemName="colunas"
                value={searchText}
              />
            </SearchInputContainer>
            <div ref={filterBtnRef}>
              <ButtonPrimary
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: '0px 4px 10px 0px rgba(15, 33, 43, 0.05)',
                }}
                typeVariant="outline"
                onClick={() => toggleFiltersOpen((state) => !state)}
              >
                <ButtonFilterContent>
                  <div className="filter-qty-container">
                    <CalendarIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM weight="Medium">Data:</TextM>
                    <TextM weight="Medium" className="filter-qty">
                      {filterType !== TFilterType.CUSTOM
                        ? FilterTypeLabel[
                            filterType as keyof typeof FilterTypeLabel
                          ]
                        : `${formatDateFromBackend(dateFrom).toLocaleDateString(
                            'pt-br',
                          )}-${formatDateFromBackend(dateTo).toLocaleDateString(
                            'pt-br',
                          )}`}
                    </TextM>
                  </div>
                  <KeyboardArrowDownOutlinedIcon />
                </ButtonFilterContent>
              </ButtonPrimary>
            </div>

            <CessionsFilter
              open={isFiltersOpen}
              refElement={filterBtnRef}
              filterType={filterType as TFilterType}
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleClose={() => toggleFiltersOpen(false)}
              handleFilter={(from: string, to: string, type: TFilterType) => {
                if (type === TFilterType.TODAY) {
                  setDateFrom(formatDateForBackendString(new Date()));
                  setDateTo(formatDateForBackendString(new Date()));
                }
                if (type === TFilterType.YESTERDAY) {
                  setDateFrom(
                    formatDateForBackendString(subtractDays(new Date(), 1)),
                  );
                  setDateTo(
                    formatDateForBackendString(subtractDays(new Date(), 1)),
                  );
                }
                if (type === TFilterType.LAST_7_DAYS) {
                  setDateFrom(
                    formatDateForBackendString(subtractDays(new Date(), 6)),
                  );
                  setDateTo(formatDateForBackendString(new Date()));
                }
                if (type === TFilterType.CUSTOM) {
                  setDateFrom(from);
                  setDateTo(to);
                }
                updateFilterType(type);
                toggleFiltersOpen(false);
              }}
            />
          </CessionListTableButtonArea>
        </CessionListTableFilterArea>
        {cessionList.length === 0 || getCessionRequestsListLoading ? (
          <EmptyArea
            isLoading={!!getCessionRequestsListLoading}
            message="Nenhuma cessão gerada"
          >
            <EmptyAccessImage />
          </EmptyArea>
        ) : (
          <CessionListTableArea>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
                handleGetCessionList();
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeadContent}
              tableBodyContent={cessionList
                .filter((cession) => {
                  if (filterType === TFilterType.ALL) {
                    return true;
                  }
                  const createdAt = new Date(cession.cession.created_at);
                  const diffInDays = differenceInDays(new Date(), createdAt);
                  if (filterType === TFilterType.TODAY) {
                    return diffInDays === 0;
                  }
                  if (filterType === TFilterType.YESTERDAY) {
                    return diffInDays === 1;
                  }
                  if (filterType === TFilterType.LAST_7_DAYS) {
                    return diffInDays <= 6;
                  }
                  if (filterType === TFilterType.CUSTOM) {
                    return (
                      createdAt >= new Date(dateFrom) &&
                      createdAt <= new Date(dateTo)
                    );
                  }
                  return true;
                })
                .filter((cession) => {
                  if (!searchText || searchText === '') {
                    return true;
                  }
                  return (
                    cession.cession.id
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    cession.cession.i_funding
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  );
                })
                .map((cession) => {
                  return {
                    id: '1',
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{cession.cession.id || '-'}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {cession.cession.created_at
                                ? `${formatDateFromBackend(
                                    cession.cession.created_at,
                                  ).toLocaleDateString('pt-BR')}`
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {cession?.requestedAmount
                                ? formatCurrency(cession?.requestedAmount)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {formatCurrency(cession.acquisitionAmount)}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <CessionListActionButtons>
                              <CessionListActionButton
                                isDisabled={false}
                                onClick={() =>
                                  navigate(
                                    `/cession/${fundingId}/cessions/${cession.cession.id}`,
                                  )
                                }
                              >
                                <EyeIcon
                                  color={
                                    theme.palette.brand.primary.base as string
                                  }
                                />
                              </CessionListActionButton>
                              <CessionListActionButton
                                isDisabled={getCessionFileLoading}
                                onClick={() => downloadCessionFile(cession)}
                              >
                                <DownloadIcon
                                  color={
                                    theme.palette.brand.primary.base as string
                                  }
                                />
                              </CessionListActionButton>
                            </CessionListActionButtons>
                          </TableBodyCellContainer>
                        ),
                      },
                    ] as TBodyContentCell[],
                  };
                })}
            />
          </CessionListTableArea>
        )}
      </CessionListTableWrapper>
    </>
  );
};

export default CessionListTable;
