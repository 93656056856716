import { isValidCPF } from 'app/helpers';
import { TCustomValue } from 'app/components';
import * as yup from 'yup';

export const aboutSchemaPlain = {
  taxpayer_id: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/,
      'Formato de CPF incorreto',
    )
    .test('test-invalid-cpf', 'CPF inválido', (cpf) => isValidCPF(cpf || '')),
  full_name: yup.string().required('Campo obrigatório'),
  nationality: yup.string().required('Campo obrigatório'),
  occupation: yup.string().required('Campo obrigatório'),
  phone: yup
    .string()
    .test(
      'test-phone-number',
      'Formato de telefone incorreto',
      function (value) {
        const { path, createError } = this;

        if (
          value &&
          value.length < 15 &&
          value?.replace(/\D+/g, '')?.match(/(\d{2})(\d)/)?.[2] === '9'
        ) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        if (value && value.length < 14) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        return true;
      },
    )
    .required('Campo obrigatório'),
  // eslint-disable-next-line no-useless-escape
  email_address: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo obrigatório'),
  birth_date: yup.string().nullable().required('Campo obrigatório'),
  document_number: yup
    .string()
    .when(['document_type', 'document_issuer', 'document_issue_date'], {
      is: (
        document_type: TCustomValue,
        document_issuer: string,
        document_issue_date: string,
      ) =>
        (document_type?.value && document_type?.value.length > 0) ||
        (document_issuer && document_issuer.length > 0) ||
        (document_issue_date && document_issue_date.length > 0),
      then: yup.string().required('Campo obrigatório'),
    }),
  document_type: yup
    .object()
    .nullable()
    .when(['document_number', 'document_issuer', 'document_issue_date'], {
      is: (
        document_number: string,
        document_issuer: string,
        document_issue_date: string,
      ) =>
        (document_number && document_number.length > 0) ||
        (document_issuer && document_issuer.length > 0) ||
        (document_issue_date && document_issue_date.length > 0),
      then: yup.object().nullable().required('Campo obrigatório'),
    }),
  document_issuer: yup
    .string()
    .when(['document_number', 'document_type', 'document_issue_date'], {
      is: (
        document_number: string,
        document_type: TCustomValue,
        document_issue_date: string,
      ) =>
        (document_number && document_number.length > 0) ||
        (document_type?.value && document_type?.value.length > 0) ||
        (document_issue_date && document_issue_date.length > 0),
      then: yup.string().required('Campo obrigatório'),
    }),
  document_issue_date: yup
    .string()
    .nullable()
    .when(['document_number', 'document_type', 'document_issuer'], {
      is: (
        document_number: string,
        document_type: TCustomValue,
        document_issuer: string,
      ) =>
        (document_number && document_number.length > 0) ||
        (document_type?.value && document_type?.value.length > 0) ||
        (document_issuer && document_issuer.length > 0),
      then: yup.string().required('Campo obrigatório').nullable(),
    })
    .test('test-date-format', 'Formato de data inválido', (value) => {
      if (!value) return true;

      const date = new Date(value);
      if (date.toString() === 'Invalid Date') return false;

      return true;
    }),
};

export const aboutStepSchema = yup.object().shape(aboutSchemaPlain, [
  ['document_number', 'document_type'],
  ['document_number', 'document_issuer'],
  ['document_number', 'document_issue_date'],
  ['document_type', 'document_issuer'],
  ['document_type', 'document_issue_date'],
  ['document_issuer', 'document_issue_date'],
]);

export const documentStepSchema = aboutStepSchema.pick([
  'document_number',
  'document_type',
  'document_issuer',
  'document_issue_date',
]);

export const spouseStepSchema = yup.object().shape({
  spouse_taxpayer_id: yup
    .string()
    .test('test-invalid-cpf', 'CPF inválido', (cpf) => {
      if (!cpf) return true;
      if (cpf && cpf.length === 0) true;
      if (!isValidCPF(cpf || '')) {
        return false;
      }

      return true;
    })
    .required('Campo obrigatório'),
  spouse_date_of_birth: yup
    .string()
    .nullable()
    .test('test-date-format', 'Formato de data inválido', (value) => {
      if (!value) return true;

      const date = new Date(value);
      if (date.toString() === 'Invalid Date') return false;

      return true;
    }),
});

export const addressStepSchema = yup.object({
  country_code: yup
    .object({
      value: yup.string().required('Campo obrigatório'),
      label: yup.string(),
    })
    .required('Campo obrigatório'),
  postal_code: yup
    .string()
    .min(8, 'Insira um valor válido')
    .required('Campo obrigatório'),
  noNumber: yup.bool(),
  district: yup.string().required('Campo obrigatório'),
  street_name: yup.string().required('Campo obrigatório'),
  street_number: yup.string().when('noNumber', {
    is: (noNumber: boolean) => noNumber === false,
    then: yup.string().required('Campo obrigatório'),
  }),
  extra_info: yup.string(),
  state_code: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  city: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  references: yup.string(),
});

export const bankStepSchema = yup.object({
  ispb_code: yup.object().nullable(),
  bank_account: yup.string().when('ispb_code', {
    is: (ispb_code?: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup.string().required('Campo obrigatório'),
  }),

  bank_branch: yup.string().when('ispb_code', {
    is: (ispb_code: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup
      .string()
      .min(4, 'Formato incorreto')
      .required('Campo obrigatório'),
  }),
  bank_account_type: yup
    .object()
    .nullable()
    .when('ispb_code', {
      is: (ispb_code?: TCustomValue) =>
        ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
      then: yup.object().nullable().required('Campo obrigatório'),
    }),
});
