import { ChargesSimulatorSwitch } from './styles';
import { CustomSwitchIOS, TextM } from 'app/components';
import { ReactNode } from 'react';

export interface IChargesSimulatorItem {
  children?: string | ReactNode;
  title: string;
  isDisabled: boolean;
  isChecked: boolean;
  style?: React.CSSProperties;
  onChange: () => void;
}

const ChargesSimulatorItem = ({
  children,
  title,
  isDisabled,
  isChecked,
  style,
  onChange,
}: IChargesSimulatorItem) => {
  return (
    <>
      <ChargesSimulatorSwitch style={style}>
        <div className="wrapper">
          <div>
            <TextM>{title}</TextM>
            {typeof children === 'string' && (
              <TextM
                style={{
                  marginTop: '2px',
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {children}
              </TextM>
            )}
          </div>
          <div>
            <CustomSwitchIOS checked={isChecked} onClick={onChange} />
          </div>
        </div>
        {typeof children === 'object' && children}
      </ChargesSimulatorSwitch>
    </>
  );
};

export default ChargesSimulatorItem;
