import { Divider } from '@mui/material';
import {
  ChargesSimulatorSection,
  ChargesSimulatorSectionContainer,
  ChargesSimulatorSectionContent,
  ChargesSimulatorSwitcher,
  ChargesSimulatorSectionContentColumn,
} from './styles';
import { ButtonPrimary } from 'app/components';
import { CalendarInput, Heading4, TextL, TextM } from 'app/components';
import { ChargesSimulatorItem } from '../ChargesSimulatorItem';
import { CustomCurrencyInput } from 'app/components/CustomCurrencyInput';
import { ChargesSimulatorClockIcon } from 'app/components/Icons';
import { CustomSelect } from 'app/components/CustomSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChargesSimulatorConfigs {}

const ChargesSimulatorConfigs = () => {
  return (
    <ChargesSimulatorSection>
      <Heading4
        style={{
          fontSize: '32px',
        }}
      >
        Configurar
      </Heading4>
      <ChargesSimulatorSectionContainer>
        <ChargesSimulatorSectionContent>
          <TextL weight="semibold">Acréscimos e descontos</TextL>
          <ChargesSimulatorSwitcher columns={2}>
            <div className="column">
              <ChargesSimulatorItem
                title="Juros"
                isChecked={true}
                isDisabled={true}
                onChange={() => console.log('Test')}
              >
                R$ 2.000,00
              </ChargesSimulatorItem>
              <ChargesSimulatorItem
                title="Multa"
                isChecked={true}
                isDisabled={true}
                onChange={() => console.log('Test')}
              >
                R$ 2.000,00
              </ChargesSimulatorItem>
              <ChargesSimulatorItem
                title="Correção"
                isChecked={true}
                isDisabled={true}
                onChange={() => console.log('Test')}
                style={{
                  borderBottom: 'none',
                }}
              >
                R$ 2.000,00
              </ChargesSimulatorItem>
            </div>
            <div className="column">
              <ChargesSimulatorItem
                title="Desc. antecipação"
                isChecked={true}
                isDisabled={true}
                onChange={() => console.log('Test')}
              >
                R$ 2.000,00
              </ChargesSimulatorItem>
              <ChargesSimulatorItem
                title="Desc. negociação"
                isChecked={true}
                isDisabled={true}
                onChange={() => console.log('Test')}
                style={{
                  borderBottom: 'none',
                }}
              >
                <div style={{ display: 'flex', width: '220px' }}>
                  <CustomCurrencyInput
                    value="100"
                    style={{
                      backgroundColor: 'white',
                      width: '140px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                    }}
                    withSelect={true}
                    inputProps={{
                      style: {
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      },
                    }}
                    handleInputChange={() => console.log('Test')}
                    name="amount"
                  />
                  <CustomSelect
                    name="test"
                    isClearable={false}
                    withInput={true}
                    styles={{
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderLeft: 'none',
                      width: '80px',
                    }}
                    handleInputChange={() => console.log('test')}
                    options={[
                      {
                        value: 'amount',
                        label: 'R$',
                      },
                      {
                        value: 'percent',
                        label: '%',
                      },
                    ]}
                  />
                </div>
              </ChargesSimulatorItem>
            </div>
          </ChargesSimulatorSwitcher>
        </ChargesSimulatorSectionContent>
        <ChargesSimulatorSectionContentColumn
          style={{
            maxWidth: '320px',
          }}
        >
          <ChargesSimulatorSectionContent style={{ marginBottom: '12px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <TextL weight="semibold" style={{ marginRight: 'auto' }}>
                Vencimento
              </TextL>
              <ChargesSimulatorClockIcon />
              <TextL
                weight="regular"
                style={{ color: '#6F6B82', marginLeft: '8px' }}
              >
                (D+120)
              </TextL>
            </div>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <CalendarInput
                name="foundation_date"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={new Date().toISOString()}
                onChange={(e) => console.log(e)}
              />
            </div>
          </ChargesSimulatorSectionContent>
          <ChargesSimulatorSectionContent>
            <TextL weight="semibold">Meios de pagamento</TextL>
            <ChargesSimulatorSwitcher
              columns={2}
              gap={24}
              style={{
                marginBottom: '0px',
                paddingBottom: '0px',
              }}
            >
              <div className="column">
                <ChargesSimulatorItem
                  title="Pix"
                  isChecked={true}
                  isDisabled={true}
                  onChange={() => console.log('Test')}
                  style={{ borderBottom: 'none', paddingBottom: '0px' }}
                />
                <ChargesSimulatorItem
                  title="Terceiros"
                  isChecked={true}
                  isDisabled={true}
                  onChange={() => console.log('Test')}
                  style={{
                    borderBottom: 'none',
                    paddingBottom: '0px',
                    marginBottom: '0px',
                  }}
                />
              </div>
              <div className="column">
                <ChargesSimulatorItem
                  title="Boleto"
                  isChecked={true}
                  isDisabled={true}
                  onChange={() => console.log('Test')}
                  style={{ borderBottom: 'none' }}
                />
              </div>
            </ChargesSimulatorSwitcher>
          </ChargesSimulatorSectionContent>
        </ChargesSimulatorSectionContentColumn>
        <ChargesSimulatorSectionContentColumn
          style={{
            maxWidth: '320px',
          }}
        >
          <ChargesSimulatorSectionContent
            style={{
              minHeight: '266px',
            }}
          >
            <TextL weight="semibold">Resumo</TextL>
            <div
              style={{
                marginTop: '24px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '12px',
                  justifyContent: 'space-between',
                }}
              >
                <TextM style={{ color: '#6F6B82' }}>Valor original</TextM>
                <TextM>R$ 2.000,00</TextM>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '12px',
                  justifyContent: 'space-between',
                }}
              >
                <TextM style={{ color: '#6F6B82' }}>Acréscimos</TextM>
                <TextM>R$ 500,00</TextM>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '12px',
                  justifyContent: 'space-between',
                }}
              >
                <TextM style={{ color: '#6F6B82' }}>Descontos</TextM>
                <TextM>- R$ 200,00</TextM>
              </div>
              <Divider />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginTop: '12px',
                  justifyContent: 'space-between',
                }}
              >
                <TextM weight="Bold">Total</TextM>
                <TextM weight="Bold">R$ 2.300,00</TextM>
              </div>
            </div>
          </ChargesSimulatorSectionContent>
          <ButtonPrimary
            style={{
              minHeight: '52px',
              fontSize: '20px',
              marginTop: 'auto',
            }}
            onClick={() => console.log('Test')}
          >
            Criar cobrança
          </ButtonPrimary>
        </ChargesSimulatorSectionContentColumn>
      </ChargesSimulatorSectionContainer>
    </ChargesSimulatorSection>
  );
};

export default ChargesSimulatorConfigs;
