import { useTheme } from '@mui/material';
import { ButtonDefault } from 'app/components';
import {
  ChargesSimulatorDebtorContent,
  ChargesSimulatorSection,
  ChargesSimulatorSectionContainer,
} from './styles';
import { Heading4, TextL } from 'app/components';
import { ChargesSimulatorPersonIcon } from 'app/components/Icons';
import { useEffect, useState } from 'react';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { normalizeCpfCnpj } from 'app/utils/normalizer';
import { formatPhone } from 'app/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChargesSimulatorDebtor {}

const ChargesSimulatorDebtor = () => {
  const theme = useTheme();
  const { installments } = useInstallmentsCart();
  const { getPersonList } = usePersonService();
  const { getBusinessList } = useCustomerService();
  const { showSnackbar } = useSnackbar();
  const [debtor, updateDebtor] = useState<
    TRegisterBusinessDataRequest | TRegisterPersonDataRequest
  >();

  useEffect(() => {
    if (installments && installments.length > 0) {
      const { debtor: debtorInstallment } = installments[0];

      const taxpayerId = debtorInstallment[0].taxpayer_id;
      if (taxpayerId.length === 11) {
        getPersonList(0, 'taxpayerId', taxpayerId, 1).then((person) => {
          if (
            person &&
            Array.isArray(person.content) &&
            person.content.length > 0
          ) {
            updateDebtor(person.content[0]);
            return;
          }
          showSnackbar(
            'Ocorreu um problema ao buscar os dados do tomador',
            'error',
          );
        });
      } else {
        getBusinessList(0, 'taxpayerId', taxpayerId, 1).then((business) => {
          if (
            business &&
            Array.isArray(business.content) &&
            business.content.length > 0
          ) {
            updateDebtor(business.content[0]);
            return;
          }
          showSnackbar(
            'Ocorreu um problema ao buscar os dados do tomador',
            'error',
          );
        });
      }
    }
  }, [installments]);

  const isBusiness = debtor && debtor.taxpayer_id.length > 11;

  return (
    <ChargesSimulatorSection>
      <Heading4
        style={{
          fontSize: '32px',
        }}
      >
        Pagador
      </Heading4>
      {debtor && (
        <ChargesSimulatorSectionContainer>
          <ButtonDefault
            style={{
              border: 'none',
              display: 'flex',
              width: 'fit-content',
            }}
            onClick={() => console.log('test')}
          >
            <ChargesSimulatorPersonIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{
                color: theme.palette.brand.primary.base as string,
                marginLeft: '8px',
              }}
            >
              {isBusiness
                ? (debtor as TRegisterBusinessDataRequest).legal_name
                : (debtor as TRegisterPersonDataRequest).full_name}
            </TextL>
          </ButtonDefault>
          <ChargesSimulatorDebtorContent style={{ marginLeft: 'auto' }}>
            <span>{isBusiness ? 'CNPJ' : 'CPF'}</span>
            <span>{normalizeCpfCnpj(debtor!.taxpayer_id)}</span>
          </ChargesSimulatorDebtorContent>
          <ChargesSimulatorDebtorContent style={{ borderLeft: 'none' }}>
            <span className="middle">E-mail</span>
            <span>{debtor.email_address}</span>
          </ChargesSimulatorDebtorContent>
          <ChargesSimulatorDebtorContent
            style={{
              borderLeft: 'none',
              borderRight: 'none',
            }}
          >
            <span className="middle">Celular</span>
            <span>
              {debtor.phone?.area_code && debtor.phone?.number
                ? formatPhone(
                    `${debtor.phone?.area_code}${debtor.phone?.number}`,
                  )
                : '-'}
            </span>
          </ChargesSimulatorDebtorContent>
        </ChargesSimulatorSectionContainer>
      )}
    </ChargesSimulatorSection>
  );
};

export default ChargesSimulatorDebtor;
