import { useState, useEffect, useCallback } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
  EViewBoxColumnType,
  ViewBox,
} from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../context';
import { Box, useTheme } from '@mui/material';
import { LegalPersonViewScrDetailsHeader, TableWrapper } from './styles';
import { Heading3, Table, TextL, TextM, TextS, TextXs } from 'app/components';
import { EmptyArea } from 'modules/management/components';
import { useCustomerService } from 'modules/customer/services';
import { formatCNPJ } from 'app/helpers';
import { TScrDetailItem, TScrResponse } from 'modules/customer/interfaces/scr';
import { ArrowBack, RefreshOutlined } from '@mui/icons-material';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { formatCurrency } from 'app/utils/normalizer';
import NaturalPersonViewScrDetailsSideSheet from './LegalPersonViewScrDetailsSideSheet';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { DownloadIcon } from 'app/components/Icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LegalPersonViewScrPDF from './LegalPersonViewScrPDF';
import {
  LegalPersonViewPageContentContainer,
  LegalPersonViewPageContentHeaderButtons,
  LegalPersonViewPageContentSection,
} from '../LegalPersonViewPageContent/styles';

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Modalidade</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Variação cambial</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Vencimentos</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const LegalPersonViewScrDetails = () => {
  const theme = useTheme();
  const [isConfirmRequestScrOpen, toggleConfirmRequestModalOpen] =
    useState(false);
  const [scr, updateScr] = useState<TScrResponse>();
  const [scrDetailItem, updateScrDetailItem] = useState<TScrDetailItem | null>(
    null,
  );
  const [business, setBusiness] = useState<TRegisterBusinessDataRequest | null>(
    null,
  );
  const {
    getBusiness,
    getBusinessScr,
    requestBusinessScr,
    getBusinessScrLoading,
    requestBusinessScrLoading,
  } = useCustomerService();
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();
  const params = useParams();

  useEffect(() => {
    if (isProfileAuthorized(EAccountPermissions.READ_SCR)) {
      if (typeof params.id === 'string') {
        getBusinessScr(params.id, params.scrId as string).then(
          (scrResponse) => {
            if (scrResponse) updateScr(scrResponse);
          },
        );
        getBusiness(params.id).then((b) => {
          setBusiness(b);
        });
      }
    } else {
      navigate('/');
    }
  }, [params]);

  const onRequestScr = useCallback(() => {
    if (typeof scr?.dados?.dataBaseConsultada === 'string') {
      requestBusinessScr(
        params.id as string,
        scr.dados.dataBaseConsultada,
      ).then((scrResponse) => {
        if (scrResponse) updateScr(scrResponse);
        toggleConfirmRequestModalOpen(false);
      });
    }
  }, [params, scr]);

  return (
    <LegalPersonViewPageContentContainer>
      <ButtonDefault
        style={{
          background: 'transparent',
          border: 'none',
          marginRight: 'auto',
          width: '100px',
          padding: '0px 0px 52px',
          color: theme.palette.brand.primary.base,
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBack
          style={{
            color: theme.palette.brand.primary.base as string,
            marginRight: '5px',
            fontSize: '22px',
          }}
        />
        <TextM
          weight="Semibold"
          style={{ color: theme.palette.brand.primary.base }}
        >
          Voltar
        </TextM>
      </ButtonDefault>
      <LegalPersonViewPageContentSection>
        <LegalPersonViewScrDetailsHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {scr?.dados?.dataBaseConsultada && (
              <Heading3 fontWeight="Sb">{`SCR ${scr?.dados?.dataBaseConsultada}`}</Heading3>
            )}
            <LegalPersonViewPageContentHeaderButtons>
              {scr && isProfileAuthorized(EAccountPermissions.CREATE_SCR) && (
                <ButtonDefault
                  style={{
                    maxWidth: '250px',
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => toggleConfirmRequestModalOpen(true)}
                >
                  <RefreshOutlined
                    style={{
                      color: theme.palette.brand.primary.base as string,
                      fontSize: '22px',
                    }}
                  />
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.primary.base }}
                  >
                    Atualizar consulta
                  </TextM>
                </ButtonDefault>
              )}
              {scr && scr.dados && business && (
                <PDFDownloadLink
                  style={{
                    textDecoration: 'none',
                  }}
                  fileName={`SCR_${business.taxpayer_id}.pdf`}
                  document={
                    <LegalPersonViewScrPDF data={scr} borrower={business} />
                  }
                >
                  {() => {
                    return (
                      <ButtonPrimary
                        typeVariant="outline"
                        style={{
                          gap: '8px',
                          maxWidth: '190px',
                          marginLeft: '10px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: theme.palette.brand.primary.base,
                        }}
                      >
                        <DownloadIcon
                          color={theme.palette.brand.primary.base as string}
                        />
                        <TextM
                          weight="Semibold"
                          style={{ color: theme.palette.brand.primary.base }}
                        >
                          Fazer download
                        </TextM>
                      </ButtonPrimary>
                    );
                  }}
                </PDFDownloadLink>
              )}
            </LegalPersonViewPageContentHeaderButtons>
          </div>
        </LegalPersonViewScrDetailsHeader>
        <Box>
          <BoxSection>
            {scr?.dados ? (
              <>
                <ViewBox
                  style={{ marginTop: '0px' }}
                  values={
                    {
                      ...scr.dados,
                    } as unknown as {
                      [key: string]: string | number;
                    }
                  }
                  columns={[
                    {
                      path: 'cnpjDaIFSolicitante',
                      label: 'CNPJ Solicitante',
                      type: EViewBoxColumnType.CNPJ,
                      sufix: '/****-**',
                    },
                    {
                      path: 'codigoDoCliente',
                      label: 'Código do Cliente',
                    },
                    {
                      path: 'tipoDoCliente',
                      label: 'Tipo do Cliente',
                    },
                    {
                      path: 'dataBaseConsultada',
                      label: 'Data base consultada',
                    },
                    {
                      path: 'dataInicioRelacionamento',
                      label: 'Data início relacionamento',
                      type: EViewBoxColumnType.DATE,
                    },
                    {
                      path: 'coobrigacaoAssumida',
                      label: 'Coobrigação assumida',
                      type: EViewBoxColumnType.CURRENCY,
                    },
                    {
                      path: 'coobrigacaoRecebida',
                      label: 'Coobrigação resumida',
                      type: EViewBoxColumnType.CURRENCY,
                    },
                    {
                      path: 'percentualDocumentosProcessados',
                      label: 'Percentual documentos processados',
                      sufix: '%',
                    },
                    {
                      path: 'percentualVolumeProcessado',
                      label: 'Percentual volume processado',
                      sufix: '%',
                    },
                    {
                      path: 'quantidadeDeInstituicoes',
                      label: 'Quantidade de instituições',
                    },
                    {
                      path: 'quantidadeDeOperacoes',
                      label: 'Quantidade de operações',
                    },
                    {
                      path: 'quantidadeOperacoesDiscordancia',
                      label: 'Quantidade de operações discordância',
                    },
                    {
                      path: 'quantidadeOperacoesSubJudice',
                      label: 'Quantidade de operações sub judice',
                    },
                    {
                      path: 'responsabilidadeTotalDiscordancia',
                      label: 'Responsabilidade total discordância',
                    },
                    {
                      path: 'responsabilidadeTotalSubJudice',
                      label: 'Responsabilidade total sub judice',
                    },
                    {
                      path: 'riscoIndiretoVendor',
                      label: 'Risco indireto vendor',
                      type: EViewBoxColumnType.CURRENCY,
                    },
                  ]}
                />
                {scr?.dados?.analise && !Array.isArray(scr.dados.analise) ? (
                  <TableWrapper>
                    <Table
                      tableHeadCell={tableHeader}
                      tableBodyContent={scr.dados.analise.detalhamento.map(
                        (itemDetail, index) => {
                          return {
                            id: `${itemDetail.modalidade.titulo}-${String(
                              index,
                            )}`,
                            cells: [
                              {
                                id: '1',
                                content: (
                                  <TableBodyCellContainer>
                                    <TextS weight="Bold">
                                      {itemDetail.modalidade.titulo ?? '-'}
                                    </TextS>
                                    <TextXs>
                                      {itemDetail.modalidade.subtitulo ?? '-'}
                                    </TextXs>
                                  </TableBodyCellContainer>
                                ),
                              },
                              {
                                id: '2',
                                content: (
                                  <TableBodyCellContainer>
                                    <TextS>
                                      {itemDetail.valor
                                        ? formatCurrency(itemDetail.valor)
                                        : '-'}
                                    </TextS>
                                  </TableBodyCellContainer>
                                ),
                              },
                              {
                                id: '3',
                                content: (
                                  <TableBodyCellContainer>
                                    <TextS>
                                      {itemDetail.variacaoCambial ?? '-'}
                                    </TextS>
                                  </TableBodyCellContainer>
                                ),
                              },
                              {
                                id: '4',
                                content: (
                                  <TableBodyCellContainer>
                                    <ButtonDefault
                                      onClick={() =>
                                        updateScrDetailItem(itemDetail)
                                      }
                                      style={{
                                        border: 'none',
                                        fontWeight: 'normal',
                                        background: 'transparent',
                                        textDecoration: 'underline',
                                        justifyContent: 'flex-start',
                                        padding: '0px',
                                      }}
                                    >
                                      Visualizar
                                    </ButtonDefault>
                                  </TableBodyCellContainer>
                                ),
                              },
                            ],
                          };
                        },
                      )}
                    />
                  </TableWrapper>
                ) : (
                  <div style={{ textAlign: 'center', padding: '48px 0' }}>
                    <TextL>
                      Cliente não possui dados suficientes no SCR para realizar
                      a análise.
                    </TextL>
                  </div>
                )}
              </>
            ) : (
              <EmptyArea
                message="Dados da consulta indisponível"
                createButtonText="Atualizar consulta"
                onCreateClick={
                  isProfileAuthorized(EAccountPermissions.CREATE_SCR)
                    ? () => toggleConfirmRequestModalOpen(true)
                    : undefined
                }
                isLoading={getBusinessScrLoading}
              >
                {null}
              </EmptyArea>
            )}
            <ConfirmModal
              isOpen={isConfirmRequestScrOpen}
              title="Essa consulta é cobrada ao Originador. Deseja prosseguir?"
              color="primary"
              btnConfirmText="Confirmar"
              isLoading={requestBusinessScrLoading}
              handleConfirm={onRequestScr}
              handleClose={() => toggleConfirmRequestModalOpen(false)}
              handleCancel={() => toggleConfirmRequestModalOpen(false)}
            >
              Consultas ao SCR precisam do consentimento Livre, Informado e
              Inambíguo da Empresa. Você confirma que obteve consentimento do
              titular do CNPJ:{' '}
              <strong>{formatCNPJ(business?.taxpayer_id ?? '')}</strong>?
            </ConfirmModal>
            <NaturalPersonViewScrDetailsSideSheet
              handleClose={() => updateScrDetailItem(null)}
              isOpen={scrDetailItem !== null}
              itemDetail={scrDetailItem}
            />
          </BoxSection>
        </Box>
      </LegalPersonViewPageContentSection>
    </LegalPersonViewPageContentContainer>
  );
};

export default LegalPersonViewScrDetails;
