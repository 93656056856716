/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import {
  CustomSelectContainer,
  CustomSelectStyle,
  InputLabelContainer,
  LineItem,
  TextFieldArea,
} from './styles';
import { InputError } from '../typography';
import Select from 'react-select';
import { Tooltip, useTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export type TCustomValue = {
  label?: string;
  value?: string;
};

export interface ICustomSelectProps {
  labelValue?: string;
  value?: TCustomValue | TCustomValue[];
  validationError?: string;
  handleInputChange: (v: any) => void;
  onBlur?: (v: any) => void;
  options: any;
  name: string;
  multi?: boolean;
  hideLineItem?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  withInput?: boolean;
  styles?: React.CSSProperties;
  tooltip?: string;
}

const CustomSelect = ({
  labelValue,
  tooltip,
  handleInputChange,
  value,
  validationError,
  options,
  onBlur,
  multi,
  placeholder,
  name,
  hideLineItem,
  disabled,
  isClearable = true,
  withInput,
  styles,
}: ICustomSelectProps) => {
  const theme = useTheme();
  const customSelectStyle = useMemo(
    () => CustomSelectStyle(theme, styles, withInput),
    [theme, styles, withInput],
  );
  return (
    <CustomSelectContainer withInput={withInput}>
      {labelValue && (
        <InputLabelContainer>
          {labelValue}{' '}
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineIcon
                style={{
                  fontSize: 16,
                  color: theme.palette.brand.primary.base,
                  marginLeft: 5,
                }}
              />
            </Tooltip>
          )}
        </InputLabelContainer>
      )}
      <TextFieldArea hasLabel={typeof labelValue === 'string'}>
        {!hideLineItem && <LineItem withInput={withInput} />}
        <Select
          inputId={name}
          value={Array.isArray(value) || value?.value ? value : undefined}
          onChange={(v: any) =>
            handleInputChange({
              currentTarget: { name, value: v },
              target: { name, value: v },
            })
          }
          placeholder={placeholder}
          isClearable={isClearable}
          styles={{ ...customSelectStyle, ...((styles as any) ?? {}) }}
          isMulti={multi}
          options={options}
          isDisabled={disabled}
          onBlur={() =>
            onBlur &&
            onBlur({
              currentTarget: { name },
              target: { name },
            })
          }
        />
      </TextFieldArea>
      {validationError && <InputError>{validationError}</InputError>}
    </CustomSelectContainer>
  );
};

export default CustomSelect;
