import React from 'react';
import { Card as CardMaterial, CardProps } from '@mui/material';

export type ICardProps = CardProps;

const Card = ({ children, ...props }: ICardProps) => {
  return (
    <CardMaterial
      sx={{
        padding: '0',
        display: 'flex',
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 2px 23px -8px rgba(31, 30, 35, 0.15)',
        borderRadius: '10px',
      }}
      {...props}
    >
      {children}
    </CardMaterial>
  );
};

export default Card;
