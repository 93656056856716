import {
  BaseModal,
  CalendarInput,
  CustomInput,
  Heading3,
  TCustomValue,
  TextS,
} from 'app/components';
import { useTheme } from '@mui/material';
import {
  EFilterItemType,
  TFilterItem,
  TFilterResult,
  TFilterValues,
} from './types';
import {
  FilterItemContent,
  FilterItemDataRange,
  FilterItemDataRangeInput,
  FiltersContent,
} from './styles';
import { ButtonPrimary, CloseIcon } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { CustomSelect } from 'app/components/CustomSelect';

export interface IFiltersProps {
  isOpen: boolean;
  filters: Array<TFilterItem>;
  filterValues: TFilterValues;
  handleClose: () => void;
  handleFilter: (selectedFilters: TFilterResult) => void;
}
const Filters = ({
  isOpen,
  filters,
  filterValues,
  handleClose,
  handleFilter,
}: IFiltersProps) => {
  const theme = useTheme();
  const [selectedFilters, updateSelectedFilters] = useState<TFilterResult>(
    {} as TFilterResult,
  );

  useEffect(() => {
    const selectedFiltersDefault = Object.keys(
      filterValues,
    ).reduce<TFilterResult>((filtersDefault, filterKey) => {
      const filterItem = filters.find(
        (filterItemObj) => filterItemObj.name === filterKey,
      );
      if (filterItem) {
        return {
          ...filtersDefault,
          [filterKey]: {
            filter: filterItem,
            value: filterValues[filterKey],
          },
        };
      }
      return filtersDefault;
    }, {} as TFilterResult);
    updateSelectedFilters(selectedFiltersDefault);
  }, [filterValues]);

  const onTextFilterChange = useCallback(
    (filter: TFilterItem, value: string) => {
      updateSelectedFilters((values) => ({
        ...values,
        [filter.name]: {
          filter,
          value,
        },
      }));
    },
    [selectedFilters],
  );

  const onSelectFilterChange = useCallback(
    (filter: TFilterItem, value: TCustomValue | TCustomValue[]) => {
      updateSelectedFilters((values) => ({
        ...values,
        [filter.name]: {
          filter,
          value:
            filter.type === EFilterItemType.MULTI_SELECT
              ? (value as TCustomValue[]).map((v) => v.value as string)
              : ((value as TCustomValue).value as string),
        },
      }));
    },
    [selectedFilters],
  );

  const onDateFilterChange = useCallback(
    (filter: TFilterItem, value: Date | null) => {
      try {
        updateSelectedFilters((values) => ({
          ...values,
          [filter.name]: {
            filter,
            value: value?.toISOString() ?? null,
          },
        }));
      } catch {
        updateSelectedFilters((values) => ({
          ...values,
          [filter.name]: {
            filter,
            value: null,
          },
        }));
      }
    },
    [selectedFilters],
  );

  const onDateRangeFilterChange = useCallback(
    (
      filter: TFilterItem,
      dateKey: 'dateFrom' | 'dateTo',
      value: Date | null,
    ) => {
      try {
        updateSelectedFilters((values) => ({
          ...values,
          [filter.name]: {
            ...(values[filter.name] || {}),
            filter,
            [dateKey]: value?.toISOString() ?? null,
          },
        }));
      } catch {
        updateSelectedFilters((values) => ({
          ...values,
          [filter.name]: {
            ...(values[filter.name] || {}),
            filter,
            [dateKey]: null,
          },
        }));
      }
    },
    [selectedFilters],
  );

  return (
    <BaseModal open={isOpen}>
      <FiltersContent>
        <div className="header-area">
          <Heading3>Filtros</Heading3>
          <div className="close-btn" onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body-content">
          {filters.map((filter) => {
            if (filter.type === EFilterItemType.TEXT) {
              return (
                <FilterItemContent key={filter.name} isFull={filter.isFull}>
                  <CustomInput
                    name={filter.name}
                    id={filter.name}
                    value={
                      (selectedFilters[filter.name]?.value as string) || ''
                    }
                    handleInputChange={(e) =>
                      onTextFilterChange(filter, e?.currentTarget?.value)
                    }
                    placeholder={filter.placeholder}
                    labelValue={filter.label}
                  />
                </FilterItemContent>
              );
            }
            if (filter.type === EFilterItemType.DATE) {
              return (
                <FilterItemContent key={filter.name} isFull={filter.isFull}>
                  <CalendarInput
                    name={filter.name}
                    labelValue={filter.label}
                    placeholder={filter.placeholder}
                    value={
                      (selectedFilters[filter.name]?.value as string) || ''
                    }
                    onChange={(e) => onDateFilterChange(filter, e)}
                    validationError={''}
                  />
                </FilterItemContent>
              );
            }
            if (filter.type === EFilterItemType.DATE_RANGE) {
              return (
                <FilterItemContent key={filter.name} isFull={filter.isFull}>
                  <TextS weight="Bold">{filter.label}</TextS>
                  <FilterItemDataRange>
                    <FilterItemDataRangeInput>
                      <CalendarInput
                        name={`${filter.name}_dateFrom`}
                        labelValue=""
                        placeholder={filter.placeholder}
                        value={
                          (selectedFilters[filter.name]?.dateFrom as string) ||
                          ''
                        }
                        onChange={(e) =>
                          onDateRangeFilterChange(filter, 'dateFrom', e)
                        }
                        validationError={''}
                      />
                    </FilterItemDataRangeInput>
                    <FilterItemDataRangeInput>
                      <CalendarInput
                        name={`${filter.name}_dateTo`}
                        labelValue=""
                        placeholder={filter.placeholder}
                        value={
                          (selectedFilters[filter.name]?.dateTo as string) || ''
                        }
                        onChange={(e) =>
                          onDateRangeFilterChange(filter, 'dateTo', e)
                        }
                        validationError={''}
                      />
                    </FilterItemDataRangeInput>
                  </FilterItemDataRange>
                </FilterItemContent>
              );
            }

            const selectValue =
              filter.type === EFilterItemType.MULTI_SELECT
                ? (selectedFilters[filter.name]?.value as TCustomValue[])?.map(
                    (filterMultiValue) => {
                      const filterMultiItemOption = filter.options?.find(
                        (option) => option.value === filterMultiValue,
                      );
                      return filterMultiItemOption as TCustomValue;
                    },
                  )
                : filter.options?.find(
                    (option) =>
                      option.value ===
                        (selectedFilters[filter.name]?.value as string) || '',
                  );
            if (
              [EFilterItemType.SELECT, EFilterItemType.MULTI_SELECT].includes(
                filter.type,
              )
            ) {
              return (
                <FilterItemContent key={filter.name} isFull={filter.isFull}>
                  <CustomSelect
                    hideLineItem={true}
                    labelValue={filter.label}
                    value={selectValue}
                    options={filter.options || []}
                    handleInputChange={(e) =>
                      onSelectFilterChange(filter, e.currentTarget.value)
                    }
                    name={filter.name}
                    multi={filter.type === EFilterItemType.MULTI_SELECT}
                    placeholder={filter.placeholder}
                    isClearable={true}
                    validationError={''}
                  />
                </FilterItemContent>
              );
            }
          })}
        </div>
        <div className="footer-content">
          <ButtonPrimary
            disabled={Object.keys(selectedFilters).length === 0}
            onClick={() => handleFilter(selectedFilters)}
            style={{
              width: '100px',
            }}
          >
            Aplicar
          </ButtonPrimary>
        </div>
      </FiltersContent>
    </BaseModal>
  );
};

export default Filters;
