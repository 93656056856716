import { isValidCNPJ } from 'app/helpers';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { TCustomValue } from 'app/components';
import * as yup from 'yup';

export const aboutStepSchema = yup.object({
  taxpayer_id: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,
      'Formato de CNPJ incorreto',
    )
    .test('test-invalid-cnpj', 'CNPJ inválido', (cnpj) =>
      isValidCNPJ(cnpj || ''),
    ),
  legal_name: yup.string().required('Campo obrigatório'),
  phone: yup
    .string()
    .test(
      'test-phone-number',
      'Formato de telefone incorreto',
      function (value) {
        const { path, createError } = this;

        if (
          value &&
          value.length < 15 &&
          value?.replace(/\D+/g, '')?.match(/(\d{2})(\d)/)?.[2] === '9'
        ) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        if (value && value.length < 14) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        return true;
      },
    )
    .required('Campo obrigatório'),
  // eslint-disable-next-line no-useless-escape
  email_address: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo obrigatório'),
  foundation_date: yup.string().nullable().required('Campo obrigatório'),
  name: yup
    .string()
    .nullable()
    .test('test-name', 'Máximo 100 caracteres', function (value) {
      const { path, createError } = this;

      if (value && value.length > 100) {
        return createError({
          path,
          message: 'Máximo 100 caracteres',
        });
      }

      return true;
    }),
});

export const addressStepSchema = yup.object({
  country_code: yup
    .object({
      value: yup.string().required('Campo obrigatório'),
      label: yup.string(),
    })
    .required('Campo obrigatório'),
  postal_code: yup
    .string()
    .min(8, 'Insira um valor válido')
    .required('Campo obrigatório'),
  noNumber: yup.bool(),
  district: yup.string().required('Campo obrigatório'),
  street_name: yup.string().required('Campo obrigatório'),
  street_number: yup.string().when('noNumber', {
    is: (noNumber: boolean) => noNumber === false,
    then: yup.string().required('Campo obrigatório'),
  }),
  extra_info: yup.string(),
  state_code: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  city: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  references: yup.string(),
});

export const billingAddressStepSchema = yup.object({
  billing_address_postal_code: yup
    .string()
    .min(8, 'Insira um valor válido')
    .required('Campo obrigatório'),
  billing_address_noNumber: yup.bool(),
  billing_address_district: yup.string().required('Campo obrigatório'),
  billing_address_street_name: yup.string().required('Campo obrigatório'),
  billing_address_street_number: yup.string().required('Campo obrigatório'),
  billing_address_extra_info: yup.string(),
  billing_address_state_code: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  billing_address_city: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  billing_address_references: yup.string(),
});

export const bankStepSchema = yup.object({
  ispb_code: yup.object().nullable(),
  bank_account: yup.string().when('ispb_code', {
    is: (ispb_code?: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup.string().required('Campo obrigatório'),
  }),

  bank_branch: yup.string().when('ispb_code', {
    is: (ispb_code: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup
      .string()
      .min(4, 'Formato incorreto')
      .required('Campo obrigatório'),
  }),
  bank_account_type: yup
    .object()
    .nullable()
    .when('ispb_code', {
      is: (ispb_code?: TCustomValue) =>
        ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
      then: yup.object().nullable().required('Campo obrigatório'),
    }),
});

export const pixStepSchema = yup.object({
  key: yup.string().required('Campo obrigatório'),
});

export const businessRelationSchema = yup.object().shape({
  share: yup.string().test('test-share', 'Valor inválido', function (value) {
    const { path, createError } = this;

    if (
      value &&
      (Number(formatToCurrencyFloat(value)) > 100 ||
        Number(formatToCurrencyFloat(value)) < 0)
    ) {
      return createError({
        path,
        message: 'Valor deve ser entre 0 e 100',
      });
    }

    return true;
  }),
});
