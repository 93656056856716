import React, { useState } from 'react';
import { TextField, useTheme } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import {
  CalendarInputContainer,
  InputLabelContainer,
  LineItem,
  TextFieldArea,
} from './styles';
import { InputError } from '../typography';

export interface ICalendarInputProps {
  name: string;
  labelValue: string;
  value: string | null;
  placeholder: string;
  validationError?: string;
  onChange: (value: Date | null) => void;
}

const CalendarInput = ({
  labelValue,
  name,
  value,
  placeholder,
  onChange,
  validationError,
}: ICalendarInputProps) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleInputClick = () => {
    setIsOpen((state) => !state);
  };

  const handleCalendarClose = () => {
    setIsOpen(false);
  };

  return (
    <CalendarInputContainer>
      {labelValue && <InputLabelContainer>{labelValue}</InputLabelContainer>}
      <div style={{ marginTop: '10px' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DesktopDatePicker
            open={isOpen}
            label={null}
            inputFormat="dd/MM/yyyy"
            value={value ? value : null}
            onChange={onChange}
            PaperProps={{
              sx: {
                '& .MuiCalendarOrClockPicker-root': {
                  '& .MuiCalendarPicker-root': {
                    '& .MuiPickersCalendarHeader-root': {
                      '& .MuiPickersCalendarHeader-labelContainer': {
                        backgroundColor: '#FDFDFD',
                        border: '1px solid #E6E5EA !important',
                        borderRadius: '10px',
                        padding: '16px 14px',
                        paddingRight: '0px',
                        color: theme.palette.brand.primary.base as string,
                        fontWeight: 600,
                      },
                      '& .MuiPickersArrowSwitcher-root': {
                        button: {
                          svg: {
                            color: theme.palette.brand.primary.base as string,
                          },
                        },
                      },
                    },
                  },

                  '& .MuiButtonBase-root.Mui-selected': {
                    backgroundColor: theme.palette.brand.primary.base as string,
                  },
                },
                '& .MuiYearPicker-root': {
                  '& .PrivatePickersYear-root': {
                    '& .Mui-selected': {
                      backgroundColor: theme.palette.brand.primary
                        .base as string,
                    },
                  },
                },
                backgroundColor: '#F7F7F7',
                borderRadius: '10px',
                button: {
                  backgroundColor: 'transparent',
                  color: '#1E1360',
                  fontWeight: 600,
                  span: {},
                },
              },
            }}
            InputAdornmentProps={{
              position: 'start',
              onClick: handleInputClick,
            }}
            renderInput={(params) => (
              <TextFieldArea>
                <LineItem />
                <TextField
                  name={name}
                  InputLabelProps={{ shrink: false, hidden: true }}
                  label=""
                  placeholder={placeholder}
                  {...params}
                />
              </TextFieldArea>
            )}
            onClose={handleCalendarClose}
            PopperProps={{ placement: 'bottom' }}
          />
        </LocalizationProvider>
      </div>
      {validationError && <InputError>{validationError}</InputError>}
    </CalendarInputContainer>
  );
};

export default CalendarInput;
