import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import {
  ButtonPrimary,
  ChevronIcon,
  CustomCircularIndicator,
  DeleteButton,
  DownloadButton,
  TextM,
} from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  StepContentButtonsArea,
  StepContentInputArea,
  TableBodyFile,
  TableFilesWrapper,
  TableHeadFile,
  UploadArea,
} from './styles';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import {
  TTableBodyContent,
  TTableHeadCell,
} from '../../../../../app/components';
import { Upload } from '../../../../../app/components';
import { useUploadBusinessFiles } from '../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'app/components';
import { routingPath } from 'app/routes';
import {
  BusinessDocumentTypeLabel,
  DOCUMENT_TYPE,
} from 'app/helpers/documentTypes';
import { iconByFileType } from 'modules/customer/components/DocumentsModal/DocumentsModal';
import { useCustomerContext } from 'modules/customer/context';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import {
  RequiredArea,
  RequiredAreaIcon,
  RequiredDocumentsList,
  RequiredDocumentsListItem,
} from '../../../components';
import { AttentionRequiredDocuments } from 'app/components/Icons';

const tableBodyFileStyle = {
  display: 'flex',
};

const tableHeadRaw = {
  id: '1',
  content: ['Nome', 'Tipo', 'Data de envio', 'Baixar', 'Excluir'],
};

type BusinessDocumentTypeLabelType = typeof BusinessDocumentTypeLabel;

const DocumentsStep = () => {
  const theme = useTheme();
  const { createdBusiness } = useContext(LegalPersonHandlerStepperContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    handleUploadFiles,
    downloadBusinessFile,
    handleDeleteBusinessFile,
    uploadedFiles,
    uploadedFilesLoading,
    deleteRequestLoading,
  } = useUploadBusinessFiles();
  const {
    state: { func, product },
  } = useCustomerContext();

  const [tableHeadContent, setTableHeadContent] = useState<
    TTableHeadCell[] | null
  >(null);
  const [tableBodyContent, setTableBodyContent] = useState<
    TTableBodyContent[] | null
  >(null);
  const [requiredDocuments, updateRequiredDocuments] = useState<
    DOCUMENT_TYPE[]
  >([]);

  useEffect(() => {
    const businessDocuments =
      func.value === EFunctions.BORROWER
        ? product.variables_configuration?.borrower.business_document
        : product.variables_configuration?.employer_document;

    if (businessDocuments)
      updateRequiredDocuments(
        businessDocuments
          ?.filter((document) => {
            if (!document.required) return false;
            const hasUploaded = uploadedFiles.find(
              (uploadedFile) => uploadedFile.type === document.type,
            );
            return !hasUploaded;
          })
          .map((document) => document.type),
      );
  }, [product, func, uploadedFiles]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    handleUploadFiles(acceptedFiles, createdBusiness?.id || '');
  }, []);

  const handleRegister = () => {
    if (state?.applicationRequestFlow) {
      navigate(`/${routingPath.products.applicationRequest}`, {
        state: {
          ...state,
          borrower: createdBusiness,
        },
      });
    } else {
      navigate('/records/legal');
    }
  };

  const renderCreationDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const diffInDays = differenceInDays(new Date(), date);
    return (
      <TextM weight="Regular">
        {diffInDays > 2
          ? format(date, 'dd/MM/yyyy')
          : diffInDays === 0
          ? 'Hoje'
          : `${diffInDays} dia(s) atrás`}
      </TextM>
    );
  };

  useEffect(() => {
    const parsedHeadContent = tableHeadRaw.content.map((item, index) => {
      if (index === 0) {
        return {
          id: String(index),
          content: (
            <TableHeadFile>
              <TextM weight="Bold">Arquivo</TextM>
              <ChevronIcon />
            </TableHeadFile>
          ),
        };
      } else {
        return {
          id: String(index),
          content: <TextM weight="Bold">{item}</TextM>,
        };
      }
    });

    setTableHeadContent(parsedHeadContent);
  }, []);

  useEffect(() => {
    const parsedBodyContent = uploadedFiles.map((item, index) => {
      return {
        id: String(index),
        cells: [
          {
            id: '1',
            content: (
              <TableBodyFile style={tableBodyFileStyle}>
                <div>
                  {
                    iconByFileType[
                      item?.file_extension?.toLocaleLowerCase() ?? 'pdf'
                    ]
                  }
                </div>
                <TextM weight="Semibold">{item?.file_name}</TextM>
              </TableBodyFile>
            ),
          },
          {
            id: '2',
            content: (
              <TextM weight="Regular">
                {
                  BusinessDocumentTypeLabel[
                    (item?.type ||
                      'OTHER') as keyof BusinessDocumentTypeLabelType
                  ]
                }
              </TextM>
            ),
          },
          {
            id: '3',
            content: renderCreationDate(
              item?.created_at || new Date().toISOString(),
            ),
          },
          {
            id: '4',
            content: (
              <DownloadButton
                handleClick={() =>
                  downloadBusinessFile(
                    createdBusiness?.id || '',
                    item.id || '',
                    item,
                  )
                }
              />
            ),
          },
          {
            id: '5',
            content: (
              <DeleteButton
                handleClick={() => {
                  handleDeleteBusinessFile(
                    item.id || '',
                    createdBusiness?.id || '',
                  );
                }}
              />
            ),
          },
        ],
      };
    });

    setTableBodyContent(parsedBodyContent);
  }, [uploadedFiles]);

  return (
    <StepContent
      title="Documentos"
      description="Envie documentos relacionados a esta empresa (um por vez)"
    >
      <StepContentInputArea style={{ marginTop: '32px' }}>
        <TableFilesWrapper>
          <Table
            tableHeadCell={tableHeadContent}
            tableBodyContent={tableBodyContent}
          />
        </TableFilesWrapper>
        <UploadArea>
          <Upload handleDrop={handleDrop} loading={uploadedFilesLoading} />
        </UploadArea>
        {requiredDocuments.length > 0 && (
          <RequiredArea>
            <RequiredAreaIcon>
              <AttentionRequiredDocuments />
            </RequiredAreaIcon>
            <TextM weight="Bold">{`${requiredDocuments.length} documentos obrigatórios não foram enviados.`}</TextM>
            <RequiredDocumentsList>
              {requiredDocuments.map((document) => {
                return (
                  <RequiredDocumentsListItem key={document as string}>
                    {
                      BusinessDocumentTypeLabel[
                        document as keyof BusinessDocumentTypeLabelType
                      ]
                    }
                  </RequiredDocumentsListItem>
                );
              })}
            </RequiredDocumentsList>
          </RequiredArea>
        )}
      </StepContentInputArea>
      <StepContentButtonsArea style={{ marginTop: '50px' }}>
        {uploadedFilesLoading || deleteRequestLoading ? (
          <CustomCircularIndicator
            sx={{ color: theme.palette.brand.primary.base }}
          />
        ) : (
          <ButtonPrimary
            disabled={requiredDocuments.length > 0}
            onClick={handleRegister}
          >
            Concluir
          </ButtonPrimary>
        )}
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default DocumentsStep;
