import { useEffect } from 'react';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import { NoItemsRegistered } from '../../../../../../app/components';
import { useTheme } from '@mui/material';
import { useProductsService } from '../../../../services/hooks';
import { useProductsContext } from '../../../../context';
import { ProductCard } from '../ProductCard';
import { MainPageContentContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'app/components';

const MainPageContent = () => {
  const theme = useTheme();
  const { getProducts, getProductsLoading } = useProductsService();
  const navigate = useNavigate();
  const {
    state: { content },
  } = useProductsContext();

  useEffect(() => {
    getProducts();
  }, []);

  const handleProductCardClick = (id: string) => {
    navigate(`/products/detail/${id}/applications?page=0`);
  };

  return (
    <MainPageContentContainer isLoading={getProductsLoading}>
      {getProductsLoading ? (
        <Spinner />
      ) : content.length > 0 ? (
        content.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            handleClick={handleProductCardClick}
          />
        ))
      ) : (
        <NoItemsRegistered
          buttonText="Adicionar produto"
          icon={
            <AccountBalanceOutlined
              sx={{ color: theme.palette.brand.gray[100], fontSize: '160px' }}
            />
          }
          title="Nenhum produto cadastrado"
        />
      )}
    </MainPageContentContainer>
  );
};

export default MainPageContent;
