import { createContext, useEffect, useCallback, useState } from 'react';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import { useBreadcrumbsContext } from 'app/context';
import {
  UsersAccessHandlerContainer,
  UsersAccessHandlerStepContainer,
  UsersAccessHandlerStepperContainer,
} from './styles';
import { Stepper } from 'app/components';
import { InfoStep, PermissionsStep, ReviewStep } from './steps';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { ButtonPrimary } from 'app/components';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { useAuthContext } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const steps = [
  {
    step: 0,
    title: 'Identificação',
    clickable: false,
  },
  {
    step: 1,
    title: 'Permissões',
    clickable: false,
  },
  {
    step: 2,
    title: 'Ficou tudo certo?',
    clickable: false,
  },
];

type IUsersAccessHandlerStepperContext = {
  activeStep: number;
  onForward: () => void;
  onBack: () => void;

  userAccessData: UserAccess;
  onChangeUserAccessData: (userAccessData: Partial<UserAccess>) => void;
};

export const UsersAccessHandlerStepperContext =
  createContext<IUsersAccessHandlerStepperContext>({
    activeStep: 1,
    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },

    userAccessData: {} as UserAccess,
    onChangeUserAccessData: () => {
      throw new Error('Método não implementado');
    },
  });

const UsersAccessHandler = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuthContext();
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [userAccessData, updateUserAccessData] = useState<UserAccess>(
    {} as UserAccess,
  );

  useEffect(() => {
    if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN)) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setItems(
      [
        { to: '/access-management', label: 'Gestão de Acessos' },
        { to: '/access-management/new', label: 'Criar usuário' },
      ],

      <HomeOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  const onChangeUserAccessData = useCallback(
    (_userAccessData: Partial<UserAccess>) => {
      updateUserAccessData({
        ...userAccessData,
        ..._userAccessData,
      });
    },
    [userAccessData],
  );

  const onForward = useCallback(() => {
    if (activeStep < steps.length) {
      updateActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const onBack = useCallback(() => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN))
    return null;

  return (
    <UsersAccessHandlerStepperContext.Provider
      value={{
        activeStep,
        onForward,
        onBack,

        userAccessData,
        onChangeUserAccessData,
      }}
    >
      <UsersAccessHandlerContainer>
        <UsersAccessHandlerStepperContainer>
          <ButtonPrimary
            typeVariant="ghost"
            className="back-button"
            onClick={() => navigate(routingPath.management.access)}
          >
            <ArrowBack style={{ marginRight: '12px' }} />
            Usuários do portal
          </ButtonPrimary>
          <Stepper activeStep={activeStep} stepsItems={steps} />
        </UsersAccessHandlerStepperContainer>
        <UsersAccessHandlerStepContainer>
          {activeStep === 0 && <InfoStep />}
          {activeStep === 1 && <PermissionsStep />}
          {activeStep === 2 && <ReviewStep />}
        </UsersAccessHandlerStepContainer>
      </UsersAccessHandlerContainer>
    </UsersAccessHandlerStepperContext.Provider>
  );
};

export default UsersAccessHandler;
