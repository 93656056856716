import { Divider, DrawerProps, useTheme } from '@mui/material';
import { BaseSidesheet } from 'app/components';
import {
  ButtonDanger,
  ButtonPrimary,
  CloseIcon,
  CustomCircularIndicator,
  Heading3,
} from '../../../../app/components';
import { useWindowSize } from '../../../../app/hooks';
import {
  BusinessEditSideSheetContent,
  ButtonArea,
  CloseIconWrapper,
  SideSheetHeader,
} from './styles';

export interface IBusinessEditSideSheet extends DrawerProps {
  handleClose: () => void;
  handleSave?: () => void;
  handleRemove?: () => void;
  title: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isRemoveDisabled?: boolean;
  isCloseButtonDisabled?: boolean;
  isBigger?: boolean;
  showBackdrop?: boolean;
  closeButtonText?: string;
}

const BusinessEditSideSheet = ({
  title,
  handleClose,
  handleSave,
  handleRemove,
  open,
  isDisabled,
  isLoading,
  children,
  isRemoveDisabled,
  isCloseButtonDisabled,
  isBigger,
  closeButtonText,
  showBackdrop,
}: IBusinessEditSideSheet) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  return (
    <BaseSidesheet
      hideBackdrop={!showBackdrop}
      open={open}
      handleClose={handleClose}
    >
      <SideSheetHeader>
        <Heading3>{title}</Heading3>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </SideSheetHeader>
      <Divider />
      <BusinessEditSideSheetContent
        isBigger={isBigger}
        style={{
          width:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width! > theme.breakpoints.values.tablet
              ? theme.spacing(75)
              : theme.spacing(50),
        }}
      >
        {children}
        <ButtonArea>
          {!isLoading ? (
            <>
              {!isCloseButtonDisabled && (
                <ButtonPrimary
                  onClick={handleClose}
                  style={{ width: '160px' }}
                  typeVariant="outline"
                >
                  {closeButtonText ?? 'Cancelar'}
                </ButtonPrimary>
              )}
              {typeof handleRemove === 'function' && (
                <ButtonDanger
                  style={{ width: '160px' }}
                  onClick={handleRemove}
                  disabled={isRemoveDisabled}
                >
                  Remover
                </ButtonDanger>
              )}
              {typeof handleSave === 'function' && (
                <ButtonPrimary
                  onClick={handleSave}
                  disabled={isDisabled}
                  style={{ width: '160px' }}
                >
                  Salvar
                </ButtonPrimary>
              )}
            </>
          ) : (
            <CustomCircularIndicator
              sx={{ color: theme.palette.brand.primary.base }}
            />
          )}
        </ButtonArea>
      </BusinessEditSideSheetContent>
    </BaseSidesheet>
  );
};

export default BusinessEditSideSheet;
