import styled from '@emotion/styled';

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  .MuiStepLabel-root {
    cursor: pointer;
    padding: 0;
    margin: 4px 0px;

    svg {
      font-size: 35px;
    }
  }
  
  
  /* COMPLETED STEP */
  .Mui-completed {
    svg {
      color: rgba(0, 0, 0, 0.38);
      color: ${({ theme }) => theme.palette?.brand.gray[300]};
    }

  }

  /* END COMPLETED STEP */


  /* ARROW COMPLETED STEP */
  .MuiSvgIcon-root.Mui-completed {
    background-color: ${({ theme }) => theme.palette?.brand.gray[400]};
    border: none;
  }

  /* END ARROW COMPLETED STEP */

  
  /* GENERAL FONT STYLE */
  .MuiSvgIcon-root {
    text {
      fill: ${({ theme }) => theme.palette?.brand.gray[400]};
      font-family: 'Urbanist';
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.palette?.brand.gray[400]};
    }
  }
  
  .Mui-active {
    circle {
      color: ${({ theme }) => theme.palette?.brand.primary.base};
    }
  }

  /* END GENERAL FONT STYLE */

  
  /* CIRCULAR BORDER */
  .MuiSvgIcon-root {
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette?.brand.gray[400]};
    border-radius: 40px;
    
    circle {
      color: transparent;
    }
  }

  /* END CIRCULAR BORDER */
  

  /* ACTIVE STEP */
  .MuiSvgIcon-root.Mui-active {
    border: 0px solid transparent;
    circle {
      color:  ${({ theme }) => theme.palette?.brand.primary.base};
    }

    text {
      fill: #fff;
    }
  } 

  /* END ACTIVE STEP */


  /* SUBTITLE */
  .MuiStepLabel-labelContainer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.palette?.brand.gray[600]};
  }
  /* END SUBTITLE */


  /* TITLE */
  .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-disabled {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette?.brand.gray[500]}; //#9E9E9E 
  }
  /* END TITLE */

  /* TITLE STEP COMPLETED */
  .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-completed {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette?.brand.gray[600]}; //#757575
  }

  /* END TITLE STEP COMPLETED */


  /* TITLE STEP ACTIVE */
  .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette?.brand.gray[800]}; //#757575
  }

  /* END TITLE STEP ACTIVE */


  /* COMPLETED STEP SUBTITLE */
  .MuiStep-root.Mui-completed .MuiStepLabel-labelContainer {
    color: ${({ theme }) => theme.palette?.brand.gray[600]};;
  }
  /* END COMPLETED STEP SUBTITLE */


  /* ACTIVE STEP SUBTITLE */
  .current .MuiStepLabel-labelContainer {
    color: ${({ theme }) => theme.palette?.brand.gray[800]};
  }
  /* END ACTIVE STEP SUBTITLE */

  /* COMPLETED STEP SUBTITLE */
  .MuiStep-root .Mui-disabled .MuiStepLabel-labelContainer {
    color: ${({ theme }) => theme.palette?.brand.gray[500]};;
  }
  /* END COMPLETED STEP SUBTITLE */

  `;

export const ConnectorBullet = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette?.brand.gray[300]};
  margin-top: 3px;
  margin-left: 15px;
`;

export const Bullets = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette?.brand.gray[300]};
  margin-top: 3px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;

  &:nth-of-type(1) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette?.brand.gray[300]};
    margin-top: 3px;
    margin-left: 13px;
  }
`;

export const StepperStorybook = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
`;

export const StepperStorybookContainer = styled.div`
  max-width: 25%;
`;

export const StepperStorybookStepContainer = styled.div`
  width: 75%;
  padding-left: 30px;
`;
