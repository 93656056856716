import { formatCurrency } from 'app/utils/normalizer';
import { useTheme } from '@mui/material';

import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { TRegisterPersonDataRequest } from 'modules/customer/context';
import { TProducts, useQualificationsContext } from 'modules/products/context';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { TQualificationStatusOption } from 'modules/products/pages/interfaces';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  TApplicationResponse,
  EApplicationStatus,
  EApplicationStatusDescription,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomTableSelect,
  BoxSection,
  ButtonPrimary,
  Heading4,
  Spinner,
} from 'app/components';
import { TextM, TextS } from 'app/components/typography/body';
import { TextL } from 'app/components/typography/body/TextL';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';
import {
  ButtonContainer,
  Container,
  EmptyContainer,
  TableArea,
  TableWrapper,
} from './styles';
import {
  AddIcon,
  CopyIcon,
  DetailsIcon,
  InstallmentsFiltersIcon,
} from 'app/components/Icons';
import { routingPath } from 'app/routes';
import { EmptyApplications } from 'app/components/Icons/svgs';
import { NaturalPersonViewApplicationSideSheet } from '../NaturalPersonViewApplicationSideSheet';
import {
  handleGetStaticStatusList,
  handleStatusListPermissions,
} from 'modules/products/helpers/statusList';
import { useQualificationChangeContext } from 'modules/products/context/QualificationChangeProvider/QualificationChangeProvider';
import { Heading3, Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { format } from 'date-fns';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import ApplicationsFilters, {
  TFilters,
} from 'modules/applications/components/ApplicationsFilters/ApplicationsFilters';
import { initalFilters } from 'modules/applications/utils/filters';
import { ApplicationsContentFiltersCount } from 'modules/products/pages/ProductDetail/components/ApplicationContent/styles';

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Produto</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ textAlign: 'center' }}>
          Solicitado
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ textAlign: 'center' }}>
          Financiado
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ textAlign: 'center' }}>
          TC
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ textAlign: 'center' }}>
          IOF
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ width: '150px' }}>
          Criado em
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ textAlign: 'center' }}>
          Status
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '8',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

interface INaturalPersonViewApplicationTabProps {
  personData: TRegisterPersonDataRequest;
}

export type TApplicationData = {
  productData?: TProducts;
  qualificationData?: TQualificationRequestApplication;
  statusApproval: TQualificationStatusOption[];
} & TApplicationResponse;

const NaturalPersonViewApplicationTab = ({
  personData,
}: INaturalPersonViewApplicationTabProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    state: { borrowerStatusOptions },
  } = useQualificationsContext();
  const {
    handleUpdateQualificationStatus,
    handleUpdateQualificationStatusLoading,
  } = useQualificationChangeContext();
  const [isFiltersOpened, toggleFiltersOpened] = useState<boolean>(false);
  const [filters, updateFilters] = useState<TFilters>(
    initalFilters as TFilters,
  );
  const { getQualificationStatusOptions, getQualificationRequestApplication } =
    useApplicationsService();
  const { getApplications, applicationLoading } = useApplicationsService();
  const { getProduct } = useProductsService();
  const [isLoading, setIsLoading] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [applicationState, setApplicationState] = useState<
    TApplicationData[] | undefined
  >([]);
  const [applicationDetail, setApplicationDetail] =
    useState<TApplicationData | null>(null);
  const { isProfileAuthorized } = usePermify();
  const { userInfo } = useAuthContext();
  const { showSnackbarCopy } = useSnackbar();

  const handleGetApplications = async (
    page: number,
    personId: string,
    itemsPerPage?: number,
  ) => {
    setIsLoading(true);
    setApplicationState([]);
    try {
      const applicationData = await getApplications(
        page,
        itemsPerPage,
        {
          product_id: filters?.productId,
          borrower_id: personId,
          created_date_from: formatDateForBackendString(
            filters?.minCreatedAtDate,
          ),
          created_date_to: formatDateForBackendString(
            filters?.maxCreatedAtDate,
          ),
          disbursement_date_from: formatDateForBackendString(
            filters?.minDisbursementDate,
          ),
          disbursement_date_to: formatDateForBackendString(
            filters?.maxDisbursementDate,
          ),
          disbursement_processed_date_from: formatDateForBackendString(
            filters.minDisbursementProcessedAtDate,
          ),
          disbursement_processed_date_to: formatDateForBackendString(
            filters.maxDisbursementProcessedAtDate,
          ),
          funding_id: filters?.fundingId,
        },
        filters.status && filters.status.length === initalFilters.status.length
          ? undefined
          : filters.status,
      );
      setTotalItemsQty(applicationData?.total_elements || 0);
      if (
        applicationData?.content !== undefined &&
        Array.isArray(applicationData?.content)
      ) {
        const applicationDataWithProduct = await Promise.all<TApplicationData>(
          applicationData?.content.map(async (application) => {
            const productId = application.product.id;
            const statusApproval = await getQualificationStatusOptions(
              productId,
            );
            const qualificationDataArr =
              await getQualificationRequestApplication(
                productId,
                0,
                application.borrower.id,
                application.id,
              );
            try {
              const productData = await getProduct(productId);
              return {
                ...application,
                qualificationData: qualificationDataArr
                  ? qualificationDataArr?.content?.[0]
                  : undefined,
                statusApproval: statusApproval,
                productData: productData?.data,
              };
            } catch {
              return {
                ...application,
                qualificationData: qualificationDataArr
                  ? qualificationDataArr?.content?.[0]
                  : undefined,
                statusApproval: statusApproval,
                productData: undefined,
              };
            }
          }),
        );
        setApplicationState((state) =>
          state?.concat(
            applicationDataWithProduct.filter((content) => {
              const inx = state?.findIndex(
                (stateContent) => stateContent?.id === content?.id,
              );
              return inx < 0;
            }),
          ),
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCopyItems = () => {
    const header =
      'created_at\tid\tborrower_id\tproduct_id\trequested_amount' +
      '\tinterest_rate\ttac_amount\tfinance_fee\tnum_payments' +
      '\tfirst_payment_date\tdisbursement_date\tissue_date\tstatus' +
      '\tstatus_description\tsequential_id\tfunding_id\tfunding_name' +
      '\tloan_total_processing_cost\tloan_annual_interest_rate\tloan_annual_effective_interest_rate' +
      '\tloan_monthly_effective_interest_rate\tloan_total_amount_owed' +
      '\tloan_disbursement_amount\tloan_financed_amount\tloan_iof_daily_rate\tloan_iof_amount' +
      '\tloan_iof_base_rate\tloan_payment_amount\tloan_issue_date\tloan_last_payment_date' +
      '\r\n';

    const content = applicationState
      ?.map((item) => {
        return `${
          (item?.created_at
            ? format(new Date(item?.created_at), 'yyyy-MM-dd')
            : '') || ''
        }\t${item?.id || ''}\t${item?.borrower?.id || ''}\t${
          item?.product?.id || ''
        }\t${item?.requested_amount?.toLocaleString() || ''}\t${
          item?.interest_rate?.toLocaleString() || ''
        }\t${item?.tac_amount?.toLocaleString() || ''}\t${
          item?.finance_fee?.toLocaleString() || ''
        }\t${item?.num_payments || ''}\t${item?.first_payment_date || ''}\t${
          item?.disbursement_date || ''
        }\t${item?.issue_date || ''}\t${item?.status || ''}\t${
          item?.status_description || ''
        }\t${item?.sequential_id || ''}\t${item?.funding?.id || ''}\t${
          item?.funding?.legal_name || ''
        }\t${
          item?.loan_details?.total_processing_cost?.toLocaleString() || ''
        }\t${
          item?.loan_details?.annual_interest_rate?.toLocaleString() || ''
        }\t${
          item?.loan_details?.annual_effective_interest_rate.toLocaleString() ||
          ''
        }\t${item?.loan_details?.monthly_effective_interest_rate?.toLocaleString()}\t${
          item?.loan_details?.total_amount_owed?.toLocaleString() || ''
        }\t${
          item?.loan_details?.disbursement_amount?.toLocaleString() || ''
        }\t${item?.loan_details?.financed_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.iof_daily_rate?.toLocaleString() || ''
        }\t${item?.loan_details?.iof_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.iof_base_rate?.toLocaleString() || ''
        }\t${item?.loan_details?.payment_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.issue_date || ''
        }\t${item?.loan_details?.last_payment_date || ''}`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${content}`);
    showSnackbarCopy();
  };

  useEffect(() => {
    if (!personData.id) {
      navigate(routingPath.records.natural);
    } else {
      handleGetApplications(currentPage, personData.id as string, linesPerPage);
    }
  }, [personData, linesPerPage, currentPage]);

  useEffect(() => {
    if (currentPage === 0) {
      handleGetApplications(0, personData.id as string, linesPerPage);
    } else {
      setCurrentPage(0);
    }
  }, [filters]);

  const handleOpenApplicationDetail = (
    application: TApplicationData | undefined,
  ) => {
    if (application) setApplicationDetail(application);
  };

  const handleCloseApplicationDetail = () => {
    setApplicationDetail(null);
  };

  const handleUpdateQualificationStatusAction = (
    application: TApplicationData | null,
    identifier: string,
  ) => {
    handleUpdateQualificationStatus(
      application as TApplicationResponse,
      identifier,
      application?.qualificationData,
    ).then((retn) => {
      if (retn) {
        setApplicationState((state) =>
          state?.map((applicationFromState) => {
            if (applicationFromState?.id === application?.id) {
              if (retn.isQualification) {
                return {
                  ...applicationFromState,
                  qualificationData:
                    retn.newStatus as TQualificationRequestApplication,
                };
              }
              return {
                ...applicationFromState,
                status: retn.newStatus as string,
              };
            }
            return applicationFromState;
          }),
        );
        if (applicationDetail) {
          setApplicationDetail(() => {
            const updatedApplicationDetail = { ...applicationDetail };
            if (retn.isQualification) {
              return {
                ...updatedApplicationDetail,
                qualificationData:
                  retn.newStatus as TQualificationRequestApplication,
              };
            }
            return {
              ...updatedApplicationDetail,
              status: retn.newStatus as string,
            };
          });
        }
      }
    });
  };

  const getStatusList = useCallback((application: TApplicationData) => {
    const { statusApproval } = application;
    if (
      application.status === EApplicationStatus.PENDING_QUALIFICATION &&
      statusApproval
    ) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ]
        .concat(
          statusApproval && statusApproval?.length > 0
            ? statusApproval.map((status) => ({
                label: status.description,
                value: status.identifier,
              }))
            : [],
        )
        .concat({
          label: 'Negado',
          value: EQualificationStatus.DENIED as string,
        });
    } else if (
      application.status === EApplicationStatus.PENDING_QUALIFICATION &&
      !statusApproval
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[EApplicationStatus.PENDING_RANDOM],
          value: EQualificationStatus.PENDING_RANDOM as string,
        },
        {
          label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
          value: EQualificationStatus.CANCELED as string,
        },
      ];
    }

    return handleGetStaticStatusList(application.status);
  }, []);

  const filterCount = useMemo(() => {
    let counter = 0;
    if (filters.minCreatedAtDate || filters.maxCreatedAtDate) counter++;
    if (filters.minDisbursementDate || filters.maxDisbursementDate) counter++;
    if (
      filters.minDisbursementProcessedAtDate ||
      filters.maxDisbursementProcessedAtDate
    )
      counter++;
    if (filters.productId) counter++;
    if (filters.fundingId) counter++;

    if (filters.status?.length !== 6) counter++;
    return counter;
  }, [filters]);

  const onApplyFilters = useCallback((f: TFilters) => {
    updateFilters(f);
    toggleFiltersOpened(false);
  }, []);

  return (
    <BoxSection>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading3
          style={{
            color: theme.palette.brand.secondary.base,
          }}
        >
          Solicitações
        </Heading3>
        <ButtonContainer>
          {applicationState && applicationState.length > 0 && (
            <ButtonPrimary
              style={{
                backgroundColor: 'transparent',
                width: '200px',
              }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <CopyIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={handleCopyItems}
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Copiar resultado
              </TextM>
            </ButtonPrimary>
          )}
          {isProfileAuthorized(EAccountPermissions.CREATE_APPLICATION) && (
            <ButtonPrimary
              style={{
                backgroundColor: 'transparent',
                marginLeft: '11px',
                width: '200px',
              }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={() =>
                navigate(`/${routingPath.products.applicationRequest}`, {
                  state: {
                    person: personData,
                  },
                })
              }
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Criar solicitação
              </TextM>
            </ButtonPrimary>
          )}
          <ButtonPrimary
            style={{
              maxWidth: filterCount > 0 ? '150px' : '120px',
              marginLeft: '11px',
            }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <InstallmentsFiltersIcon
                color={theme.palette.brand.primary.base as string}
              />
            }
            onClick={() => toggleFiltersOpened(() => true)}
          >
            <TextM
              weight="Bold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base as string,
              }}
            >
              Filtros
            </TextM>
            {filterCount > 0 && (
              <ApplicationsContentFiltersCount>
                {filterCount}
              </ApplicationsContentFiltersCount>
            )}
          </ButtonPrimary>
        </ButtonContainer>
      </div>
      {borrowerStatusOptions &&
      applicationState &&
      applicationState.length > 0 ? (
        <>
          <Container>
            <TableArea>
              <TableWrapper>
                <Table
                  showPagination
                  handlePageChange={(selectedPage) => {
                    setCurrentPage(selectedPage - 1);
                    handleGetApplications(
                      selectedPage - 1,
                      personData?.id as string,
                      linesPerPage,
                    );
                  }}
                  handleLinesPerPage={(selectedLinesPerPage) => {
                    setCurrentPage(0);
                    setLinesPerPage(
                      selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                    );
                  }}
                  totalElementsQty={totalItemsQty}
                  currentPage={currentPage + 1}
                  linesPerPage={linesPerPage}
                  tableFooterCell={[
                    {
                      id: '1',
                      content: null,
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <div style={{ display: 'block' }}>
                            <div>
                              <TextM>{`Total (${applicationState.length})`}</TextM>
                            </div>
                            <div>
                              <TextM weight="Bold">
                                {formatCurrency(
                                  applicationState
                                    .map(
                                      (application) =>
                                        application?.requested_amount || 0,
                                    )
                                    .reduce((a, b) => a + b, 0),
                                )}
                              </TextM>
                            </div>
                          </div>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <div style={{ display: 'block' }}>
                            <div>
                              <TextM>{`Total (${applicationState.length})`}</TextM>
                            </div>
                            <div>
                              <TextM weight="Bold">
                                {formatCurrency(
                                  applicationState
                                    .map(
                                      (application) =>
                                        application?.loan_details
                                          ?.financed_amount || 0,
                                    )
                                    .reduce((a, b) => a + b, 0),
                                )}
                              </TextM>
                            </div>
                          </div>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <div style={{ display: 'block' }}>
                            <div>
                              <TextM>{`Total (${applicationState.length})`}</TextM>
                            </div>
                            <div>
                              <TextM weight="Bold">
                                {formatCurrency(
                                  applicationState
                                    .map(
                                      (application) =>
                                        application?.tac_amount || 0,
                                    )
                                    .reduce((a, b) => a + b, 0),
                                )}
                              </TextM>
                            </div>
                          </div>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer>
                          <div style={{ display: 'block' }}>
                            <div>
                              <TextM>{`Total (${applicationState.length})`}</TextM>
                            </div>
                            <div>
                              <TextM weight="Bold">
                                {formatCurrency(
                                  applicationState
                                    .map(
                                      (application) =>
                                        application?.loan_details?.iof_amount ||
                                        0,
                                    )
                                    .reduce((a, b) => a + b, 0),
                                )}
                              </TextM>
                            </div>
                          </div>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '6',
                      content: null,
                    },
                    {
                      id: '7',
                      content: null,
                    },
                    {
                      id: '8',
                      content: null,
                    },
                  ]}
                  tableBodyContent={applicationState?.map(
                    (application, index) => {
                      return {
                        id: application?.id || String(index),
                        cells: [
                          {
                            id: '1',
                            content: (
                              <TableBodyCellContainer>
                                <TextS
                                  weight={
                                    !application.productData
                                      ? 'Regular'
                                      : 'Bold'
                                  }
                                >
                                  {!application.productData ? (
                                    <em>DESCREDENCIADO</em>
                                  ) : (
                                    application?.productData?.name || ''
                                  )}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '2',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {formatCurrency(
                                    application?.requested_amount,
                                  ) || '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '3',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {formatCurrency(
                                    application?.loan_details?.financed_amount,
                                  ) || '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '4',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {formatCurrency(application?.tac_amount)}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '5',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {formatCurrency(
                                    application?.loan_details?.iof_amount,
                                  )}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '6',
                            content: (
                              <TableBodyCellContainer>
                                <TextS style={{ paddingRight: '5px' }}>
                                  {`${formatDateFromBackend(
                                    application?.created_at || '',
                                  ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                application?.created_at || '',
                              )}`}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '7',
                            content: (
                              <TableBodyCellContainer>
                                <CustomTableSelect
                                  selectItems={getStatusList(
                                    application,
                                  ).filter(
                                    handleStatusListPermissions(
                                      application,
                                      application?.status ===
                                        EApplicationStatus.PENDING_QUALIFICATION
                                        ? application?.qualificationData?.status
                                        : application?.status,
                                      userInfo,
                                    ),
                                  )}
                                  disabled={
                                    !isProfileAuthorized(
                                      EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                                    ) || !application.productData
                                  }
                                  isLoading={
                                    handleUpdateQualificationStatusLoading ===
                                    application?.id
                                  }
                                  handleChange={(identifier) =>
                                    handleUpdateQualificationStatusAction(
                                      application,
                                      identifier,
                                    )
                                  }
                                  value={
                                    (application?.status ===
                                    EApplicationStatus.PENDING_QUALIFICATION
                                      ? application?.qualificationData?.status
                                      : application?.status) || ''
                                  }
                                />
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '8',
                            content: (
                              <TableBodyCellContainer
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  margin: '0px',
                                }}
                              >
                                <ButtonPrimary
                                  typeVariant="outline"
                                  style={{ width: 'fit-content' }}
                                  onClick={() =>
                                    handleOpenApplicationDetail(application)
                                  }
                                >
                                  <DetailsIcon
                                    color={
                                      theme.palette.brand.primary.base as string
                                    }
                                  />
                                </ButtonPrimary>
                              </TableBodyCellContainer>
                            ),
                          },
                        ],
                      };
                    },
                  )}
                  tableHeadCell={tableHeader}
                />
              </TableWrapper>
            </TableArea>
          </Container>
        </>
      ) : (
        <EmptyContainer>
          {applicationLoading || isLoading ? (
            <Spinner />
          ) : (
            <>
              <img src={EmptyApplications} />
              <Heading4
                style={{
                  marginTop: '30px',
                  color: theme.palette.brand.secondary.base,
                }}
                fontWeight="B"
              >
                Nenhum empréstimo solicitado
              </Heading4>
            </>
          )}
        </EmptyContainer>
      )}
      <NaturalPersonViewApplicationSideSheet
        application={applicationDetail}
        isOpen={applicationDetail !== null}
        handleClose={handleCloseApplicationDetail}
        handleUpdateQualificationStatus={handleUpdateQualificationStatusAction}
      />
      <ApplicationsFilters
        filters={filters}
        handleApply={onApplyFilters}
        handleClose={() => toggleFiltersOpened(false)}
        isLoading={applicationLoading}
        isOpen={isFiltersOpened}
        hideBorrower={true}
        hideApplication={true}
      />
    </BoxSection>
  );
};

export default NaturalPersonViewApplicationTab;
