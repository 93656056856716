import { useCustomVariables } from 'app/hooks';
import {
  EVariableType,
  TVariable,
  VariableTypeNames,
} from 'app/services/variablesHttpService';
import { useState, useEffect, useCallback, useContext } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  TextL,
  TextS,
} from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Popover, useTheme } from '@mui/material';
import {
  NaturalPersonViewCustomVariablesTabPopOver,
  TableWrapper,
} from './styles';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { CustomVariableEditSideSheet } from 'modules/customer/components';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { Heading3, Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { DetailsIcon } from 'app/components/Icons';
import { EmptyArea } from 'modules/management/components';

type INaturalPersonViewCustomVariablesTabProps = {
  personData: TRegisterPersonDataRequest;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Variáveis adicionadas</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Detalhes</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TextL weight="bold" style={{ minWidth: '100px' }}>
        Valor
      </TextL>
    ),
  },
  { id: '5', content: <div /> },
];

const NaturalPersonViewCustomVariablesTab = ({
  personData,
}: INaturalPersonViewCustomVariablesTabProps) => {
  const theme = useTheme();
  const [variables, updateVariables] = useState<TVariable[]>();
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const { getCustomVariablesForPerson, isCustomVariablesLoading } =
    useCustomVariables();
  const [editingCustomVariable, toggleEditingCustomVariable] =
    useState<TVariable | null>(null);
  useEffect(() => {
    getCustomVariablesForPerson().then(
      (vars) => vars && updateVariables(() => vars),
    );
  }, []);
  const { isProfileAuthorized } = usePermify();

  const handleOpenCustomVariablesSideSheet = useCallback(
    (variable: TVariable) => {
      toggleEditingCustomVariable(variable);
    },
    [],
  );

  const handleSaveCustomVariable = useCallback(
    (value: string | undefined) => {
      const newPersonData: TRegisterPersonDataRequest = {
        ...personData,
        custom_variables: {
          ...personData.custom_variables,
          [editingCustomVariable?.name as string]: value,
        },
      };
      updatePerson(newPersonData).then(() => {
        toggleEditingCustomVariable(null);
      });
    },
    [editingCustomVariable],
  );

  const getFormatedValue = useCallback(
    (variable: TVariable): string | null => {
      if (!personData.custom_variables) return null;
      if (
        variable.type === EVariableType.DATE &&
        typeof personData.custom_variables[variable.name] === 'string'
      )
        return formatDateFromBackend(
          personData.custom_variables[variable.name] as string,
        ).toLocaleDateString('pt-BR');
      if (variable.type === EVariableType.BOOLEAN) {
        if (personData.custom_variables[variable.name] === 'true') return 'Sim';
        if (personData.custom_variables[variable.name] === 'false')
          return 'Não';
        return '';
      }
      return personData.custom_variables[variable.name] as string;
    },
    [personData],
  );

  return (
    <BoxSection>
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Campos adicionais
      </Heading3>
      {variables && variables.length > 0 ? (
        <TableWrapper>
          <Table
            tableHeadCell={tableHeader}
            tableBodyContent={variables.map((variable, index) => {
              return {
                id: variable?.id || String(index),
                cells: [
                  {
                    id: '1',
                    content: (
                      <TableBodyCellContainer>
                        <TextS weight="Bold">{variable.display_name}</TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '2',
                    content: (
                      <TableBodyCellContainer>
                        <PopupState
                          variant="popover"
                          popupId={`${variable.display_name}-popup`}
                        >
                          {(popupState) => (
                            <>
                              <ButtonDefault
                                {...bindTrigger(popupState)}
                                style={{
                                  border: 'none',
                                  fontWeight: 'normal',
                                  background: 'transparent',
                                  textDecoration: 'underline',
                                  justifyContent: 'flex-start',
                                  padding: '0px',
                                }}
                              >
                                Ver descrição
                              </ButtonDefault>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <NaturalPersonViewCustomVariablesTabPopOver>
                                  <TextS>{variable.description}</TextS>
                                </NaturalPersonViewCustomVariablesTabPopOver>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '3',
                    content: (
                      <TableBodyCellContainer>
                        <TextS>{VariableTypeNames[variable.type]}</TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '4',
                    content: (
                      <TableBodyCellContainer>
                        <TextS style={{ minWidth: '100px' }}>
                          {personData.custom_variables &&
                          personData.custom_variables[variable.name] ? (
                            getFormatedValue(variable)
                          ) : isProfileAuthorized(
                              EAccountPermissions.UPDATE_PERSON_BUSINESS,
                            ) ? (
                            <ButtonDefault
                              onClick={() =>
                                handleOpenCustomVariablesSideSheet(variable)
                              }
                              style={{
                                border: 'none',
                                fontWeight: 'normal',
                                background: 'transparent',
                                textDecoration: 'underline',
                                minWidth: '100px',
                                justifyContent: 'flex-start',
                                padding: '0px',
                              }}
                            >
                              Definir valor
                            </ButtonDefault>
                          ) : (
                            <>Vazio</>
                          )}
                        </TextS>
                      </TableBodyCellContainer>
                    ),
                  },
                  {
                    id: '5',
                    content: isProfileAuthorized(
                      EAccountPermissions.UPDATE_PERSON_BUSINESS,
                    ) && (
                      <TableBodyCellContainer
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '0px',
                        }}
                      >
                        <ButtonPrimary
                          typeVariant="outline"
                          style={{ width: 'fit-content' }}
                          onClick={() =>
                            handleOpenCustomVariablesSideSheet(variable)
                          }
                        >
                          <DetailsIcon
                            color={theme.palette.brand.primary.base as string}
                          />
                        </ButtonPrimary>
                      </TableBodyCellContainer>
                    ),
                  },
                ],
              };
            })}
          />
        </TableWrapper>
      ) : (
        <EmptyArea
          message="Nenhum campo adicionado"
          isLoading={isCustomVariablesLoading}
        >
          {null}
        </EmptyArea>
      )}
      <CustomVariableEditSideSheet
        isOpen={editingCustomVariable !== null}
        handleClose={() => toggleEditingCustomVariable(null)}
        handleSave={handleSaveCustomVariable}
        isLoading={isLoading}
        value={
          editingCustomVariable && personData.custom_variables
            ? (personData.custom_variables[
                editingCustomVariable.name
              ] as string)
            : ''
        }
        variable={editingCustomVariable as TVariable}
      />
    </BoxSection>
  );
};

export default NaturalPersonViewCustomVariablesTab;
