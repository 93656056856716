import {
  CustomInput,
  CalendarInput,
  CustomCurrencyInput,
  CustomSelect,
} from 'app/components';
import { useFormik } from 'formik';
import { useCallback, useContext } from 'react';
import { formatCPF, formatPhone } from '../../../../../../app/helpers';
import { formatDateFromBackend } from '../../../../../../app/helpers/dateHelpers';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TRegisterPersonDataRequest } from '../../../../context';
import { aboutStepSchema } from '../../../../helpers/personValidation';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import {
  EMaritalStatus,
  ESex,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { TReactChangeInput } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import {
  MaritialPropertyObject,
  MaritialStatusObject,
  SexObject,
} from 'modules/customer/context/PersonProvider/person.constants';

export interface INaturalPersonEditAboutSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const NaturalPersonEditAboutSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditAboutSection) => {
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const formik = useFormik({
    initialValues: {
      taxpayer_id: personData.taxpayer_id || '',
      full_name: personData.full_name || '',
      sex: personData.sex
        ? {
            label: SexObject[personData.sex],
            value: personData.sex,
          }
        : {
            label: '',
            value: undefined,
          },
      nationality: personData.nationality || '',
      occupation: personData.occupation || '',
      mothers_name: personData.mothers_name || '',
      birthplace: personData.birthplace || '',
      monthly_income:
        formatCurrency(personData.monthly_income, false) || '0,00',
      phone: formatPhone(
        personData.phone.area_code + personData.phone.number || '',
      ),
      email_address: personData.email_address || '',
      marital_status: personData.marital_status
        ? {
            label: MaritialStatusObject[personData.marital_status],
            value: personData.marital_status,
          }
        : {
            label: '',
            value: undefined,
          },
      marital_property_system: personData.marital_property_system
        ? {
            label: MaritialPropertyObject[personData.marital_property_system],
            value: personData.marital_property_system,
          }
        : {
            label: '',
            value: undefined,
          },
      pep: personData.pep
        ? {
            label: 'Sim',
            value: 'true',
          }
        : {
            label: 'Não',
            value: 'false',
          },
      birth_date: personData.birth_date
        ? formatDateFromBackend(personData.birth_date).toISOString()
        : '',
    },
    validationSchema: aboutStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newPersonData: TRegisterPersonDataRequest = {
        ...personData,
        taxpayer_id: values.taxpayer_id.replace(/\D+/g, ''),
        full_name: values.full_name.trim(),
        nationality: values.nationality.trim(),
        occupation: values.occupation.trim(),
        phone: {
          ...personData.phone,
          area_code: values.phone?.replace(/\D+/g, '').slice(0, 2),
          number: values?.phone?.replace(/\D+/g, '').slice(2),
        },
        email_address: values.email_address.trim(),
        birth_date: new Date(values.birth_date).toISOString(),
        monthly_income: Number(formatToCurrencyFloat(values.monthly_income)),
        pep: values.pep.value === 'true',
        birthplace: values.birthplace.trim(),
        marital_property_system: values.marital_property_system.value,
        mothers_name:
          values.mothers_name.length > 0
            ? values.mothers_name.trim()
            : undefined,
        marital_status: values.marital_status.value,
        sex: values.sex.value,
        ...(values.marital_status?.value !== EMaritalStatus.MARRIED && {
          spouse_full_name: undefined,
          spouse_taxpayer_id: undefined,
          spouse_date_of_birth: undefined,
          marital_property_system: undefined,
        }),
      };
      updatePerson(newPersonData).then(() => {
        onClose();
      });
    },
  });

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_income',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      title="Sobre"
    >
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomInput
            name="full_name"
            value={formik.values.full_name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Empresa S.A."
            labelValue="Nome completo"
            validationError={
              formik.touched.full_name ? formik.errors.full_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            disabled
            name="taxpayer_id"
            value={formatCPF(formik.values.taxpayer_id)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="00.000.000-00"
            labelValue="CPF"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            name="birth_date"
            labelValue="Data de nascimento"
            placeholder="dd/mm/aaaa"
            value={formik.values.birth_date}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'birth_date',
                },
              })
            }
            validationError={
              formik.touched.birth_date ? formik.errors.birth_date : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="birthplace"
            value={formik.values.birthplace}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: São Paulo"
            labelValue="Naturalidade"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="nationality"
            value={formik.values.nationality}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Brasileiro"
            labelValue="Nacionalidade"
            validationError={
              formik.touched.nationality ? formik.errors.nationality : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Sexo"
            name="sex"
            placeholder="Selecione"
            value={formik.values.sex}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              { value: ESex.MALE, label: 'Masculino' },
              { value: ESex.FEMALE, label: 'Feminino' },
              { value: ESex.OTHER, label: 'Não informado' },
            ]}
            validationError={
              formik.touched.marital_status?.value
                ? formik.errors.marital_status?.value
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="occupation"
            value={formik.values.occupation}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Vendedor"
            labelValue="Ocupação"
            validationError={
              formik.touched.occupation ? formik.errors.occupation : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values.phone)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Estado civil"
            name="marital_status"
            placeholder="Selecione"
            value={formik.values.marital_status}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              { value: EMaritalStatus.MARRIED, label: 'Casado(a)' },
              { value: EMaritalStatus.WIDOWED, label: 'Viúvo(a)' },
              { value: EMaritalStatus.SEPARATED, label: 'Separado(a)' },
              { value: EMaritalStatus.DIVORCED, label: 'Divorciado(a)' },
              { value: EMaritalStatus.SINGLE, label: 'Solteiro(a)' },
              { value: EMaritalStatus.OTHER, label: 'Outro' },
            ]}
            validationError={
              formik.touched.marital_status?.value
                ? formik.errors.marital_status?.value
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="email_address"
            value={formik.values.email_address}
            handleInputChange={formik.handleChange}
            placeholder="exemplo@email.com"
            labelValue="E-mail"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="mothers_name"
            value={formik.values.mothers_name}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Maria da Silva"
            labelValue="Nome da mãe"
            validationError={
              formik.touched.mothers_name ? formik.errors.mothers_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Renda mensal"
            value={formik.values.monthly_income}
            handleInputChange={handleMonthlyIncomeChange}
            name="monthly_income"
            validationError={
              formik.touched.monthly_income ? formik.errors.monthly_income : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Pessoa politicamente exposta (PEP)"
            name="pep"
            placeholder="Selecione"
            value={formik.values.pep}
            handleInputChange={formik.handleChange}
            options={[
              { value: 'false', label: 'Não' },
              { value: 'true', label: 'Sim' },
            ]}
            validationError={
              formik.touched.marital_status?.value
                ? formik.errors.marital_status?.value
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditAboutSection;
