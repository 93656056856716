import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TScrDetailItem } from 'modules/customer/interfaces/scr';
import { Table, TextL, TextS } from 'app/components';
import { formatCurrency } from 'app/utils/normalizer';

export interface INaturalPersonViewScrDetailsSideSheet {
  itemDetail: TScrDetailItem | null;
  handleClose: () => void;
  isOpen: boolean;
}

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Descrição</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const NaturalPersonViewScrDetailsSideSheet = ({
  itemDetail,
  handleClose,
  isOpen,
}: INaturalPersonViewScrDetailsSideSheet) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      isCloseButtonDisabled={true}
      showBackdrop={true}
      title="Vencimentos"
    >
      {itemDetail && (
        <Table
          tableHeadCell={tableHeader}
          maxBodyHeight="87vh"
          tableBodyContent={itemDetail.vencimentos.map((item, index) => {
            return {
              id: `${item.categoria}-${String(index)}`,
              cells: [
                {
                  id: '1',
                  content: (
                    <TableBodyCellContainer>
                      <TextS>{item.descricao ?? '-'}</TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '2',
                  content: (
                    <TableBodyCellContainer>
                      <TextS>
                        {itemDetail.valor
                          ? formatCurrency(parseFloat(item.valor))
                          : '-'}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
              ],
            };
          })}
        />
      )}
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonViewScrDetailsSideSheet;
