import { DrawerContentArea, SideMenuDrawerContainer } from './styles';
import { Drawer, DrawerProps, useMediaQuery, useTheme } from '@mui/material';

export interface ISideMenuDrawerProps extends DrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  collapsed?: boolean;
  hideOnSmallScreen?: boolean;
}

const SideMenuDrawer = ({
  isOpen,
  collapsed,
  hideOnSmallScreen = false,
  handleClose,
  children,
}: ISideMenuDrawerProps) => {
  const theme = useTheme();
  const smWindowSize = useMediaQuery(theme.breakpoints.down('laptop'));

  return (
    <SideMenuDrawerContainer
      isCollapsed={collapsed}
      hideOnSmallScreen={smWindowSize && hideOnSmallScreen}
    >
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => handleClose()}
        variant={smWindowSize && hideOnSmallScreen ? 'temporary' : 'permanent'}
        hideBackdrop={smWindowSize ? false : true}
        elevation={1}
        sx={{ width: '100%' }}
      >
        <DrawerContentArea widthContent={'100%'}>{children}</DrawerContentArea>
      </Drawer>
    </SideMenuDrawerContainer>
  );
};

export default SideMenuDrawer;
