import { useState, useEffect, useCallback } from 'react';
import { useCustomVariables } from 'app/hooks';
import {
  EVariableType,
  TVariable,
  VariableTypeNames,
} from 'app/services/variablesHttpService';
import {
  BoxSection,
  ButtonDefault,
  TextL,
  TextS,
} from '../../../../../../app/components';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Popover, useTheme } from '@mui/material';
import {
  ApplicationCustomVariablesTabPopOver,
  ApplicationCustomVariablesTableWrapper,
} from './styles';
import { CustomVariableEditSideSheet } from 'modules/customer/components';
import { Heading3, Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { TableArea } from '../ApplicationScheduleTab/styles';
import { EmptyArea } from 'modules/management/components';

type IApplicationCustomVariablesTabProps = {
  applicationData: TApplicationResponse;
  updateApplicationData: (newApplicationValue: TApplicationResponse) => void;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Variáveis adicionadas</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Detalhes</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ minWidth: '100px' }}>
          Valor
        </TextL>
      </TableHeadCellContainer>
    ),
  },
];

const ApplicationCustomVariablesTab = ({
  applicationData,
  updateApplicationData,
}: IApplicationCustomVariablesTabProps) => {
  const theme = useTheme();
  const [variables, updateVariables] = useState<TVariable[]>();
  const [editingCustomVariable, toggleEditingCustomVariable] =
    useState<TVariable | null>(null);
  const { getCustomVariablesForApplication, isCustomVariablesLoading } =
    useCustomVariables();

  useEffect(() => {
    getCustomVariablesForApplication().then(
      (vars) => vars && updateVariables(() => vars),
    );
  }, []);

  const handleSaveCustomVariable = useCallback(
    (value: string | undefined) => {
      const application: TApplicationResponse = {
        ...applicationData,
        custom_variables: {
          ...applicationData.custom_variables,
          [editingCustomVariable?.name as string]: value,
        },
      };
      updateApplicationData(application);
      setTimeout(() => toggleEditingCustomVariable(null));
    },
    [editingCustomVariable, applicationData],
  );

  const getFormatedValue = useCallback(
    (variable: TVariable): string | null => {
      if (!applicationData.custom_variables) return null;
      if (
        variable.type === EVariableType.DATE &&
        typeof applicationData.custom_variables[variable.name] === 'string'
      )
        return formatDateFromBackend(
          applicationData.custom_variables[variable.name] as string,
        ).toLocaleDateString('pt-BR');
      if (variable.type === EVariableType.BOOLEAN) {
        if (applicationData.custom_variables[variable.name] === 'true')
          return 'Sim';
        if (applicationData.custom_variables[variable.name] === 'false')
          return 'Não';
        return '';
      }
      return applicationData.custom_variables[variable.name] as string;
    },
    [applicationData],
  );
  return (
    <BoxSection>
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Campos adicionais
      </Heading3>
      {variables && variables.length > 0 ? (
        <TableArea>
          <ApplicationCustomVariablesTableWrapper>
            <Table
              tableHeadCell={tableHeader}
              tableBodyContent={variables.map((variable, index) => {
                return {
                  id: variable?.id || String(index),
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextS weight="Bold">{variable.display_name}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <PopupState
                            variant="popover"
                            popupId={`${variable.display_name}-popup`}
                          >
                            {(popupState) => (
                              <>
                                <ButtonDefault
                                  {...bindTrigger(popupState)}
                                  style={{
                                    border: 'none',
                                    fontWeight: 'normal',
                                    background: 'transparent',
                                    textDecoration: 'underline',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                  }}
                                >
                                  Ver descrição
                                </ButtonDefault>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <ApplicationCustomVariablesTabPopOver>
                                    <TextS>{variable.description}</TextS>
                                  </ApplicationCustomVariablesTabPopOver>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>{VariableTypeNames[variable.type]}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <TextS style={{ minWidth: '100px' }}>
                            {applicationData.custom_variables &&
                            applicationData.custom_variables[variable.name] ? (
                              getFormatedValue(variable)
                            ) : (
                              <em>Vazio</em>
                            )}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })}
            />
          </ApplicationCustomVariablesTableWrapper>
        </TableArea>
      ) : (
        <EmptyArea
          message="Nenhum campo adicionado"
          isLoading={isCustomVariablesLoading}
        >
          {null}
        </EmptyArea>
      )}
      <CustomVariableEditSideSheet
        isOpen={editingCustomVariable !== null}
        handleClose={() => toggleEditingCustomVariable(null)}
        handleSave={handleSaveCustomVariable}
        isLoading={false}
        value={
          editingCustomVariable && applicationData.custom_variables
            ? (applicationData.custom_variables[
                editingCustomVariable.name
              ] as string)
            : ''
        }
        variable={editingCustomVariable as TVariable}
      />
    </BoxSection>
  );
};

export default ApplicationCustomVariablesTab;
