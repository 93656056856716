import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CloseIcon, Heading4, TextL } from 'app/components';
import {
  ModalBody,
  ModalHeader,
  Cnab444GenerateMessageModalContainer,
} from './styles';
import { BaseModal } from 'app/components';

export interface ICnab444GenerateMessageModal {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

const Cnab444GenerateMessageModal = ({
  isOpen,
  handleClose,
  title,
  icon,
  children,
}: ICnab444GenerateMessageModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <Cnab444GenerateMessageModalContainer>
        <ModalHeader>
          <TextL>Geração de CNAB 444</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          {icon}
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              marginTop: '24px',
              textAlign: 'center',
            }}
            fontWeight="B"
          >
            {title}
          </Heading4>
          <TextL
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
            }}
          >
            {children}
          </TextL>
        </ModalBody>
      </Cnab444GenerateMessageModalContainer>
    </BaseModal>
  );
};

export default Cnab444GenerateMessageModal;
