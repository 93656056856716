import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
  StepContent,
} from 'app/components';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import { InfoArea, StepContentButtonsArea } from './styles';
import { ArrowBack } from '@mui/icons-material';
import { Divider, useTheme } from '@mui/material';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { useQualificationsService } from 'modules/products/services/hooks';
import { ListBox, ListBoxItem } from '../../ApplicationRequest/components';
import { TextL, TextM, TextS } from 'app/components';
import { formatCNPJ, formatCPF, formatPhone } from 'app/helpers';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { QualificationModalContent } from '../../ApplicationRequest/BorrowerStep/styles';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

const filterItemsPerson = [
  { name: 'Nome', parameterName: 'full_name' },
  { name: 'CPF', parameterName: 'taxpayer_id' },
  { name: 'Email', parameterName: 'email_address' },
];

const filterItemsBusiness = [
  { name: 'Nome', parameterName: 'legal_name' },
  { name: 'CNPJ', parameterName: 'taxpayer_id' },
  { name: 'Email', parameterName: 'email_address' },
];

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const BorrowerStep = () => {
  const {
    onForward,
    onBack,
    onChangeBorrowerData,
    borrowerType,
    borrowerData,
    productData,
    isPerson,
    isBusiness,
  } = useContext(QualificationRequestStepperContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const [isQualificationAlertModalOpen, toggleQualificationModalAlertOpen] =
    useState<TQualification | null>(null);
  const [hasMoreItems, updateHasMoreItems] = useState<boolean>(false);
  const { getPersonList, personListLoading } = usePersonService();
  const { getBusinessList, creatBusinessLoading } = useCustomerService();
  const { getBorrowerQualification } = useQualificationsService();
  const [personBorrowerList, setPersonBorrowerList] = useState<
    TRegisterPersonDataRequest[]
  >([]);
  const [businessBorrowerList, setBusinessBorrowerList] = useState<
    TRegisterBusinessDataRequest[]
  >([]);

  useEffect(() => {
    if (borrowerData) {
      if (
        productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.BORROWER
      ) {
        setPersonBorrowerList([borrowerData]);
        return;
      }
      setBusinessBorrowerList([borrowerData]);
      return;
    }
  }, []);

  const onForwardRequest = useCallback(() => {
    if (productData && borrowerData) {
      getBorrowerQualification(productData.id, borrowerData.id as string).then(
        (qualificationData) => {
          const qualification = qualificationData?.content?.[0];
          if (qualification && qualification.status === 'QUALIFIED') {
            toggleQualificationModalAlertOpen(qualification as TQualification);
            return;
          }
          onForward();
        },
      );
    }
  }, [borrowerData, productData, borrowerType, onForward]);

  //called by debounce on first render
  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setPersonBorrowerList([]);
    setBusinessBorrowerList([]);

    if (
      productData?.borrower_type === 'PERSON' &&
      borrowerType === EFunctions.BORROWER
    ) {
      if (field === '') {
        handleGetPerson(0);
        return;
      }
      handleGetPerson(0, field, value);
      return;
    }

    if (field === '') {
      handleGetBusiness(0);
      return;
    }
    handleGetBusiness(0, field, value);
  };

  const handleGetPerson = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getPersonList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      updateHasMoreItems(!!res?.has_next);
      setPersonBorrowerList((state) =>
        state.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  const handleGetBusiness = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getBusinessList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      updateHasMoreItems(!!res?.has_next);
      setBusinessBorrowerList((state) =>
        state.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  return (
    <StepContent
      title={isBusiness ? 'Empresas' : isPerson ? 'Pessoas' : 'Tomador'}
      description={
        isBusiness
          ? 'Selecione a empresa para a linha de crédito'
          : isPerson
          ? 'Selecione a pessoa para a linha de crédito'
          : 'Selecione o tomador para a linha de crédito'
      }
    >
      <ListBox
        hasMoreItems={hasMoreItems}
        filterItems={
          productData?.borrower_type === 'PERSON' &&
          borrowerType === EFunctions.BORROWER
            ? filterItemsPerson
            : filterItemsBusiness
        }
        items={
          personBorrowerList.length > 0
            ? personBorrowerList
            : businessBorrowerList
        }
        onSearch={onSearch}
        isLoading={isLoading || personListLoading || creatBusinessLoading}
        handleRequest={
          productData?.borrower_type === 'PERSON' &&
          borrowerType === EFunctions.BORROWER
            ? () =>
                handleGetPerson(
                  currentPage.current + 1,
                  searchParameter.current ? searchParameter.current : undefined,
                  searchValue.current ? searchValue.current : undefined,
                )
            : () =>
                handleGetBusiness(
                  currentPage.current + 1,
                  searchParameter.current ? searchParameter.current : undefined,
                  searchValue.current ? searchValue.current : undefined,
                )
        }
      >
        {(i) => {
          const item = i as TRegisterBusinessDataRequest &
            TRegisterPersonDataRequest;
          return (
            <ListBoxItem
              className={borrowerData?.id === item.id ? 'selected' : ''}
              onClick={() => onChangeBorrowerData(item)}
              key={item.taxpayer_id}
            >
              <TextL
                style={{
                  color:
                    borrowerData?.id === item.taxpayer_id
                      ? theme.palette.brand.primary.base
                      : theme.palette.brand.secondary.base,
                }}
                weight="semibold"
              >
                {productData?.borrower_type === 'PERSON' &&
                borrowerType === EFunctions.BORROWER
                  ? item.full_name
                  : item.legal_name}
              </TextL>
              <InfoArea>
                <TextS>
                  {productData?.borrower_type === 'PERSON' &&
                  borrowerType === EFunctions.BORROWER
                    ? formatCPF(item.taxpayer_id || '')
                    : formatCNPJ(item.taxpayer_id)}
                </TextS>
                <BorrowerInfoDivider />
                <TextS>
                  {formatPhone(item.phone.area_code + item.phone.number || '')}
                </TextS>
                <BorrowerInfoDivider />
                <TextS>{item.email_address}</TextS>
              </InfoArea>
            </ListBoxItem>
          );
        }}
      </ListBox>
      <StepContentButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary disabled={!borrowerData} onClick={onForwardRequest}>
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isQualificationAlertModalOpen !== null}
        icon={<AttentionCircularIcon />}
        title={`${
          productData?.borrower_type === 'PERSON' &&
          borrowerType === EFunctions.BORROWER
            ? borrowerData?.full_name
            : borrowerData?.legal_name
        } já possui uma linha de crédito.`}
        btnConfirmText="Limpar e selecionar outro tomador"
        handleConfirm={() => {
          toggleQualificationModalAlertOpen(null);
          onChangeBorrowerData(null);
        }}
        handleClose={() => toggleQualificationModalAlertOpen(null)}
        handleCancel={() => toggleQualificationModalAlertOpen(null)}
      >
        <QualificationModalContent>
          <TextM>
            {`O tomador selecionado já possui uma linha de crédito para o produto `}
            <strong>{productData?.name}</strong>
          </TextM>
          <a
            href={
              productData?.borrower_type === 'PERSON' &&
              borrowerType === EFunctions.BORROWER
                ? `#/records/natural/${borrowerData?.id}/qualifications`
                : `#/records/legal/${borrowerData?.id}/qualifications`
            }
            target="_blank"
            style={{
              textDecoration: 'underline',
              marginTop: '20px',
              textAlign: 'right',
            }}
            className="link"
            rel="noreferrer"
          >
            Ir para o cadastro
          </a>
        </QualificationModalContent>
      </ConfirmModal>
    </StepContent>
  );
};

export default BorrowerStep;
