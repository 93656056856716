import { useTheme } from '@mui/material';
import { CloseIcon, Spinner, Heading4, TextL } from 'app/components';
import { BaseModal } from 'app/components';
import {
  ModalBody,
  ModalHeader,
  Cnab444GenerateLoadingModalContainer,
} from './styles';

export interface ICnab444GenerateLoadingModal {
  isOpen: boolean;
  hideMessage?: boolean;
  handleClose: () => void;
}

const Cnab444GenerateLoadingModal = ({
  isOpen,
  hideMessage,
  handleClose,
}: ICnab444GenerateLoadingModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <Cnab444GenerateLoadingModalContainer>
        <ModalHeader>
          <TextL>Geração de CNAB 444</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          <Spinner />
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              marginTop: '24px',
            }}
            fontWeight="B"
          >
            Carregando
          </Heading4>
          {!hideMessage && (
            <TextL
              style={{
                color: theme.palette.brand.secondary.base,
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              Arquivos grandes podem demorou um pouco. Em caso de problemas,
              você pode tentar novamente.
            </TextL>
          )}
        </ModalBody>
      </Cnab444GenerateLoadingModalContainer>
    </BaseModal>
  );
};

export default Cnab444GenerateLoadingModal;
