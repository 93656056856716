import { CustomInput } from 'app/components/CustomInput';
import { useState } from 'react';
import {
  ButtonPrimary,
  Heading3,
  RegularText,
} from '../../../../../../app/components';
import { AuthFormContainer } from '../../../../components';
import {
  ButtonArea,
  HeadTextArea,
  InputArea,
  InputItem,
  SubtitleArea,
} from './styles';

const TwoFactorAuthForm = () => {
  const [twoFactorCode, setTwoFactorCode] = useState('');

  return (
    <AuthFormContainer>
      <HeadTextArea>
        <Heading3 fontWeight="B">Autenticação 2 fatores</Heading3>
      </HeadTextArea>
      <SubtitleArea>
        <RegularText>Insira o código de 2 fatores</RegularText>
      </SubtitleArea>
      <InputArea>
        <InputItem>
          <CustomInput
            value={twoFactorCode}
            handleInputChange={(e) => setTwoFactorCode(e.target.value)}
            placeholder="Digite o código"
          />
        </InputItem>
        <ButtonArea>
          <ButtonPrimary onClick={() => console.log('Login')}>
            ENTRAR
          </ButtonPrimary>
        </ButtonArea>
      </InputArea>
    </AuthFormContainer>
  );
};

export default TwoFactorAuthForm;
