import React, { useState, useCallback, useEffect } from 'react';
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { useTheme } from '@mui/material';
import {
  UsersAccessListContentButtonArea,
  UsersAccessListContentFilterArea,
  UsersAccessListContentPermissionCount,
  UsersAccessListContentPermissionDetails,
  UsersAccessListContentTableArea,
  UsersAccessListContentWrapper,
} from './styles';
import SearchFilter, {
  TFilterItems,
} from 'app/components/SearchFilter/SearchFilter';
import { Badge, ButtonPrimary } from 'app/components';
import { AddIcon, DetailsIcon, EmptyAccessImage } from 'app/components/Icons';
import { Table, TextL, TextM, TextS } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { formatStringHourFromBackend } from 'app/helpers/dateHelpers';
import {
  UserPermissionsDescription,
  UserAccess,
  UserValidPermissions,
} from 'modules/management/interfaces/usersAccess';
import { EmptyArea } from 'modules/management/components';
import { useNavigate } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { useAccessService } from 'modules/management/services/useAccessService';
import { TBadgeVariant } from 'app/components/Badge/Badge';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const USER_STATUS = {
  PENDING: {
    color: 'info',
    text: 'PENDENTE',
  },
  ACTIVE: {
    color: 'success',
    text: 'ATIVO',
  },
  INACTIVE: {
    color: 'attention',
    text: 'DESATIVADO',
  },
};

const filterItems: TFilterItems[] = [
  {
    name: 'Nome',
    parameterName: 'full_name',
  },
  {
    name: 'CPF',
    parameterName: 'taxpayer_id',
  },
  {
    name: 'Email',
    parameterName: 'email',
  },
];

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data da criação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold" style={{ paddingLeft: '10px' }}>
          Status
        </TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">E-mail</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Permissões</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

const UsersAccessListContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isGetUserListLoading, getUserAccessList } = useAccessService();
  const [usersAccessList, setUsersAccessList] = useState<UserAccess[]>([]);
  const [permissionDetailsOpenItem, setPermissionDetailsOpenItem] =
    useState<UserAccess | null>(null);
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  );
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );

  const getUsersAccessListContent = useCallback(
    (clean?: boolean) => {
      getUserAccessList().then((list) => {
        const filteredUsers = list.filter(
          (item) =>
            clean ||
            !selectedFilter ||
            !searchInputValue ||
            String(item[selectedFilter.parameterName as keyof UserAccess] || '')
              .toLowerCase()
              .includes(searchInputValue.toLowerCase()),
        );

        setTotalItemsQty(filteredUsers.length);
        setUsersAccessList(filteredUsers);
      });
    },
    [searchInputValue, selectedFilter],
  );

  const onCreateClick = useCallback(() => {
    navigate(routingPath.management.accessNew);
  }, [navigate]);

  const handleOpenAccessDetail = useCallback((userAccess: UserAccess) => {
    navigate(
      `/${routingPath.management.accessView
        .replace(':id', userAccess.id)
        .replace(':tabRoute?', 'details')}`,
    );
  }, []);

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    setTimeout(() => getUsersAccessListContent());
  };

  useEffect(() => {
    getUsersAccessListContent();
  }, []);

  return (
    <>
      <UsersAccessListContentWrapper>
        <UsersAccessListContentFilterArea>
          <SearchFilter
            inputValue={searchInputValue}
            handleClearInput={() => {
              setCurrentPage(0);
              setLinesPerPage(10);
              setSearchInputValue(() => '');
              setTimeout(() => getUsersAccessListContent(true));
            }}
            filterItems={filterItems}
            handleSelectFilterItem={(selectedFilterValue) => {
              setSelectedFilter(() => selectedFilterValue);
            }}
            handleInputChange={(evt) =>
              setSearchInputValue(() => evt.currentTarget.value)
            }
            selectedFilter={selectedFilter?.name}
            handleSearch={handleSearch}
          />
          <UsersAccessListContentButtonArea>
            <ButtonPrimary
              style={{ maxWidth: '202px' }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={onCreateClick}
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Criar usuário
              </TextM>
            </ButtonPrimary>
          </UsersAccessListContentButtonArea>
        </UsersAccessListContentFilterArea>
        {usersAccessList.length === 0 || isGetUserListLoading ? (
          <EmptyArea
            isLoading={isGetUserListLoading}
            message="Nenhum usuário encontrado"
            createButtonText="Criar usuário"
            onCreateClick={onCreateClick}
          >
            <EmptyAccessImage />
          </EmptyArea>
        ) : (
          <UsersAccessListContentTableArea>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeadContent}
              tableBodyContent={usersAccessList
                ?.slice(
                  currentPage * linesPerPage,
                  currentPage * linesPerPage + linesPerPage,
                )
                .map((item) => {
                  const permissions = item.permissions.filter((permission) =>
                    UserValidPermissions.includes(permission),
                  );
                  return {
                    id: '1',
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.created_at === null
                                ? 'Não informado'
                                : `${new Date(
                                    item?.created_at || '',
                                  ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                item?.created_at || '',
                              )}`}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <Badge
                              style={{
                                borderColor: 'transparent',
                              }}
                              typeVariant={
                                USER_STATUS[item.status].color as TBadgeVariant
                              }
                            >
                              {USER_STATUS[item.status].text}
                            </Badge>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{item.full_name}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{item.email}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              position: 'relative',
                            }}
                          >
                            <UsersAccessListContentPermissionCount
                              onClick={() =>
                                permissions.length > 0 &&
                                setPermissionDetailsOpenItem(item)
                              }
                            >
                              {permissions.length}
                            </UsersAccessListContentPermissionCount>
                            {permissionDetailsOpenItem?.id === item.id &&
                              permissions.length > 0 && (
                                <UsersAccessListContentPermissionDetails
                                  onClick={() =>
                                    setPermissionDetailsOpenItem(null)
                                  }
                                >
                                  <ul>
                                    {permissions
                                      .map(
                                        (permission: EAccountPermissions) => {
                                          if (
                                            Object.keys(
                                              UserPermissionsDescription,
                                            ).includes(permission)
                                          ) {
                                            return (
                                              <li key={permission}>
                                                {
                                                  UserPermissionsDescription[
                                                    permission as keyof typeof UserPermissionsDescription
                                                  ].title
                                                }
                                              </li>
                                            );
                                          }
                                          return null;
                                        },
                                      )
                                      .filter((i) => i !== null)}
                                  </ul>
                                </UsersAccessListContentPermissionDetails>
                              )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '0px',
                            }}
                          >
                            <ButtonPrimary
                              typeVariant="outline"
                              style={{ width: 'fit-content' }}
                              onClick={() => handleOpenAccessDetail(item)}
                            >
                              <DetailsIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
            />
          </UsersAccessListContentTableArea>
        )}
      </UsersAccessListContentWrapper>
    </>
  );
};

export default UsersAccessListContent;
