import {
  BaseModal,
  CustomInput,
  Heading3,
  Spinner,
  TextL,
  TextM,
} from 'app/components';
import { Container } from './styles';
import { ButtonPrimary, CloseIcon } from 'app/components';
import { useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { WarningRounded } from '@mui/icons-material';
import * as Yup from 'yup';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { useAuthContext } from 'modules/auth/context';

export interface IDownloadFileModal {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (email: string) => void;
  loading: boolean;
  dateFrom: string;
  dateTo: string;
}

const DownloadReportModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  loading,
  dateFrom,
  dateTo,
}: IDownloadFileModal) => {
  const { user } = useAuthContext();
  const theme = useTheme();
  const [email, setEmail] = useState(user?.getUsername().split(':')[1]);

  const formik = useFormik({
    validationSchema: Yup.object({
      email: Yup.string().email('Email inválido').required('Campo obrigatório'),
    }),
    initialValues: {
      email: email,
    },
    onSubmit: (values) => {
      setEmail(values.email);
      handleSubmit(values.email || '');
    },
  });

  const onCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <BaseModal open={isOpen} onClose={onCloseModal}>
      <Container>
        <div className="header">
          <Heading3>Exportação de relatório</Heading3>
          <div className="close-btn" onClick={onCloseModal}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body">
          {loading && (
            <div className="loading">
              <div>
                <Spinner />
              </div>
              <TextL>
                Enviando solicitação de relatório para o email informado...
              </TextL>
            </div>
          )}
          {!loading && (
            <>
              <TextL>
                O Relatório de Conciliação será processado e enviado para o
                email informado. Indique um email válido para receber as
                instruções para download do arquivo.
              </TextL>

              <div className="input">
                <CustomInput
                  labelValue="Email"
                  value={formik.values.email || ''}
                  handleInputChange={formik.handleChange}
                  name="email"
                  validationError={
                    formik.touched.email ? formik.errors.email : ''
                  }
                />
              </div>

              <div className="warning">
                <WarningRounded
                  style={{
                    color: theme.palette.brand.primary.base,
                    alignSelf: 'center',
                  }}
                />
                <TextL weight="medium">
                  Verifique as colunas selecionadas e os filtros aplicados, eles
                  serão considerados para a criação do relatório
                </TextL>
              </div>

              <div className="dates">
                <TextM weight="Medium">
                  Data Inicial:{' '}
                  {formatDateFromBackend(dateFrom).toLocaleDateString('pt-br')}
                </TextM>
                <TextM weight="Medium">
                  Data Final:{' '}
                  {formatDateFromBackend(dateTo).toLocaleDateString('pt-br')}
                </TextM>
              </div>

              <div className="btn">
                <ButtonPrimary onClick={formik.submitForm}>
                  <TextM weight="Semibold" style={{ color: '#fff' }}>
                    Emitir Relatório
                  </TextM>
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      </Container>
    </BaseModal>
  );
};

export default DownloadReportModal;
