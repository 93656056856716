import React from 'react';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { Bullets, ConnectorBullet, StepperContainer } from './styles';

export type StepItem = {
  step: number;
  title: string;
  subtitle?: string;
  handleClick?: () => void;
  clickable?: boolean;
};

export interface IStepper {
  activeStep: number;
  stepsItems: StepItem[];
}

export const renderBullets = () => {
  const size = new Array(5).fill({});
  return (
    <>
      {size.map((__, index) => (
        <Bullets key={index} />
      ))}
    </>
  );
};

const renderConnector = () => {
  const size = new Array(6).fill({});
  return (
    <>
      {size.map((__, index) => (
        <ConnectorBullet key={index} />
      ))}
    </>
  );
};

const Stepper = ({ activeStep = 0, stepsItems }: IStepper) => {
  const handleClick = (step: StepItem) => {
    if (!step.clickable) return;
    if (typeof step.handleClick === 'function') step?.handleClick();
  };

  return (
    <StepperContainer>
      {renderBullets()}
      <MuiStepper
        activeStep={activeStep}
        orientation="vertical"
        connector={renderConnector()}
      >
        {stepsItems.length > 0 &&
          stepsItems.map((item) => {
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {};

            labelProps.optional = item?.subtitle;

            return (
              <Step key={item.step} onClick={() => handleClick(item)}>
                <StepLabel
                  className={activeStep === item.step ? 'current' : 'outmoded'}
                  {...labelProps}
                >
                  {item.title}
                </StepLabel>
              </Step>
            );
          })}
      </MuiStepper>
    </StepperContainer>
  );
};

export default Stepper;
