import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
} from 'app/components';
import { Divider } from '@mui/material';
import { useBeneficiaryService } from 'modules/products/services/hooks';
import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem, ListBoxItemTitle } from '../components';
import { StepContentButtonsArea } from '../styles';
import { BeneficiaryAccountStepContent } from './styles';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { normalizeCpfCnpj } from 'app/utils/normalizer';
import { ArrowBack } from '@mui/icons-material';
import { TextS } from 'app/components';
import { InfoArea } from '../BorrowerStep/styles';

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const BeneficiaryAccountStep = () => {
  const { getBeneficiaryAccounts, getBeneficiaryAccountsLoading } =
    useBeneficiaryService();
  const [hasNext, updateHasNext] = useState(false);
  const currentPage = useRef<number>(0);
  const [searchText, updateSearchText] = useState('');
  const [beneficiaryAccountList, setBeneficiaryAccountList] = useState<
    TBeneficiaryAccount[]
  >([]);
  const { beneficiaryAccount, updateBeneficiaryAccount, onForward, onBack } =
    useContext(ApplicationRequestContext);
  const [selectedBeneficiaryAccount, setSelectedBeneficiaryAccount] =
    useState<TBeneficiaryAccount | null>(null);

  useEffect(() => {
    handleGetBeneficiaryAccounts(0);
  }, []);

  useEffect(() => {
    if (
      !selectedBeneficiaryAccount ||
      (beneficiaryAccount &&
        beneficiaryAccount.id !== selectedBeneficiaryAccount.id)
    ) {
      setSelectedBeneficiaryAccount(beneficiaryAccount);
    }
  }, [beneficiaryAccount]);

  const handleGetBeneficiaryAccounts = useCallback(
    async (page: number) => {
      const list = await getBeneficiaryAccounts(page);
      updateHasNext(!!list?.has_next);
      setBeneficiaryAccountList((state) =>
        state.concat(
          list?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      const defaultAccount = list?.content.find(
        (item) => item.default_account && item.enabled,
      );
      if (defaultAccount && !selectedBeneficiaryAccount) {
        setSelectedBeneficiaryAccount(() => defaultAccount);
      }
      currentPage.current = page;
    },
    [currentPage, selectedBeneficiaryAccount],
  );

  return (
    <BoxSection boxVariant="rounded">
      <BeneficiaryAccountStepContent>
        <Heading3>
          Qual a <strong>Conta Beneficiária </strong>
          dessa solicitação?
        </Heading3>
        <ListBox
          filterItems={[{ name: 'Nome', parameterName: 'holder' }]}
          hasMoreItems={hasNext}
          items={beneficiaryAccountList
            .filter((item) => item.enabled)
            .filter(
              (item) =>
                !searchText ||
                item.description
                  .toLowerCase()
                  .includes(searchText.toLowerCase()),
            )}
          isLoading={getBeneficiaryAccountsLoading}
          onSearch={(field, value) => updateSearchText(value)}
          handleRequest={() =>
            handleGetBeneficiaryAccounts(currentPage.current + 1)
          }
        >
          {(i) => {
            const item = i as TBeneficiaryAccount;
            return (
              <ListBoxItem
                className={
                  selectedBeneficiaryAccount?.id === item.id ? 'selected' : ''
                }
                onClick={() => setSelectedBeneficiaryAccount(item)}
                key={item.holder.taxpayer_id}
              >
                <ListBoxItemTitle>{item.description}</ListBoxItemTitle>
                <InfoArea>
                  <TextS>{item.holder.name}</TextS>
                  <BorrowerInfoDivider />
                  <TextS>{normalizeCpfCnpj(item.holder.taxpayer_id)}</TextS>
                </InfoArea>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary
            disabled={!selectedBeneficiaryAccount}
            onClick={() => {
              if (selectedBeneficiaryAccount) {
                updateBeneficiaryAccount(selectedBeneficiaryAccount);
                setTimeout(() => onForward());
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </BeneficiaryAccountStepContent>
    </BoxSection>
  );
};

export default BeneficiaryAccountStep;
