import { useContext, useState, useEffect, useCallback } from 'react';
import { ButtonDefault, ButtonPrimary, StepContent } from 'app/components';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import { InputItem, StepContentButtonsArea, VariablesArea } from './styles';
import { ArrowBack } from '@mui/icons-material';
import { useProductsService } from 'modules/products/services/hooks';
import get from 'lodash.get';
import * as yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { formatDecimalField } from 'app/helpers';
import { format } from 'date-fns';
import {
  formatDateForBackend,
  formatDateFromBackend,
} from 'app/helpers/dateHelpers';
import {
  CalendarInput,
  CustomInput,
  CustomSelect,
  Heading3,
  Spinner,
} from 'app/components';
import { TCustomVariable, TCustomVariableValue } from '../types';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

const REQUIRED_FIELD_MESSAGE = 'Campo obrigatório';

const CustomVariablesStep = () => {
  const [loading, setLoading] = useState(false);
  const { getCustomVariables } = useProductsService();
  const {
    onForward,
    onBack,
    onSaveBorrower,
    isSavingBorrower,
    productData,
    borrowerData,
    borrowerType,
  } = useContext(QualificationRequestStepperContext);
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | boolean;
  }>();
  const [variablesFormSchema, setVariablesFormSchema] = useState({});
  const [customVariables, updateCustomVariables] = useState<TCustomVariable[]>(
    [],
  );

  const handleCreateValidationSchema = (
    variables: TCustomVariable[] | undefined,
  ) => {
    if (variables && variables?.length > 0) {
      const validationSchemaObj = variables.reduce((schemaObj, variable) => {
        return {
          ...schemaObj,
          [`${variable.id}`]:
            variable.type === 'BOOLEAN'
              ? variable.type === 'BOOLEAN' && variable.required
                ? yup
                    .object({
                      label: yup.string(),
                      value: yup.string(),
                    })
                    .nullable()
                    .test({
                      name: `${variable.name} field`,
                      test: (value, { createError }) => {
                        return !value ||
                          value?.value === undefined ||
                          value?.value === ''
                          ? createError({
                              message: 'Campo obrigatório',
                              path: `${variable.id}`,
                            })
                          : true;
                      },
                    })
                : yup
                    .object({
                      label: yup.string().nullable(),
                      value: yup.string().nullable(),
                    })
                    .nullable()
              : variable.type === 'NUMBER'
              ? variable.type === 'NUMBER' && variable.required
                ? yup.string().required(REQUIRED_FIELD_MESSAGE)
                : yup.string()
              : variable.type === 'TEXT'
              ? variable.required
                ? yup.string().required(REQUIRED_FIELD_MESSAGE).min(1)
                : yup.string()
              : variable.type === 'DATE'
              ? variable.type === 'DATE' && variable.required
                ? yup.string().nullable().required(REQUIRED_FIELD_MESSAGE)
                : yup.string().nullable()
              : yup.string(),
        };
      }, {});
      setVariablesFormSchema(yup.object(validationSchemaObj).required());
    }
  };

  const handleCreateInitialValues = (
    variables: TCustomVariable[] | undefined,
  ) => {
    if (variables && variables?.length > 0) {
      const variablesInitialValues = variables.reduce((values, variable) => {
        const borrowerValue = get(borrowerData, variable.name, undefined);
        if (typeof borrowerValue !== 'undefined') {
          if (variable.type === 'DATE')
            return {
              ...values,
              [variable.id]: formatDateFromBackend(borrowerValue).toISOString(),
            };
          return {
            ...values,
            [variable.id]: borrowerValue,
          };
        }
        if (variable.type === 'BOOLEAN' || variable.type === 'DATE')
          return {
            ...values,
            [variable.id]: null,
          };
        return {
          ...values,
          [variable.id]: '',
        };
      }, {});
      setInitialValues(variablesInitialValues);
    }
  };

  useEffect(() => {
    if (productData) {
      setLoading(true);
      getCustomVariables(productData?.id)
        .then((variablesRes) => {
          if (variablesRes) {
            const type =
              productData.borrower_type === 'PERSON' ? 'person' : 'business';
            const variables =
              borrowerType === EFunctions.BORROWER
                ? variablesRes.borrower[type]
                : variablesRes.employer;

            // Handle schema for form
            handleCreateValidationSchema(variables);

            // Handle initial values for form
            handleCreateInitialValues(variables);

            // Update custom variables
            updateCustomVariables(variables);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [productData, borrowerType, borrowerData]);

  const handleInputChange = useCallback(
    (
      evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      variable: TCustomVariable,
    ) => {
      if (variable.type === 'NUMBER') {
        formik.setFieldValue(
          `${evt.target.name}`,
          formatDecimalField(evt.target.value),
        );
        return;
      }
      formik.setFieldValue(`${evt.target.name}`, evt.target.value);
    },
    [],
  );

  const handleInputCalendarChange = useCallback(
    (value: Date | null, variable: TCustomVariable) => {
      formik.setFieldValue(`${variable.id}`, value);
    },
    [],
  );

  const formik = useFormik({
    initialValues: initialValues as FormikValues,
    enableReinitialize: true,
    validationSchema: variablesFormSchema,
    onSubmit(values) {
      const variablesValuesKeys = Object.keys(values);

      const parsedVariablesValues: TCustomVariableValue[] =
        variablesValuesKeys.map((key) => {
          const currentVar: TCustomVariable | undefined = customVariables?.find(
            (i) => i.id === key,
          );
          return {
            name: currentVar?.name?.replace('custom_variables.', ''),
            value:
              currentVar?.type === 'NUMBER'
                ? formatDecimalField(values[key]).replace(',', '.')
                : currentVar?.type === 'DATE'
                ? values[key] &&
                  format(
                    formatDateForBackend(
                      new Date(values[key]).toLocaleDateString('pt-br'),
                    ),
                    'yyyy-MM-dd',
                  )
                : currentVar?.type === 'BOOLEAN'
                ? values[key]?.value
                : values[key],
            id: key,
          };
        });

      onSaveBorrower(parsedVariablesValues).then((res) => {
        if (res) onForward();
      });
    },
  });

  const hasCustomVariables = customVariables.length > 0;
  return (
    <StepContent
      title="Campos adicionais"
      description="Defina os campos adicionais necessários para a linha de crédito"
    >
      <VariablesArea>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {hasCustomVariables ? (
              customVariables.map((variable) => {
                return (
                  <InputItem key={variable.id}>
                    {variable.type === 'BOOLEAN' ? (
                      <CustomSelect
                        handleInputChange={formik.handleChange}
                        name={variable.id}
                        options={[
                          { value: false, label: 'Não' },
                          { value: true, label: 'Sim' },
                          { value: '', label: 'Vazio' },
                        ]}
                        labelValue={variable.display_name}
                        value={{
                          value: formik.values?.[`${variable.id}`]?.value,
                          label: formik.values?.[`${variable.id}`]?.label,
                        }}
                        placeholder="Definir valor"
                        validationError={
                          formik.touched?.[`${variable.id}`]
                            ? formik.errors?.[`${variable.id}`]?.toString()
                            : ''
                        }
                      />
                    ) : variable.type === 'DATE' ? (
                      <CalendarInput
                        name={variable.name}
                        labelValue={variable.display_name}
                        placeholder="00/00/0000"
                        value={formik.values?.[`${variable.id}`]}
                        onChange={(e) => handleInputCalendarChange(e, variable)}
                        validationError={
                          formik.touched?.[`${variable.id}`]
                            ? formik.errors?.[`${variable.id}`]?.toString()
                            : ''
                        }
                      />
                    ) : (
                      <CustomInput
                        handleInputChange={(e) =>
                          handleInputChange(e, variable)
                        }
                        name={variable.id}
                        labelValue={variable.display_name}
                        value={formik.values?.[`${variable.id}`] || ''}
                        placeholder={
                          variable.type === 'NUMBER'
                            ? 'Digitar número'
                            : 'Digitar informação'
                        }
                        validationError={
                          formik.touched?.[`${variable.id}`]
                            ? formik.errors?.[`${variable.id}`]?.toString()
                            : ''
                        }
                      />
                    )}
                  </InputItem>
                );
              })
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Heading3 style={{ color: '#C3C1CA' }}>
                  O produto não requer nenhum campo
                </Heading3>
              </div>
            )}
          </>
        )}
      </VariablesArea>
      <StepContentButtonsArea>
        {!isSavingBorrower ? (
          <>
            <ButtonDefault
              disabled={isSavingBorrower}
              margin={true}
              onClick={() => onBack()}
            >
              <ArrowBack />
            </ButtonDefault>
            <ButtonPrimary
              disabled={loading}
              onClick={() =>
                hasCustomVariables ? formik.submitForm() : onForward()
              }
            >
              {hasCustomVariables ? 'Avançar' : 'Pular'}
            </ButtonPrimary>
          </>
        ) : (
          <Spinner />
        )}
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default CustomVariablesStep;
