import { Divider, DrawerProps, useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CloseIcon,
  CustomCircularIndicator,
  Heading3,
} from '../';
import { useWindowSize } from 'app/hooks';
import {
  EditSideSheetContent,
  ButtonArea,
  CloseIconWrapper,
  SideSheetHeader,
} from './styles';
import { BaseSidesheet } from '../BaseSidesheet';

export interface IEditSideSheet extends DrawerProps {
  handleClose: () => void;
  handleSave: () => void;
  handleClear?: () => void;
  title: string;
  isLoading: boolean;
  isDisabled?: boolean;
}

const EditSideSheet = ({
  title,
  handleClose,
  handleSave,
  handleClear,
  open,
  isDisabled,
  isLoading,
  children,
}: IEditSideSheet) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  return (
    <BaseSidesheet hideBackdrop open={open} handleClose={handleClose}>
      <SideSheetHeader>
        <Heading3>{title}</Heading3>
        {typeof handleClear === 'function' && (
          <ButtonPrimary
            onClick={handleClear}
            style={{
              marginLeft: 'auto',
              width: 'auto',
              marginRight: '15px',
              border: 'none',
              fontSize: '18px',
            }}
            typeVariant="outline"
          >
            Limpar filtros
          </ButtonPrimary>
        )}
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </SideSheetHeader>
      <Divider />
      <EditSideSheetContent
        style={{
          width:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width! > theme.breakpoints.values.tablet
              ? theme.spacing(75)
              : theme.spacing(50),
        }}
      >
        {children}
        <ButtonArea>
          {!isLoading ? (
            <>
              <ButtonPrimary
                onClick={handleClose}
                style={{ width: '160px' }}
                typeVariant="outline"
              >
                Cancelar
              </ButtonPrimary>
              <ButtonPrimary
                onClick={handleSave}
                disabled={isDisabled}
                style={{
                  width: '160px',
                }}
              >
                Salvar
              </ButtonPrimary>
            </>
          ) : (
            <CustomCircularIndicator
              sx={{ color: theme.palette.brand.primary.base }}
            />
          )}
        </ButtonArea>
      </EditSideSheetContent>
    </BaseSidesheet>
  );
};

export default EditSideSheet;
