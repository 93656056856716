import { useTheme } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import {
  BoxSection,
  ButtonPrimary,
  Heading3,
  SearchFilter,
  Spinner,
} from '../../../../../../app/components';
import {
  ButtonArea,
  FilterArea,
  HeaderArea,
  LegalPersonListPageContentContainer,
  TableArea,
} from './styles';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { routingPath } from '../../../../../../app/routes';
import { useCustomerService } from '../../../../services/api';
import { formatCNPJ } from '../../../../../../app/helpers';
import { TFilterItems } from 'app/components/SearchFilter/SearchFilter';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Table, TextL, TextS } from 'app/components';
import {
  useQueryParam,
  NumberParam,
  StringParam,
  JsonParam,
  withDefault,
} from 'use-query-params';

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Empresa/Pessoa</TextL>
      </TableHeadCellContainer>
    ),
  },

  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data de cadastro</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const filterItems: TFilterItems[] = [
  {
    name: 'Nome',
    parameterName: 'legal_name',
  },
  {
    name: 'CNPJ',
    parameterName: 'taxpayer_id',
  },
];

const LegalPersonListPageContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  );
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const { getBusinessList, creatBusinessLoading } = useCustomerService();
  const prevSelectedFilter = useRef<string>('');
  const prevFilterValue = useRef<string>('');
  const tableDataContentLength = useRef<number>(0);
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );
  const { isProfileAuthorized } = usePermify();
  const [tableDataContent, updateTableDataContent] = useState<
    {
      id: string;
      name: string;
      register: string;
      created_at: string;
    }[]
  >([]);

  useEffect(() => {
    requestBusinessList(
      currentPage,
      selectedFilter.parameterName,
      searchInputValue,
      linesPerPage,
    );
  }, [currentPage, linesPerPage]);

  const requestBusinessList = (
    page: number,
    parameterName?: string,
    parameterValue?: string,
    itemsPerPage = 10,
  ) => {
    getBusinessList(page, parameterName, parameterValue, itemsPerPage).then(
      (businessList) => {
        updateTableDataContent([]);
        if (businessList) {
          setTotalItemsQty(businessList.total_elements || 0);
          tableDataContentLength.current = businessList.content.length;
          const businessMap = businessList.content.map((business) => ({
            id: business.id,
            name: business.legal_name,
            register: formatCNPJ(business.taxpayer_id),
            created_at: formatDateFromBackend(
              business.created_at || '',
            ).toLocaleDateString('pt-br'),
          }));

          if (businessMap.length > 0) {
            if (
              prevSelectedFilter.current === parameterName &&
              prevFilterValue.current === parameterValue
            ) {
              updateTableDataContent((state) =>
                state?.concat(
                  ...[
                    businessMap.filter((content) => {
                      const inx = state.findIndex(
                        (stateContent) => stateContent?.id === content?.id,
                      );
                      return inx < 0;
                    }),
                  ],
                ),
              );
            } else {
              updateTableDataContent(() => businessMap);
              prevSelectedFilter.current = parameterName || '';
              prevFilterValue.current = parameterValue || '';
            }
          } else {
            updateTableDataContent(() => []);
            prevSelectedFilter.current = parameterName || '';
            prevFilterValue.current = parameterValue || '';
          }
        }
      },
    );
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    setTimeout(() =>
      requestBusinessList(
        0,
        selectedFilter.parameterName,
        searchInputValue,
        10,
      ),
    );
  };

  return (
    <LegalPersonListPageContentContainer>
      <BoxSection>
        <HeaderArea>
          <Heading3 style={{ color: theme.palette?.brand.gray[800] }}>
            Cadastros
          </Heading3>
          <FilterArea>
            <SearchFilter
              inputValue={searchInputValue}
              handleClearInput={() => {
                setCurrentPage(0);
                setLinesPerPage(10);
                setSearchInputValue(() => '');
                setTimeout(() =>
                  requestBusinessList(0, undefined, undefined, 10),
                );
              }}
              handleInputChange={(evt) =>
                setSearchInputValue(() => evt.target.value)
              }
              filterItems={filterItems}
              handleSelectFilterItem={(selectedFilterValue) => {
                setSelectedFilter(() => selectedFilterValue);
              }}
              selectedFilter={selectedFilter?.name}
              handleSearch={handleSearch}
            />
          </FilterArea>
          <ButtonArea>
            {isProfileAuthorized(
              EAccountPermissions.CREATE_PERSON_BUSINESS,
            ) && (
              <ButtonPrimary
                roundType="circular"
                onClick={() => navigate(routingPath.records.legalNew)}
              >
                <AddIcon sx={{ marginRight: '8px' }} />
                Adicionar empresa
              </ButtonPrimary>
            )}
          </ButtonArea>
        </HeaderArea>
        <TableArea>
          {creatBusinessLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                width: '100%',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeadContent}
              tableBodyContent={tableDataContent?.map((item) => {
                return {
                  id: '1',
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            navigate(`/records/legal/${item.id}/details`)
                          }
                        >
                          <TextS weight="Bold">{item.name}</TextS>
                          <TextS>{item.register}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            navigate(`/records/legal/${item.id}/details`)
                          }
                        >
                          <TextS weight="Bold">{item.created_at}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })}
            />
          )}
        </TableArea>
      </BoxSection>
    </LegalPersonListPageContentContainer>
  );
};

export default LegalPersonListPageContent;
