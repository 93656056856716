import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { BeneficiaryHttpService } from '../api/beneficiaryHttpService';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { IList } from './intefaces';

const useBeneficiaryService = () => {
  const [getBeneficiaryAccountsLoading, setGetBeneficiaryAccountsLoading] =
    useState(false);
  const { showSnackbar } = useSnackbar();

  const getBeneficiaryAccounts = async (
    page: number,
    linesPerPage = 999,
  ): Promise<IList<TBeneficiaryAccount> | undefined> => {
    setGetBeneficiaryAccountsLoading(true);
    try {
      const beneficiaryService = new BeneficiaryHttpService();
      const res = await beneficiaryService.getBeneficiaryAccounts(
        page,
        linesPerPage,
      );
      return res.data;
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar os dados de contas beneficiárias',
      );
    } finally {
      setGetBeneficiaryAccountsLoading(false);
    }
  };

  return {
    getBeneficiaryAccountsLoading,
    getBeneficiaryAccounts,
  };
};

export default useBeneficiaryService;
