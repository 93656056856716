import { Badge, ButtonPrimary, CustomLinearProgress } from 'app/components';
import { TBadgeVariant } from 'app/components/Badge/Badge';
import {
  DetailsIcon,
  InstallmentsDueIcon,
  InstallmentsDueTodayIcon,
  InstallmentsOverdueIcon,
  InstallmentsPaidIcon,
} from 'app/components/Icons';

import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { Table, TextL, TextM, TextS, TextXs } from 'app/components';
import { Contract } from 'modules/charges/interfaces/contracts';
import { TableWrapper } from './styles';
import { useTheme } from '@mui/material';
import {
  getFirstDueInstallment,
  getPaidInstallmentPaidAmount,
  getProgress,
  getProgressValue,
  getTotalContractsDueAmountValue,
} from 'modules/charges/utils/contract';

const tableHeaders = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer style={{ padding: '0px 10px 0px 7px' }}>
        <TextL weight="bold">Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer style={{ paddingLeft: '20px' }}>
        <TextL weight="bold">Parcelas pagas</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor da parcela</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor total</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor pago</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Status do contrato</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

export interface IContractsTable {
  contracts?: Contract[] | null;
  totalItems: number;
  currentPage: number;
  linesPerPage: number;
  handleLinesPerPage: (lines: number) => void;
  handlePageChange: (page: number) => void;
  handleContractDetail: (contract: Contract) => void;
}

export const CONTRACT_STATUS = {
  due: {
    color: 'info',
    text: 'A vencer',
    value: 'due',
    icon: <InstallmentsDueIcon />,
  },
  paid: {
    color: 'success',
    text: 'Pago',
    value: 'paid',
    icon: <InstallmentsPaidIcon />,
  },
  partially_paid: {
    color: 'attention',
    text: 'Parcialmente pago',
    value: 'partially_paid',
    icon: <InstallmentsDueTodayIcon />,
  },
  due_today: {
    color: 'attention',
    text: 'Vence hoje',
    value: 'due_today',
    icon: <InstallmentsDueTodayIcon />,
  },
  over_due: {
    color: 'error',
    text: 'Vencido',
    value: 'over_due',
    icon: <InstallmentsOverdueIcon />,
  },
  all: {
    color: 'success',
    text: 'Todos',
    value: 'all',
    icon: <InstallmentsOverdueIcon />,
  },
};

const ContractsTable = (props: IContractsTable) => {
  const {
    contracts,
    totalItems,
    currentPage,
    linesPerPage,
    handleLinesPerPage,
    handlePageChange,
    handleContractDetail,
  } = props;
  const theme = useTheme();
  return (
    <TableWrapper>
      <Table
        tableHeadCell={tableHeaders}
        totalElementsQty={totalItems}
        currentPage={currentPage + 1}
        linesPerPage={linesPerPage}
        handleLinesPerPage={handleLinesPerPage}
        handlePageChange={handlePageChange}
        tableBodyContent={
          contracts
            ? contracts.map((contract) => {
                return {
                  id: contract?.id.toString(),
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextS weight="Bold">
                            {contract?.debtor?.[0].full_name ||
                              contract?.debtor?.[0]?.legal_name ||
                              '-'}
                          </TextS>
                          <TextXs>
                            {normalizeCpfCnpj(
                              contract?.debtor?.[0].taxpayer_id || '',
                            )}
                          </TextXs>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <CustomLinearProgress
                            progress={getProgress(contract)}
                            total={contract?.installments?.length}
                            value={getProgressValue(contract) || 0}
                            complete={
                              getProgressValue(contract) ===
                              contract?.installments?.length
                            }
                          />
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {!getFirstDueInstallment(contract)
                              ? '-'
                              : formatCurrency(
                                  getFirstDueInstallment(contract)
                                    ?.due_amount || 0,
                                )}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {formatCurrency(contract.total_due_amount || 0)}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {formatCurrency(
                              getPaidInstallmentPaidAmount(contract),
                            ) || 0}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '6',
                      content: (
                        <TableBodyCellContainer>
                          <Badge
                            iconPosition="left"
                            isSpaceBetween={true}
                            style={{
                              borderColor: 'transparent',
                              position: 'relative',
                            }}
                            typeVariant={
                              (CONTRACT_STATUS[
                                contract.status as keyof typeof CONTRACT_STATUS
                              ]?.color as TBadgeVariant) || 'info'
                            }
                            icon={
                              CONTRACT_STATUS[
                                contract.status as keyof typeof CONTRACT_STATUS
                              ]?.icon || <InstallmentsDueIcon />
                            }
                          >
                            {CONTRACT_STATUS[
                              contract.status as keyof typeof CONTRACT_STATUS
                            ]?.text || contract.status}
                          </Badge>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '7',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0px',
                          }}
                        >
                          <ButtonPrimary
                            typeVariant="outline"
                            style={{ width: 'fit-content' }}
                            onClick={() => handleContractDetail(contract)}
                          >
                            <DetailsIcon
                              color={theme.palette.brand.primary.base as string}
                            />
                          </ButtonPrimary>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })
            : []
        }
        tableFooterCell={[
          {
            id: '1',
            content: null,
          },
          {
            id: '2',
            content: null,
          },
          {
            id: '3',
            content: null,
          },
          {
            id: '4',
            content: (
              <TableBodyCellContainer>
                <div style={{ display: 'block' }}>
                  <div>
                    <TextM>{`Total (${contracts && contracts.length})`}</TextM>
                  </div>
                  <div>
                    <TextM weight="Bold">
                      {contracts
                        ? formatCurrency(
                            getTotalContractsDueAmountValue(contracts),
                          )
                        : '-'}
                    </TextM>
                  </div>
                </div>
              </TableBodyCellContainer>
            ),
          },
          {
            id: '5',
            content: (
              <TableBodyCellContainer>
                <div style={{ display: 'block' }}>
                  <div>
                    <TextM>{`Total (${contracts && contracts.length})`}</TextM>
                  </div>
                  <div>
                    <TextM weight="Bold">
                      {contracts
                        ? formatCurrency(
                            contracts
                              ?.map(
                                (contract) =>
                                  getPaidInstallmentPaidAmount(contract) || 0,
                              )
                              .reduce((acc, value) => acc + value, 0),
                          )
                        : '-'}
                    </TextM>
                  </div>
                </div>
              </TableBodyCellContainer>
            ),
          },
          {
            id: '6',
            content: null,
          },
          {
            id: '7',
            content: null,
          },
          {
            id: '8',
            content: null,
          },
        ]}
        showPagination
      />
    </TableWrapper>
  );
};

export default ContractsTable;
