import { SideSheet, TextL, ViewBox } from 'app/components';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { Heading4 } from 'app/components';
import { TMovement } from 'modules/disbursement/interfaces/disbursement';
import { useMemo } from 'react';
import { MovementTypeLabel, getAccountType } from './MovementsStatementContent';

export interface IBalanceStatementDepositSideSheet {
  handleClose: () => void;
  movementData?: TMovement;
  isOpen: boolean;
}

const MovementTypesWithParty = [
  'PIXPAYMENTIN',
  'PIXPAYMENTOUT',
  'TEDTRANSFERIN',
  'TEDTRANSFEROUT',
  'PIXREVERSALIN',
  'PIXREVERSALOUT',
];

const BankStatementDetailsSideSheet = ({
  handleClose,
  movementData,
  isOpen,
}: IBalanceStatementDepositSideSheet) => {
  const onClose = () => {
    handleClose();
  };

  const debitPartyData = useMemo(() => {
    if (
      movementData &&
      MovementTypesWithParty.includes(movementData.movementType)
    ) {
      const {
        debitPartyAccount,
        debitPartyBranch,
        debitPartyName,
        debitPartyTaxId,
        creditPartyAccount,
        creditPartyAccountType,
        creditPartyBranch,
        creditPartyKey,
        creditPartyName,
        creditPartyTaxId,
      } = movementData;

      return {
        debitPartyAccount: `${
          debitPartyAccount
            ? debitPartyAccount.slice(0, debitPartyAccount.length - 1)
            : ''
        }-${debitPartyAccount ? debitPartyAccount.slice(-1) : ''}`,
        debitPartyBranch: debitPartyBranch || '-',
        debitPartyName: debitPartyName || '-',
        debitPartyTaxId: debitPartyTaxId
          ? normalizeCpfCnpj(debitPartyTaxId)
          : '-',
        creditPartyAccount: `${
          creditPartyAccount
            ? creditPartyAccount.slice(0, creditPartyAccount.length - 1)
            : ''
        }-${creditPartyAccount ? creditPartyAccount.slice(-1) : ''}`,
        creditPartyAccountType,
        creditPartyBranch,
        creditPartyKey,
        creditPartyName,
        creditPartyTaxId: normalizeCpfCnpj(creditPartyTaxId),
      };
    }
  }, [movementData]);

  const creditPartyData = useMemo(() => {
    if (
      movementData &&
      MovementTypesWithParty.includes(movementData.movementType)
    ) {
      const {
        creditPartyAccount,
        creditPartyAccountType,
        creditPartyBranch,
        creditPartyKey,
        creditPartyName,
        creditPartyTaxId,
      } = movementData;

      return {
        creditPartyAccount: `${
          creditPartyAccount
            ? creditPartyAccount.slice(0, creditPartyAccount.length - 1)
            : ''
        }-${creditPartyAccount ? creditPartyAccount.slice(-1) : ''}`,
        creditPartyAccountType: creditPartyAccountType
          ? getAccountType(creditPartyAccountType)
          : '-',
        creditPartyBranch: creditPartyBranch || '-',
        creditPartyKey: creditPartyKey || '-',
        creditPartyName: creditPartyName || '-',
        creditPartyTaxId: creditPartyTaxId
          ? normalizeCpfCnpj(creditPartyTaxId)
          : '-',
      };
    }
  }, [movementData]);

  return (
    <SideSheet
      open={isOpen}
      handleClose={onClose}
      title="Detalhes do Lançamento"
    >
      <Heading4 fontWeight="B">
        {movementData?.movementType
          ? MovementTypeLabel[
              movementData?.movementType as keyof typeof MovementTypeLabel
            ]
          : 'Lançamento'}
        {' - '}
        {formatDateFromBackend(
          movementData?.createDate || '',
        ).toLocaleDateString('pt-BR')}{' '}
        {formatStringHourFromBackend(movementData?.createDate || '')}
      </Heading4>
      <div style={{ marginTop: '20px' }}>
        <TextL>
          Valor: {formatCurrency(Number(movementData?.amount) || 0)}
        </TextL>
      </div>
      {movementData &&
      MovementTypesWithParty.includes(movementData!.movementType) ? (
        <>
          <div style={{ marginTop: '10px' }}>
            <TextL weight="semibold">Pagador</TextL>
            <ViewBox
              columns={[
                {
                  label: 'Nome',
                  path: 'debitPartyName',
                  full: true,
                },
                {
                  label: 'CPF/CNPJ',
                  path: 'debitPartyTaxId',
                },
                {
                  label: 'Agência',
                  path: 'debitPartyBranch',
                },
                {
                  label: 'Conta',
                  path: 'debitPartyAccount',
                },
              ]}
              values={
                debitPartyData as {
                  [key: string]: string | number;
                }
              }
              size={1}
              textAlign="right"
              style={{ marginTop: '10px' }}
            />
          </div>
          <div style={{ marginTop: '30px' }}>
            <TextL weight="semibold">Recebedor</TextL>
            <ViewBox
              columns={[
                {
                  label: 'Nome',
                  path: 'creditPartyName',
                  full: true,
                },
                {
                  label: 'CPF/CNPJ',
                  path: 'creditPartyTaxId',
                },
                {
                  label: 'Agência',
                  path: 'creditPartyBranch',
                },
                {
                  label: 'Conta',
                  path: 'creditPartyAccount',
                },
                {
                  label: 'Tipo de Conta',
                  path: 'creditPartyAccountType',
                },
                {
                  label: 'Chave Pix',
                  path: 'creditPartyKey',
                },
              ]}
              values={
                creditPartyData as {
                  [key: string]: string | number;
                }
              }
              size={1}
              textAlign="right"
              style={{ marginTop: '10px' }}
            />
          </div>
        </>
      ) : null}
    </SideSheet>
  );
};

export default BankStatementDetailsSideSheet;
