import React from 'react';
import {
  CloseButton,
  PendenciesModalContainer,
  PendenciesModalContent,
  PendenciesModalHeader,
  PendenciesModalList,
} from './styles';
import { BaseModal, Heading4, TextM } from 'app/components';
import { CloseIcon } from 'app/components';
import {
  BusinessDocumentTypeLabel,
  PersonDocumentTypeLabel,
} from 'app/helpers/documentTypes';
import { useTheme } from '@mui/material';
import { TQualification } from 'modules/products/context';

export type IPendenciesModalProps = {
  isOpen: boolean;
  qualification?: TQualification;
  handleClose: () => void;
};

const AllDocumentsLabels = {
  ...BusinessDocumentTypeLabel,
  ...PersonDocumentTypeLabel,
};

const PendenciesModal = ({
  isOpen,
  qualification,
  handleClose,
}: IPendenciesModalProps) => {
  const theme = useTheme();

  return (
    <BaseModal
      open={isOpen}
      onClose={handleClose}
      sx={{ padding: '15px 48px' }}
    >
      <PendenciesModalContainer>
        <PendenciesModalHeader>
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              padding: '0px',
            }}
            fontWeight="B"
          >
            Pendências
          </Heading4>
          <CloseButton onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseButton>
        </PendenciesModalHeader>
        <PendenciesModalContent>
          {qualification && qualification.missingDocuments.length > 0 && (
            <PendenciesModalList>
              <TextM weight="Bold">Documentos faltantes:</TextM>
              {qualification.missingDocuments.map((document) => {
                return (
                  <TextM key={document.type}>{`- ${
                    AllDocumentsLabels[document.type]
                  }`}</TextM>
                );
              })}
            </PendenciesModalList>
          )}

          {qualification && qualification.missingVariables.length > 0 && (
            <PendenciesModalList>
              <TextM weight="Bold">Campos adicionais faltantes:</TextM>
              {qualification.missingVariables.map((variable) => {
                return (
                  <TextM
                    key={variable.name}
                  >{`- Informar ${variable.display_name}`}</TextM>
                );
              })}
            </PendenciesModalList>
          )}
        </PendenciesModalContent>
      </PendenciesModalContainer>
    </BaseModal>
  );
};

export default PendenciesModal;
