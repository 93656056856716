import { ButtonPrimary, Heading4, Spinner, TextL } from 'app/components';
import { ButtonContainer, ModalContent, Wrapper } from './styles';
import { useTheme } from '@mui/material';
import { CustomInput } from 'app/components/CustomInput';
import { useFormik } from 'formik';
import { CustomSelect } from 'app/components/CustomSelect';
import {
  ACCOUNT_TYPE_ARRAY,
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_ARRAY,
  BANK_LIST_OBJECT,
} from 'app/helpers';
import { useEffect, useState } from 'react';
import { formatBankAccount } from 'app/utils/numbers';
import { bankStepSchema } from 'modules/customer/helpers/businessValidation';
import { getPixTypeFromPixTypeToggle } from 'modules/customer/components/PixSelector';
import {
  PixTypeToggle,
  getErrorValidation,
  getPixTypeToggleFromPixType,
  getPlaceholder,
} from 'modules/customer/components/PixSelector/PixSelector';
import {
  IPixKey,
  PixType,
  TRegisterBusinessDataRequest,
} from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import {
  applyFormat,
  pixTypeToggles,
} from 'modules/customer/pages/LegalPersonViewPage/components/LegalPersonViewBankSection/LegalPersonEditPixSection';
import {
  PixTypeTogglesArea,
  PixTypeTogglesButton,
} from 'modules/customer/components/PixSelector/styles';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { useApplicationsService } from 'modules/products/services/hooks';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { TProducts } from 'modules/products/context';
import { TRegisterPersonDataRequest } from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { Warning } from '@mui/icons-material';

export type RegisterData = {
  external_bank_account: {
    ispb_code: string;
    bank_account: string;
    bank_account_digit: string;
    bank_branch: string;
    bank_account_type: string;
  };
  pix: {
    key: string;
    key_type: string;
  };
};

export interface RetryPaymentModalProps {
  isOpen: boolean;
  applicationData: TApplicationResponse;
  productData: TProducts | null | undefined;
  borrowerData:
    | TRegisterPersonDataRequest
    | TRegisterBusinessDataRequest
    | null
    | undefined;
  handleClose: () => void;
}

const RetryPaymentEditSidesheet = ({
  isOpen,
  applicationData,
  productData,
  borrowerData,
  handleClose,
}: RetryPaymentModalProps) => {
  const [application, updateApplication] =
    useState<TApplicationResponse | null>(null);
  const paymentOrder = application?.payment_orders?.[0];
  const beneficiary_account = paymentOrder?.beneficiary_account;
  const pix = beneficiary_account?.pix || undefined;

  const theme = useTheme();
  const { getApplication, applicationLoading } = useApplicationsService();
  const { updateApplicationPaymentOrder, updatePaymentOrderLoading } =
    useApplicationsService();
  const { showSnackbar } = useSnackbar();
  const { updatePerson, editPersonLoading } = usePersonService();
  const { updateBusiness, creatBusinessLoading } = useCustomerService();
  const [pixType, togglePixType] = useState<PixTypeToggle>(
    getPixTypeToggleFromPixType(pix as IPixKey | undefined),
  );
  const [pixKey, updatePixKey] = useState<string>(
    pix?.key ? applyFormat(pix?.key, pixType, true) : '',
  );

  const formik = useFormik({
    initialValues: {
      key: pixKey,
      ispb_code: beneficiary_account?.external_bank_account?.ispb_code
        ? {
            value: beneficiary_account?.external_bank_account?.ispb_code,
            label:
              BANK_LIST_OBJECT[
                beneficiary_account?.external_bank_account?.ispb_code as string
              ],
          }
        : undefined,
      bank_account: beneficiary_account?.external_bank_account?.bank_account
        ? formatBankAccount(
            `${beneficiary_account?.external_bank_account?.bank_account?.padStart(
              7,
              '0',
            )}${
              beneficiary_account?.external_bank_account?.bank_account_digit
            }`,
          )
        : '',
      bank_branch:
        beneficiary_account?.external_bank_account?.bank_branch ?? '',
      bank_account_type: beneficiary_account?.external_bank_account
        ?.bank_account_type
        ? {
            value:
              beneficiary_account?.external_bank_account?.bank_account_type,
            label:
              ACCOUNT_TYPE_OBJECT[
                beneficiary_account?.external_bank_account
                  ?.bank_account_type as string
              ],
          }
        : undefined,
    },
    enableReinitialize: true,
    validationSchema: bankStepSchema,
    onSubmit: async (values) => {
      const bank_account = values.bank_account.replace(/[^\w\s]/gi, '');
      const keyType = getPixTypeFromPixTypeToggle(pixType);
      const key =
        keyType === PixType.PHONE_NUMBER
          ? `+55${values.key.replace(/[^\w\s]/gi, '').replace(/\s/g, '')}`
          : keyType === PixType.TAXPAYER_ID
          ? values.key.replace(/[^\w\s]/gi, '')
          : keyType === PixType.EMAIL
          ? values.key.toLowerCase()
          : values.key.trim();

      const externalBankAccount = values.bank_account
        ? {
            ispb_code: values.ispb_code?.value as string,
            bank_code: values.ispb_code?.value as string,
            bank_account: bank_account.slice(0, bank_account.length - 1),
            bank_account_digit: bank_account.slice(
              bank_account.length - 1,
              bank_account.length,
            ),
            bank_branch: values.bank_branch,
            bank_account_type: values.bank_account_type?.value as string,
          }
        : undefined;

      const pixValue = key ? { key, key_type: keyType } : undefined;

      if (!pixValue && !externalBankAccount)
        return showSnackbar(
          'Conta bancária ou pix devem ser informados para atualização',
          'error',
        );

      if (paymentOrder?.beneficiary_type === 'BORROWER') {
        if (
          productData?.borrower_type === 'PERSON' &&
          borrowerData &&
          'full_name' in borrowerData
        ) {
          await updatePerson(borrowerData.id || '', {
            ...borrowerData,
            external_bank_account: externalBankAccount,
            pix: pixValue,
          });
        }

        if (
          productData?.borrower_type === 'BUSINESS' &&
          borrowerData &&
          'legal_name' in borrowerData
        ) {
          await updateBusiness(borrowerData.id || '', {
            ...borrowerData,
            external_bank_account: externalBankAccount,
            pix: pixValue,
          });
        }
      }

      if (!application)
        return showSnackbar('Erro ao atualizar conta beneficiária', 'error');

      await updateApplicationPaymentOrder(
        application?.id || '',
        application?.payment_orders[0].id || '',
        {
          ...application?.payment_orders?.[0]?.beneficiary_account,
          external_bank_account: {
            ispb_code: values.ispb_code?.value as string,
            bank_code: values.ispb_code?.value as string,
            bank_account: bank_account.slice(0, bank_account.length - 1),
            bank_account_digit: bank_account.slice(
              bank_account.length - 1,
              bank_account.length,
            ),
            bank_branch: values.bank_branch,
            bank_account_type: values.bank_account_type?.value as string,
          },
          pix: {
            key,
            key_type: keyType,
          },
        },
      ).then((result) => {
        if (result) {
          showSnackbar('Conta beneficiária atualizada com sucesso', 'success');
        }
      });
    },
  });

  const pixValidation =
    formik.values.key !== '' && getErrorValidation(pixType, formik.values.key);

  const handleCancelEdit = () => {
    formik.resetForm();
    formik.setFieldValue(
      'ispb_code',
      beneficiary_account?.external_bank_account?.ispb_code
        ? {
            value: beneficiary_account?.external_bank_account?.ispb_code,
            label:
              BANK_LIST_OBJECT[
                beneficiary_account?.external_bank_account?.ispb_code as string
              ],
          }
        : [undefined],
    );
    formik.setFieldValue(
      'bank_account_type',
      beneficiary_account?.external_bank_account?.bank_account_type
        ? {
            value:
              beneficiary_account?.external_bank_account?.bank_account_type,
            label:
              ACCOUNT_TYPE_OBJECT[
                beneficiary_account?.external_bank_account
                  ?.bank_account_type as string
              ],
          }
        : [undefined],
    );
    formik.setFieldValue(
      'key',
      applyFormat(
        pix?.key || '',
        getPixTypeToggleFromPixType(pix as IPixKey | undefined),
        true,
      ),
    );

    togglePixType(getPixTypeToggleFromPixType(pix as IPixKey | undefined));
  };

  const onClose = () => {
    handleCancelEdit();
    handleClose();
  };

  const onChangePixTypeToggle = (pixTypeToggle: PixTypeToggle) => {
    updatePixKey('');
    formik.setFieldValue('key', '');
    togglePixType(pixTypeToggle);
  };

  useEffect(() => {
    if (isOpen) {
      getApplication(applicationData?.id || '').then((a) => {
        updateApplication(a),
          updatePixKey(
            applyFormat(
              a?.payment_orders?.[0].beneficiary_account?.pix?.key || '',
              getPixTypeToggleFromPixType(
                a?.payment_orders?.[0].beneficiary_account?.pix?.key as
                  | IPixKey
                  | undefined,
              ),
              true,
            ),
          );
        togglePixType(
          getPixTypeToggleFromPixType(
            a?.payment_orders?.[0].beneficiary_account?.pix as
              | IPixKey
              | undefined,
          ),
        );

        formik.setFieldValue(
          'key',
          applyFormat(
            a?.payment_orders?.[0].beneficiary_account?.pix?.key || '',
            getPixTypeToggleFromPixType(
              a?.payment_orders?.[0].beneficiary_account?.pix?.key as
                | IPixKey
                | undefined,
            ),
            true,
          ),
        );
      });
    }
  }, [isOpen]);

  return (
    <ProductSideSheet
      title={'Editar conta beneficiária'}
      open={isOpen}
      handleClose={onClose}
    >
      <Wrapper>
        <ModalContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '18px',
            }}
          >
            {paymentOrder?.beneficiary_type === 'BORROWER' && (
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                }}
              >
                <Warning
                  style={{ color: theme.palette.brand.attention[900] }}
                />
                <TextL>
                  A alteração da conta beneficiária neste contrato refletirá no
                  cadastro do Tomador
                </TextL>
              </div>
            )}
            <Heading4
              fontWeight="B"
              style={{
                color: theme.palette.brand.primary.base,
              }}
            >
              Conta
            </Heading4>

            <div className="beneficiary-account-bank">
              <CustomSelect
                labelValue="Banco"
                name="ispb_code"
                placeholder="Selecione o banco"
                value={formik.values.ispb_code}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={BANK_LIST_ARRAY}
                validationError={
                  formik.touched?.ispb_code ? formik.errors?.ispb_code : ''
                }
              />
              <CustomInput
                name="bank_branch"
                value={formik.values.bank_branch}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="0000"
                labelValue="Agência"
                validationError={
                  formik.touched.bank_branch ? formik.errors.bank_branch : ''
                }
              />
              <CustomInput
                name="bank_account"
                value={formatBankAccount(formik.values.bank_account)}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="000000-0"
                labelValue="Conta"
                validationError={
                  formik.touched.bank_account ? formik.errors.bank_account : ''
                }
              />
              <CustomSelect
                labelValue="Tipo de conta"
                name="bank_account_type"
                placeholder="Selecione o tipo"
                value={formik.values.bank_account_type}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={ACCOUNT_TYPE_ARRAY}
                validationError={
                  formik.touched?.bank_account_type
                    ? formik.errors?.bank_account_type
                    : ''
                }
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
              marginTop: '32px',
            }}
          >
            <Heading4
              fontWeight="B"
              style={{
                color: theme.palette.brand.primary.base,
              }}
            >
              Pix
            </Heading4>

            <PixTypeTogglesArea>
              {pixTypeToggles.map((pixTypeToggle) => {
                return (
                  <PixTypeTogglesButton
                    selected={pixType === pixTypeToggle.value}
                    onClick={() => {
                      onChangePixTypeToggle(pixTypeToggle.value);
                    }}
                    key={pixTypeToggle.value}
                  >
                    {pixTypeToggle.label}
                  </PixTypeTogglesButton>
                );
              })}
            </PixTypeTogglesArea>

            <CustomInput
              name="key"
              id="pixKey"
              value={applyFormat(formik.values.key, pixType)}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={getPlaceholder(pixType)}
              labelValue="Insira abaixo a chave Pix"
              validationError={formik.touched.key ? pixValidation || '' : ''}
            />
          </div>
        </ModalContent>

        <ButtonContainer>
          <ButtonPrimary typeVariant="outline" onClick={onClose}>
            Cancelar
          </ButtonPrimary>
          {editPersonLoading ||
          creatBusinessLoading ||
          updatePaymentOrderLoading ||
          applicationLoading ? (
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <ButtonPrimary
              onClick={formik.submitForm}
              disabled={
                !!pixValidation ||
                editPersonLoading ||
                creatBusinessLoading ||
                updatePaymentOrderLoading ||
                applicationLoading
              }
            >
              Salvar
            </ButtonPrimary>
          )}
        </ButtonContainer>
      </Wrapper>
    </ProductSideSheet>
  );
};

export default RetryPaymentEditSidesheet;
