import { useCallback, useContext, useState } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
  StepContent,
} from 'app/components';
import {
  StepContentButtonsArea,
  StepContentReviewItem,
  StepContentReviewItems,
  StepContentReviewPermission,
  StepContentReviewPermissions,
  StepContentUserAccessHandlerWrapper,
} from './styles';
import { UsersAccessHandlerStepperContext } from '../UsersAccessHandler';
import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import { Heading4, TextM } from 'app/components';
import { getFormatedPhoneNumber } from './InfoStep';
import { formatCPF } from 'app/helpers';
import { UserPermissionsDescription } from 'modules/management/interfaces/usersAccess';
import { UsersAccessListContentPermissionDetails } from '../../UsersAccessPage/components/UsersAccessListContent/styles';
import { AttentionRequiredDocuments } from 'app/components/Icons';
import { useNavigate } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { useAccessService } from 'modules/management/services/useAccessService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const ReviewStep = () => {
  const navigate = useNavigate();
  const { onBack, userAccessData } = useContext(
    UsersAccessHandlerStepperContext,
  );
  const { showSnackbar } = useSnackbar();
  const { createUserAccess, isCreatingUserLoading } = useAccessService();
  const [isConfirmModalVisible, toggleConfirmModalVisible] = useState(false);
  const [permissionDetailsOpenItem, setPermissionDetailsOpenItem] =
    useState<EAccountPermissions | null>(null);

  const onCreateUser = useCallback(() => {
    createUserAccess(userAccessData).then((wasCreated) => {
      toggleConfirmModalVisible(false);
      if (wasCreated) {
        navigate(routingPath.management.access);
        return;
      }
      showSnackbar('Ocorreu um problema ao criar o acesso');
    });
  }, [userAccessData]);

  return (
    <StepContent title="Ficou tudo certo?">
      <StepContentUserAccessHandlerWrapper style={{ padding: '24px' }}>
        <Heading4>Identificação</Heading4>
        <StepContentReviewItems>
          <StepContentReviewItem>
            <TextM weight="Bold">Nome</TextM>
            <TextM>{userAccessData.full_name}</TextM>
          </StepContentReviewItem>
          <StepContentReviewItem>
            <TextM weight="Bold">E-mail</TextM>
            <TextM>{userAccessData.email}</TextM>
          </StepContentReviewItem>
          <StepContentReviewItem>
            <TextM weight="Bold">Telefone</TextM>
            <TextM>{getFormatedPhoneNumber(userAccessData)}</TextM>
          </StepContentReviewItem>
          <StepContentReviewItem>
            <TextM weight="Bold">CPF</TextM>
            <TextM>{formatCPF(userAccessData.taxpayer_id)}</TextM>
          </StepContentReviewItem>
        </StepContentReviewItems>
      </StepContentUserAccessHandlerWrapper>
      {userAccessData?.permissions?.length > 0 && (
        <StepContentUserAccessHandlerWrapper style={{ padding: '24px' }}>
          <Heading4>Permissões</Heading4>
          <StepContentReviewPermissions>
            {userAccessData?.permissions?.map((permission) => {
              return (
                <StepContentReviewPermission key={permission}>
                  <TextM weight="Bold" style={{ marginRight: '10px' }}>
                    {UserPermissionsDescription[
                      permission as keyof typeof UserPermissionsDescription
                    ]?.title ?? permission}
                  </TextM>
                  {UserPermissionsDescription[
                    permission as keyof typeof UserPermissionsDescription
                  ]?.description?.length > 0 && (
                    <InfoOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => setPermissionDetailsOpenItem(permission)}
                    />
                  )}
                  {permissionDetailsOpenItem === permission &&
                    !isConfirmModalVisible && (
                      <UsersAccessListContentPermissionDetails
                        className="review"
                        onClick={() => setPermissionDetailsOpenItem(null)}
                      >
                        {UserPermissionsDescription[
                          permission as keyof typeof UserPermissionsDescription
                        ]?.description ?? permission}
                      </UsersAccessListContentPermissionDetails>
                    )}
                </StepContentReviewPermission>
              );
            })}
          </StepContentReviewPermissions>
        </StepContentUserAccessHandlerWrapper>
      )}
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmModalVisible}
        isLoading={isCreatingUserLoading}
        icon={<AttentionRequiredDocuments width={55} height={55} />}
        title={`${userAccessData.full_name} receberá um email para o primeiro acesso ao Portal em ${userAccessData.email}.`}
        btnConfirmText="Concluir"
        handleConfirm={onCreateUser}
        handleClose={() => toggleConfirmModalVisible(false)}
        handleCancel={() => toggleConfirmModalVisible(false)}
      >
        {userAccessData?.permissions?.length > 0 && (
          <>
            <TextM>Esse usuário terá as seguintes autorizações:</TextM>
            <StepContentReviewPermissions style={{ justifyContent: 'center' }}>
              {userAccessData?.permissions?.map((permission) => {
                return (
                  <StepContentReviewPermission key={permission}>
                    <TextM weight="Bold" style={{ marginRight: '10px' }}>
                      {UserPermissionsDescription[
                        permission as keyof typeof UserPermissionsDescription
                      ]?.title ?? permission}
                    </TextM>
                    {UserPermissionsDescription[
                      permission as keyof typeof UserPermissionsDescription
                    ]?.description?.length > 0 && (
                      <InfoOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPermissionDetailsOpenItem(permission)}
                      />
                    )}
                    {permissionDetailsOpenItem === permission && (
                      <UsersAccessListContentPermissionDetails
                        className="review"
                        onClick={() => setPermissionDetailsOpenItem(null)}
                      >
                        {UserPermissionsDescription[
                          permission as keyof typeof UserPermissionsDescription
                        ]?.description ?? permission}
                      </UsersAccessListContentPermissionDetails>
                    )}
                  </StepContentReviewPermission>
                );
              })}
            </StepContentReviewPermissions>
          </>
        )}
      </ConfirmModal>
      <StepContentButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary onClick={() => toggleConfirmModalVisible(true)}>
          Concluir
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default ReviewStep;
