import { Divider, useTheme } from '@mui/material';
import {
  CloseButton,
  ChargesSimulatorContainer,
  ChargesSimulatorModal,
  ChargesSimulatorHeader,
} from './styles';
import { CloseIcon, ConfirmModal } from 'app/components';
import { Heading3 } from 'app/components';
import { ChargesSimulatorConfigs } from './components/ChargesSimulatorConfigs';
import { ChargesSimulatorDebtor } from './components/ChargesSimulatorDebtor';
import { ChargesSimulatorInstallments } from './components/ChargesSimulatorInstallments';
import { useCallback, useState } from 'react';
import { InstallmentItem } from 'modules/charges/interfaces/installments';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';

export interface IChargesSimulator {
  isActive: boolean;
  handleClose: () => void;
}

const ChargesSimulator = ({ isActive, handleClose }: IChargesSimulator) => {
  const theme = useTheme();
  const [
    isConfirmRemoveInstallmentVisible,
    toggleConfirmRemoveInstallmentVisible,
  ] = useState<InstallmentItem | null>(null);
  const { installments, onRemoveInstallment } = useInstallmentsCart();

  const handleRemoveInstallment = useCallback(
    (installment: InstallmentItem) => {
      if (installments.length === 1) {
        toggleConfirmRemoveInstallmentVisible(installment);
        return;
      }
      onRemoveInstallment(installment);
    },
    [installments],
  );

  const handleRemoveInstallmentConfirm = useCallback(() => {
    onRemoveInstallment(isConfirmRemoveInstallmentVisible as InstallmentItem);
    toggleConfirmRemoveInstallmentVisible(null);
    handleClose();
  }, [isConfirmRemoveInstallmentVisible]);

  if (isActive) {
    return (
      <ChargesSimulatorModal>
        <ChargesSimulatorContainer>
          <ChargesSimulatorHeader>
            <Heading3 fontWeight="B">Criação de cobrança</Heading3>
            <CloseButton onClick={handleClose}>
              <CloseIcon color={theme.palette.brand.secondary.base as string} />
            </CloseButton>
          </ChargesSimulatorHeader>
          <Divider sx={{ marginTop: '22px' }} />
          <ChargesSimulatorConfigs />
          <ChargesSimulatorDebtor />
          <ChargesSimulatorInstallments
            handleRemoveInstallment={handleRemoveInstallment}
          />
        </ChargesSimulatorContainer>
        <ConfirmModal
          isColumnButtons={true}
          isOpen={isConfirmRemoveInstallmentVisible !== null}
          icon={<AttentionCircularIcon />}
          title="Não existem mais parcelas, você deseja continuar?"
          btnConfirmText="Confirmar"
          color="attention"
          handleConfirm={handleRemoveInstallmentConfirm}
          handleClose={() => toggleConfirmRemoveInstallmentVisible(null)}
        >
          Essa é a unica parcela, removendo você vai encerrar o processo de
          geração de cobrança.
        </ConfirmModal>
      </ChargesSimulatorModal>
    );
  }

  return null;
};

export default ChargesSimulator;
