import { useFormik } from 'formik';
import { useState, useCallback, useContext } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
} from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  AboutStepExistingPerson,
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { usePerson } from '../../../hooks';
import { TRegisterPersonDataRequest, usePersonContext } from '../../../context';
import { formatCPF, formatPhone, isValidCPF } from '../../../../../app/helpers';
import { aboutStepSchema } from '../../../helpers/personValidation';
import { formatDateFromBackend } from '../../../../../app/helpers/dateHelpers';
import { usePersonService } from 'modules/customer/services';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { routingPath } from 'app/routes';
import {
  CustomInput,
  CalendarInput,
  CustomCurrencyInput,
  TextS,
  Heading3,
  TextM,
  CustomSelect,
} from 'app/components';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import FormLegendCard from 'modules/customer/components/FormLegendCard/FormLegendCard';
import { FormLegendCardWrapper } from 'modules/customer/components/FormLegendCard/styles';
import { useTheme } from '@mui/material';
import {
  EDocumentType,
  EMaritalStatus,
  ESex,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { TCustomValue } from 'app/components/CustomSelect/CustomSelect';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

const AboutStep = () => {
  const theme = useTheme();
  const { onForward, onBack, updateIsMarried } = useContext(
    NaturalPersonHandlerStepperContext,
  );
  const { getPersonList } = usePersonService();
  const [isCheckingPerson, updateIsCheckingPerson] = useState(false);
  const [isExistingPersonModalVisible, toggleExistingPersonModalVisible] =
    useState(false);
  const [existsPerson, updateExistsPerson] =
    useState<TRegisterPersonDataRequest | null>(null);
  const { setPersonData } = usePerson();
  const {
    state: { registerData },
  } = usePersonContext();
  const formik = useFormik({
    initialValues: {
      taxpayer_id: registerData.taxpayer_id || '',
      full_name: registerData.full_name || '',
      sex: registerData.sex || {
        label: '',
        value: undefined,
      },
      mothers_name: registerData.mothers_name || '',
      nationality: registerData.nationality || '',
      occupation: registerData.occupation || '',
      phone: registerData.phone.number || '',
      email_address: registerData.email_address || '',
      birth_date: registerData.birth_date
        ? formatDateFromBackend(registerData.birth_date).toISOString()
        : '',
      birthplace: registerData.birthplace || '',
      document_number: registerData.id_document?.number || '',
      document_issuer: registerData.id_document?.issuer || '',
      document_type: registerData.id_document?.type || {
        label: '',
        value: undefined,
      },
      document_issue_date: registerData.id_document?.issue_date
        ? formatDateFromBackend(
            registerData.id_document?.issue_date,
          ).toISOString()
        : '',
      monthly_income:
        formatCurrency(registerData.monthly_income, false) || '0,00',
      spouse_full_name: registerData.spouse_full_name || '',
      spouse_taxpayer_id: registerData.spouse_taxpayer_id || '',
      spouse_date_of_birth: registerData.spouse_date_of_birth
        ? formatDateFromBackend(registerData.spouse_date_of_birth).toISOString()
        : '',
      marital_status: registerData.marital_status || {
        label: '',
        value: undefined,
      },
      marital_property_system: registerData.marital_property_system || {
        label: '',
        value: undefined,
      },
      pep: registerData.pep || {
        label: '',
        value: undefined,
      },
    },
    validationSchema: aboutStepSchema,
    onSubmit: (values) => {
      const parsedValues = {
        ...values,
        birth_date: new Date(values.birth_date).toISOString(),
        id_document: {
          number: values.document_number,
          issuer: values.document_issuer,
          type: values.document_type,
          issue_date: values.document_issue_date
            ? new Date(values.document_issue_date).toISOString()
            : '',
        },
        monthly_income: Number(formatToCurrencyFloat(values.monthly_income)),
        phone: {
          area_code: '',
          country_code: '',
          number: values.phone,
        },
        pep: values.pep || {
          label: '',
          value: undefined,
        },
        ...(values.marital_status?.value !== EMaritalStatus.MARRIED && {
          marital_property_system: {
            label: '',
            value: undefined,
          },
          spouse_full_name: undefined,
          spouse_taxpayer_id: undefined,
          spouse_date_of_birth: undefined,
        }),
      };
      setPersonData(parsedValues);
      onForward();
    },
  });

  const onChangeTaxpayerId = useCallback(
    async (taxpayerId: string): Promise<boolean> => {
      if (!isValidCPF(taxpayerId)) return false;
      try {
        updateIsCheckingPerson(true);
        const person = await getPersonList(0, 'taxpayer_id', taxpayerId);
        if (person && person.content.length > 0) {
          updateExistsPerson(person.content[0]);
          toggleExistingPersonModalVisible(true);
          return true;
        }
        updateExistsPerson(null);
        return false;
      } catch {
        return false;
      } finally {
        updateIsCheckingPerson(false);
      }
    },
    [],
  );

  const getFormatedPhoneFromExistingPerson = useCallback(() => {
    if (existsPerson) {
      const phoneNumber = existsPerson?.phone?.number || '';
      const areaCodeNumber = existsPerson?.phone?.area_code || '';

      if (!phoneNumber || !areaCodeNumber) return null;
      return formatPhone(areaCodeNumber + phoneNumber);
    }
    return null;
  }, [existsPerson]);

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_income',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  return (
    <>
      <FormLegendCardWrapper>
        <FormLegendCard>
          <TextS>* - Campos obrigatórios</TextS>
        </FormLegendCard>
      </FormLegendCardWrapper>

      <StepContent
        title="Sobre"
        description="Informe os dados referentes a entidade"
      >
        <StepContentInputArea>
          <StepContentInput>
            <CustomInput
              name="taxpayer_id"
              id="taxpayer_id"
              isLoading={isCheckingPerson}
              value={formatCPF(formik.values.taxpayer_id)}
              handleInputChange={(e) => {
                formik.handleChange(e);
                const taxpayerId = e.currentTarget.value;
                if (taxpayerId.length >= 14) {
                  onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
                }
              }}
              onBlur={formik.handleBlur}
              placeholder="000.000.000-00"
              labelValue="CPF*"
              validationError={
                formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="full_name"
              value={formik.values.full_name}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: João da Silva"
              labelValue="Nome completo*"
              validationError={
                formik.touched.full_name ? formik.errors.full_name : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="nationality"
              value={formik.values.nationality}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Brasileiro"
              labelValue="Nacionalidade*"
              validationError={
                formik.touched.nationality ? formik.errors.nationality : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="occupation"
              value={formik.values.occupation}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Vendedor"
              labelValue="Ocupação*"
              validationError={
                formik.touched.occupation ? formik.errors.occupation : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="phone"
              value={formatPhone(formik.values.phone)}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="(00) 00000-0000"
              labelValue="Telefone*"
              validationError={formik.touched.phone ? formik.errors.phone : ''}
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="email_address"
              value={formik.values.email_address}
              handleInputChange={formik.handleChange}
              placeholder="exemplo@email.com"
              labelValue="E-mail*"
              validationError={
                formik.touched.email_address ? formik.errors.email_address : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CalendarInput
              name="birth_date"
              labelValue="Data de nascimento*"
              placeholder="dd/mm/aaaa"
              value={formik.values.birth_date}
              onChange={(e) =>
                formik.handleChange({
                  currentTarget: {
                    value: e,
                    name: 'birth_date',
                  },
                })
              }
              validationError={
                formik.touched.birth_date ? formik.errors.birth_date : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="birthplace"
              value={formik.values.birthplace}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: São Paulo"
              labelValue="Naturalidade"
              validationError={
                formik.touched.email_address ? formik.errors.email_address : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="mothers_name"
              value={formik.values.mothers_name}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Maria da Silva"
              labelValue="Nome da mãe"
              validationError={
                formik.touched.mothers_name ? formik.errors.mothers_name : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Sexo"
              name="sex"
              placeholder="Selecione"
              value={formik.values.sex}
              handleInputChange={(v) => {
                formik.handleChange(v);
              }}
              options={[
                { value: ESex.MALE, label: 'Masculino' },
                { value: ESex.FEMALE, label: 'Feminino' },
                { value: ESex.OTHER, label: 'Não informado' },
              ]}
              validationError={
                formik.touched.marital_status?.value
                  ? formik.errors.marital_status?.value
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Estado civil"
              name="marital_status"
              placeholder="Selecione"
              value={formik.values.marital_status}
              handleInputChange={(v) => {
                if (v?.currentTarget?.value?.value === EMaritalStatus.MARRIED) {
                  updateIsMarried(true);
                } else {
                  updateIsMarried(false);
                }
                formik.handleChange(v);
              }}
              options={[
                { value: EMaritalStatus.MARRIED, label: 'Casado(a)' },
                { value: EMaritalStatus.WIDOWED, label: 'Viúvo(a)' },
                { value: EMaritalStatus.SEPARATED, label: 'Separado(a)' },
                { value: EMaritalStatus.DIVORCED, label: 'Divorciado(a)' },
                { value: EMaritalStatus.SINGLE, label: 'Solteiro(a)' },
                { value: EMaritalStatus.OTHER, label: 'Outro' },
              ]}
              validationError={
                formik.touched.marital_status?.value
                  ? formik.errors.marital_status?.value
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomCurrencyInput
              labelValue="Renda mensal"
              value={formik.values.monthly_income}
              handleInputChange={handleMonthlyIncomeChange}
              name="monthly_income"
              validationError={
                formik.touched.monthly_income
                  ? formik.errors.monthly_income
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Pessoa politicamente exposta (PEP)"
              name="pep"
              placeholder="Selecione"
              value={formik.values.pep}
              handleInputChange={formik.handleChange}
              options={[
                { value: 'false', label: 'Não' },
                { value: 'true', label: 'Sim' },
              ]}
              validationError={
                formik.touched.marital_status?.value
                  ? formik.errors.marital_status?.value
                  : ''
              }
            />
          </StepContentInput>
        </StepContentInputArea>

        <Heading3>Documento de identificação</Heading3>
        <TextM
          weight="Regular"
          style={{
            color: theme.palette.brand.secondary.base,
            marginBottom: 16,
          }}
        >
          Informe os dados do documento de identificação
        </TextM>
        <StepContentInputArea>
          <StepContentInput>
            <CustomSelect
              labelValue="Tipo de documento"
              name="document_type"
              placeholder="Selecione"
              value={formik.values.document_type as TCustomValue}
              handleInputChange={(v) => {
                formik.handleChange(v);
              }}
              options={[
                { value: EDocumentType.RG, label: 'RG' },
                { value: EDocumentType.CNH, label: 'CNH' },
                { value: EDocumentType.PASSPORT, label: 'PASSAPORTE' },
                { value: EDocumentType.RNE, label: 'RNE' },
                { value: EDocumentType.CLASS_ID, label: 'CARTEIRA FUNCIONAL' },
                {
                  value: EDocumentType.MILITARY_ID,
                  label: 'IDENTIDADE MILITAR',
                },
              ]}
              validationError={
                formik.touched.marital_status?.value
                  ? formik.errors.marital_status?.value
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="document_number"
              value={formik.values.document_number}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: 000.000.000-00"
              labelValue="Número do documento"
              validationError={
                formik.touched.document_number
                  ? formik.errors.document_number
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="document_issuer"
              value={formik.values.document_issuer}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: SSP/SP"
              labelValue="Orgão emissor"
              validationError={
                formik.touched.document_issuer
                  ? formik.errors.document_issuer
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CalendarInput
              name="document_issue_date"
              labelValue="Data de emissão"
              placeholder="dd/mm/aaaa"
              value={formik.values.document_issue_date}
              onChange={(e) =>
                formik.handleChange({
                  currentTarget: {
                    value: e,
                    name: 'document_issue_date',
                  },
                })
              }
              validationError={
                formik.touched.document_issue_date
                  ? formik.errors.document_issue_date
                  : ''
              }
            />
          </StepContentInput>
        </StepContentInputArea>

        <ConfirmModal
          isColumnButtons={true}
          isOpen={isExistingPersonModalVisible}
          icon={<AttentionCircularIcon />}
          title="O CPF informado já está cadastrado"
          btnConfirmText="Limpar e informar outro CPF"
          handleConfirm={() => {
            formik.setFieldValue('taxpayer_id', '');
            formik.setFieldTouched('taxpayer_id', false);
            updateExistsPerson(null);
            toggleExistingPersonModalVisible(false);
            setTimeout(() => {
              document.querySelector<HTMLInputElement>('#taxpayer_id')?.focus();
            });
          }}
          handleClose={() => toggleExistingPersonModalVisible(false)}
          handleCancel={() => toggleExistingPersonModalVisible(false)}
        >
          <AboutStepExistingPerson>
            <span style={{ marginBottom: '8px' }}>
              <strong>{formatCPF(existsPerson?.taxpayer_id || '')}</strong>
            </span>
            <span>{existsPerson?.full_name}</span>
            {getFormatedPhoneFromExistingPerson() && (
              <span>{getFormatedPhoneFromExistingPerson()}</span>
            )}
            <span>{existsPerson?.email_address}</span>
            <span>...</span>
            <a
              href={`#/${routingPath.records.natural}/${existsPerson?.id}`}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Abrir perfil completo em uma nova aba
            </a>
          </AboutStepExistingPerson>
        </ConfirmModal>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary
            onClick={() => {
              if (existsPerson !== null) {
                toggleExistingPersonModalVisible(true);
              } else {
                formik.submitForm();
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </StepContent>
    </>
  );
};

export default AboutStep;
