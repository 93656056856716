import { useTheme } from '@mui/material';
import { ChargesSimulatorSection, TableWrapper } from './styles';
import { Heading4, TextM, TextS } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Table } from 'app/components/Table';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';
import { ButtonPrimary } from 'app/components';
import {
  ChargesSimulatorRemoveIcon,
  InstallmentsDownIcon,
  InstallmentsUpIcon,
} from 'app/components/Icons';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency } from 'app/utils/normalizer';
import { InstallmentItem } from 'modules/charges/interfaces/installments';

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Valor no vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Antecipação</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px' }}>
        Juros Mora
      </TextM>
    ),
  },
  {
    id: '5',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px' }}>
        Multa
      </TextM>
    ),
  },
  {
    id: '6',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px' }}>
        Correção
      </TextM>
    ),
  },
  {
    id: '7',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px' }}>
        Desc. Negoc.
      </TextM>
    ),
  },
  {
    id: '8',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px' }}>
        Atraso
      </TextM>
    ),
  },
  {
    id: '9',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px', textAlign: 'center' }}>
        Devido
      </TextM>
    ),
  },
  {
    id: '10',
    content: (
      <TextM weight="Bold" style={{ minWidth: '100px', textAlign: 'center' }}>
        Remover
      </TextM>
    ),
  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChargesSimulatorInstallments {
  handleRemoveInstallment: (installment: InstallmentItem) => void;
}

const ChargesSimulatorInstallments = ({
  handleRemoveInstallment,
}: IChargesSimulatorInstallments) => {
  const theme = useTheme();
  const { installments } = useInstallmentsCart();

  return (
    <ChargesSimulatorSection>
      <Heading4
        style={{
          fontSize: '32px',
        }}
      >
        Parcelas
      </Heading4>
      <TableWrapper>
        <Table
          tableHeadCell={tableHeader}
          maxBodyHeight="200px"
          tableBodyContent={installments.map((item) => {
            const correctionAmount = item.due_amount - item.expected_amount;
            return {
              id: String(item.id),
              cells: [
                {
                  id: '1',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {formatDateFromBackend(
                          item.due_date,
                        ).toLocaleDateString('pt-br')}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '2',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.expected_amount
                          ? '-'
                          : formatCurrency(item.expected_amount)}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '3',
                  content: (
                    <TableBodyCellContainer>
                      {!item.due_amount || correctionAmount === 0 ? (
                        '-'
                      ) : (
                        <TextS
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            color:
                              correctionAmount < 0
                                ? theme.palette?.brand.success[500]
                                : theme.palette?.brand.error[900],
                          }}
                        >
                          {correctionAmount < 0 ? (
                            <InstallmentsUpIcon
                              color={
                                theme.palette?.brand.success[500] as string
                              }
                            />
                          ) : (
                            <InstallmentsDownIcon
                              color={theme.palette?.brand.error[900] as string}
                            />
                          )}
                          <span style={{ paddingLeft: '8px' }}>
                            {correctionAmount > 0 && '+'}
                            {formatCurrency(correctionAmount)}
                          </span>
                        </TextS>
                      )}
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '4',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.interest_amount
                          ? '-'
                          : formatCurrency(item.interest_amount)}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '5',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.interest_amount
                          ? '-'
                          : formatCurrency(item.taxes_amount)}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '6',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.interest_amount
                          ? '-'
                          : formatCurrency(item.correction_amount)}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '7',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">-</TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '8',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.days_late ? '-' : item.days_late}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '9',
                  content: (
                    <TableBodyCellContainer>
                      <TextS weight="Regular">
                        {!item.due_amount
                          ? '-'
                          : formatCurrency(item.due_amount)}
                      </TextS>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '10',
                  content: (
                    <TableBodyCellContainer
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0px',
                      }}
                    >
                      <ButtonPrimary
                        typeVariant="outline"
                        style={{ width: 'fit-content' }}
                        onClick={() => handleRemoveInstallment(item)}
                      >
                        <ChargesSimulatorRemoveIcon
                          color={theme.palette.brand.primary.base as string}
                        />
                      </ButtonPrimary>
                    </TableBodyCellContainer>
                  ),
                },
              ],
            };
          })}
        />
      </TableWrapper>
    </ChargesSimulatorSection>
  );
};

export default ChargesSimulatorInstallments;
