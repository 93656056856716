import { Heading3 } from 'app/components';
import { useTheme, Divider } from '@mui/material';
import { Contract } from 'modules/charges/interfaces/contracts';
import { useState, useCallback } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  CopyIcon,
  InstallmentsDownIcon,
  InstallmentsEyeIcon,
  InstallmentsSeePageIcon,
  InstallmentsUpIcon,
} from 'app/components/Icons';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { TextL, TextM, TextS, TextXs } from 'app/components';
import { TProducts } from 'modules/products/context';
import { InstallmentItem } from 'modules/charges/interfaces/installments';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { Badge, BoxSection, ButtonDropdown } from 'app/components';
import InstallmentsTable, {
  STATUS,
} from 'modules/charges/components/InstallmentsTable/InstallmentsTable';
import { TBadgeVariant } from 'app/components/Badge/Badge';
import { MenuDropdownContent } from 'modules/customer/pages/NaturalPersonListPage/components/NaturalPersonListPageContent/styles';

interface IInstallmentsTabProps {
  contract: Contract;
  product: TProducts;
}

const InstallmentsTab = ({ contract, product }: IInstallmentsTabProps) => {
  const theme = useTheme();
  const { showSnackbarCopy } = useSnackbar();
  const [isDropdownActive, toggleDropdownActive] = useState<number | false>(
    false,
  );

  const handleCopy = useCallback(
    (item: InstallmentItem) => {
      const correctionAmount = item.due_amount - item.expected_amount;

      navigator.clipboard.writeText(
        `Produto: ${product?.name}\nPagador: ${
          item.debtor[0].name
        } (${normalizeCpfCnpj(
          item.debtor[0].taxpayer_id,
        )})\nData do vencimento: ${formatDateFromBackend(
          item.due_date,
        ).toLocaleDateString('pt-br')}\nValor no vencimento: ${
          !item.expected_amount ? '-' : formatCurrency(item.expected_amount)
        }\nValor devido: ${
          !item.due_amount ? '-' : formatCurrency(item.due_amount)
        }\nAcréscimos / Descontos: ${
          !item.due_amount || correctionAmount === 0
            ? '-'
            : formatCurrency(correctionAmount)
        }\nValor pago: ${
          !item.paid_amount ? '-' : formatCurrency(item.paid_amount)
        }\nData de pagamento: ${
          !item.repayment_date
            ? '-'
            : formatDateFromBackend(item.repayment_date).toLocaleDateString(
                'pt-br',
              )
        }\nStatus: ${STATUS[item.status as keyof typeof STATUS].text}
      `,
      );
      showSnackbarCopy();
    },
    [product],
  );

  const tableHeadContent = [
    {
      id: '2',
      content: (
        <TableHeadCellContainer style={{ paddingLeft: '20px' }}>
          <TextL weight="bold">Pagador</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '3',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Data do vencimento</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '4',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Valor no vencimento</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '5',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Valor devido</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '6',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Acréscimos / Descontos</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '7',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Valor pago</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '8',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Data de pagamento</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '9',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Status</TextL>
        </TableHeadCellContainer>
      ),
    },
    {
      id: '10',
      content: (
        <TableHeadCellContainer>
          <TextL weight="bold">Ação</TextL>
        </TableHeadCellContainer>
      ),
    },
  ];

  return (
    <BoxSection>
      <Heading3 style={{ marginBottom: '-12px' }}>Parcelas</Heading3>
      <InstallmentsTable
        hideFilters={true}
        headers={tableHeadContent}
        onRenderItem={(item, isDisabled) => {
          const correctionAmount = item.due_amount - item.expected_amount;

          return [
            {
              id: '2',
              content: (
                <TableBodyCellContainer>
                  <TextS weight="Bold">{item?.debtor[0].name || ''}</TextS>
                  <TextXs>
                    {normalizeCpfCnpj(item?.debtor[0].taxpayer_id)}
                  </TextXs>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '3',
              content: (
                <TableBodyCellContainer>
                  <TextS>
                    {formatDateFromBackend(item.due_date).toLocaleDateString(
                      'pt-br',
                    )}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '4',
              content: (
                <TableBodyCellContainer>
                  <TextS>
                    {!item.expected_amount
                      ? '-'
                      : formatCurrency(item.expected_amount)}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '5',
              content: (
                <TableBodyCellContainer>
                  <TextS>
                    {!item.due_amount ? '-' : formatCurrency(item.due_amount)}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '6',
              content: (
                <TableBodyCellContainer>
                  {!item.due_amount || correctionAmount === 0 ? (
                    '-'
                  ) : (
                    <TextS
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        color:
                          correctionAmount < 0
                            ? theme.palette?.brand.success[500]
                            : theme.palette?.brand.error[900],
                      }}
                    >
                      {correctionAmount < 0 ? (
                        <InstallmentsUpIcon
                          color={theme.palette?.brand.success[500] as string}
                        />
                      ) : (
                        <InstallmentsDownIcon
                          color={theme.palette?.brand.error[900] as string}
                        />
                      )}
                      <span style={{ paddingLeft: '8px' }}>
                        {correctionAmount > 0 && '+'}
                        {formatCurrency(correctionAmount)}
                      </span>
                    </TextS>
                  )}
                </TableBodyCellContainer>
              ),
            },
            {
              id: '7',
              content: (
                <TableBodyCellContainer>
                  <TextS>
                    {!item.paid_amount ? '-' : formatCurrency(item.paid_amount)}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '8',
              content: (
                <TableBodyCellContainer>
                  <TextS>
                    {!item.repayment_date
                      ? '-'
                      : formatDateFromBackend(
                          item.repayment_date,
                        ).toLocaleDateString('pt-br')}
                  </TextS>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '9',
              content: (
                <TableBodyCellContainer>
                  <Badge
                    iconPosition="left"
                    isSpaceBetween={true}
                    style={{
                      borderColor: 'transparent',
                      position: 'relative',
                    }}
                    typeVariant={
                      STATUS[item.status as keyof typeof STATUS]
                        .color as TBadgeVariant
                    }
                    icon={STATUS[item.status as keyof typeof STATUS].icon}
                  >
                    {STATUS[item.status as keyof typeof STATUS].text}
                  </Badge>
                </TableBodyCellContainer>
              ),
            },
            {
              id: '10',
              content: (
                <TableBodyCellContainer
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0px',
                  }}
                >
                  <ButtonDropdown
                    hideArrow={true}
                    isOpen={isDropdownActive === item.id}
                    handleClose={() => toggleDropdownActive(false)}
                    handleClick={() =>
                      !isDisabled && toggleDropdownActive(item.id)
                    }
                    title="..."
                    style={{
                      minWidth: '215px',
                      right: '-50px',
                    }}
                  >
                    <MenuDropdownContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CopyIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                      <TextM
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          color: theme.palette.brand.secondary.base,
                          paddingLeft: '15px',
                        }}
                        onClick={() => handleCopy(item)}
                      >
                        Copiar
                      </TextM>
                    </MenuDropdownContent>
                    <Divider />
                    <MenuDropdownContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <InstallmentsEyeIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                      <TextM
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          color: theme.palette.brand.secondary.base,
                          paddingLeft: '15px',
                        }}
                      >
                        Pré-visualização
                      </TextM>
                    </MenuDropdownContent>
                    <Divider />
                    <MenuDropdownContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <InstallmentsSeePageIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                      <TextM
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          color: theme.palette.brand.secondary.base,
                          paddingLeft: '15px',
                        }}
                      >
                        Ver página
                      </TextM>
                    </MenuDropdownContent>
                  </ButtonDropdown>
                </TableBodyCellContainer>
              ),
            },
          ];
        }}
        contractId={contract.id}
      />
    </BoxSection>
  );
};

export default InstallmentsTab;
